import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.foundationalGray.light()};
  border-radius: 12px;
  padding: 12px;
  width: 156px;
  text-align: center;
  font-weight: bold;

  & + div {
    margin-top: 12px;
  }
`
