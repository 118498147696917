import axios, { AxiosError, AxiosResponse } from 'axios'
import { HTTP_RESPONSE_STATUS, IHTTPResponse, mountHttpResponse } from 'utils/httpRequestHandler'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

interface IApiCall {
  method: string
  url: string
  payload?: any
}

interface IApi {
  call: (data: IApiCall) => Promise<IHTTPResponse>
}

export const apiHandler: IApi = {
  call: async (data: IApiCall) => {
    switch (data.method) {
      case 'get':
        return api
          .get(data.url)
          .then((res: AxiosResponse) => {
            return mountHttpResponse(
              res.data.message,
              HTTP_RESPONSE_STATUS.SUCCESS,
              res.data.content
            )
          })
          .catch((err: AxiosError) => {
            const errorResponse = err.response as AxiosResponse<any>
            return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
          })
      case 'put':
        return api
          .put(data.url, data.payload)
          .then((res: AxiosResponse) => {
            return mountHttpResponse(
              res.data.message,
              HTTP_RESPONSE_STATUS.SUCCESS,
              res.data.content
            )
          })
          .catch((err: AxiosError) => {
            const errorResponse = err.response as AxiosResponse<any>
            return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
          })
      case 'post':
        return api
          .post(data.url, data.payload)
          .then((res: AxiosResponse) => {
            return mountHttpResponse(
              res.data.message,
              HTTP_RESPONSE_STATUS.SUCCESS,
              res.data.content
            )
          })
          .catch((err: AxiosError) => {
            const errorResponse = err.response as AxiosResponse<any>
            return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
          })
      default:
        return mountHttpResponse('Unable to perform API call', HTTP_RESPONSE_STATUS.FAIL)
    }
  }
}

export default api
