import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  padding: 0;
  padding-right: 32px;
  margin-bottom: 48px;

  h1 {
    margin-bottom: 24px;
  }

  button {
    margin-top: 36px;
    width: 100%;
  }
`

export const Label = styled.label`
  display: block;
  margin: 14px 0;
  font-weight: 600;

  color: ${({ theme }) => theme.colors.foundationalBlack.base()};
`
