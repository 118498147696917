import styled, { css, keyframes } from 'styled-components'
import * as ToastPrimitive from '@radix-ui/react-toast'

const VIEWPORT_PADDING = 25

const hide = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

const slideIn = keyframes`
  from { transform: translateX(calc(100% + ${VIEWPORT_PADDING}px)); }
  to { transform: translateX(0); }
`

const swipeOut = keyframes`
  from { transform: translateX(var(--radix-toast-swipe-end-x)); }
  to { transform: translateX(calc(100% + ${VIEWPORT_PADDING}px)); }
`

export const Viewport = styled(ToastPrimitive.Viewport)`
  padding: ${VIEWPORT_PADDING}px;
  flex-direction: column;
  z-index: 2147483647;
  max-width: 100vw;
  list-style: none;
  position: fixed;
  display: flex;
  outline: none;
  width: 416px;
  margin: 0;
  gap: 10px;
  right: 0;
  top: 0;
`

export const StyledToast = styled(ToastPrimitive.Root)`
  ${({ theme }) => css`
    background-color: ${theme.colors.white()};
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    padding: 15px;
    display: grid;
    grid-template-areas: 'content' 'action';
    grid-template-columns: auto max-content;
    column-gap: 15px;
    align-items: center;

    @media (prefers-reduced-motion: no-preference) {
      &[data-state='open'] {
        animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
      }

      &[data-state='closed'] {
        animation: ${hide} 100ms ease-in;
      }

      &[data-swipe='move'] {
        transform: translateX(var(--radix-toast-swipe-move-x));
      }

      &[data-swipe='cancel'] {
        transform: translateX(0);
        transition: transform 200ms ease-out;
      }

      &[data-swipe='end'] {
        animation: ${swipeOut} 100ms ease-out;
      }
    }
  `}
`

export const ContentWapper = styled.div`
  display: flex;
  grid-area: content;
  flex-direction: column;
`

export const Action = styled(ToastPrimitive.Action)`
  grid-area: action;
  grid-auto-rows: 'action-button' 'action-button';
`

export const Title = styled(ToastPrimitive.Title)`
  ${({ theme }) => css`
    color: ${theme.colors.foundationalBlack.darkest()};
    margin-bottom: 5px;
    font-weight: 500;
    grid-area: title;
    font-size: 18px;
  `}
`

export const Description = styled(ToastPrimitive.Description)`
  ${({ theme }) => css`
    color: ${theme.colors.foundationalGray.dark()};
    grid-area: description;
    font-size: 14px;
    margin: 0;
  `}
`

export const Button = styled.button`
  ${({ theme }) => css`
    grid-area: action-button;
    all: unset;
    display: inline-flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    height: 32px;

    background-color: ${theme.colors.brandPrimary.light()};
    color: ${theme.colors.white()};

    &:hover {
      background-color: ${theme.colors.brandPrimary.light(0.8)};
    }
  `}
`
