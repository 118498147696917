import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  span {
    background: ${({ theme }) => theme.colors.brandSecundary.lightest()};
    width: 150px;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 0;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: hidden;
    position: absolute;
    /* bottom: calc(100% + 12px);
    left: 50%; */
    right: -2px;
    top: calc(100% + 3px);
    /* transform: translateX(-50%); */
    color: #312e38;
    &::before {
      content: '';
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.brandSecundary.lightest()} transparent;
      border-width: 0 6px 6px 6px;
      bottom: 100%;
      position: absolute;
      right: 0;
      transform: translateX(-50%);
    }
  }
  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`
