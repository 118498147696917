import styled, { css } from 'styled-components'

export const ContentContainer = styled.div`
  padding: 24px;
  min-height: 100px;
`

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.foundationalGray.dark()};
    color: ${theme.colors.foundationalBlack.dark()};
    padding-top: 24px;

    display: grid;
    gap: 16px;
    grid-template-columns: auto auto;

    justify-content: end;

    button {
      padding: 0;
      margin: 0;
    }
  `}
`
