import styled from 'styled-components'

export const Container = styled.div``

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .rw-state-focus,
  .rw-widget-input,
  .rw-widget-picker,
  .rw-dropdown-list-inp,
  .rw-input-add,
  .rw-picker-b,
  .rw-state-selected {
    border: none !important;
    box-shadow: none !important;

    padding: 0;
    margin: 0;
  }

  .rw-dropdown-list {
    width: 200px;
    margin-right: 24px;
  }

  .rw-widget-picker {
    flex: 1;
    height: 48px;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
  }

  /* .rw-dropdown-list-input {
    width: 100%;
  } */

  .rw-input-addon.rw-picker-btn {
    border: none;
    &:hover {
      background-color: ${({ theme }) => theme.colors.foundationalGray.lightest(0.3)};
    }
  }

  .rw-state-selected {
    background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
    border-color: ${({ theme }) => theme.colors.brandPrimary.light()};

    &:hover {
      background-color: ${({ theme }) => theme.colors.brandPrimary.light(0.9)} !important;
    }
  }

  .rw-widget-input {
    color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
    box-shadow: none;
    padding: 0px;
  }

  .rw-list-option {
    padding: 8px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.foundationalGray.lightest(0.3)};
      border: none;
    }
  }
`
