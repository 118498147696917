import React from 'react'
import { Redirect, Route as RouteDom, Switch } from 'react-router-dom'

import Route from 'routes/Route'

import ActiveUsers from 'pages/Users/ActiveUsers'
import RegisterUser from 'pages/Users/RegisterUser'
import RejectedUsers from 'pages/Users/RejectedUsers'
import UserApproval from 'pages/Users/UserApproval'
import InternalUsers from 'pages/Users/InternalUsers'
import InactivatedUsers from 'pages/Users/InactivatedUsers'

const baseUrl = '/dashboard/users'

const UserRoutes = () => (
  <Switch>
    <Route path={`${baseUrl}/user-approval`} component={UserApproval} isPrivate />
    <Route path={`${baseUrl}/active`} component={ActiveUsers} isPrivate />
    <Route path={`${baseUrl}/rejected`} component={RejectedUsers} isPrivate />
    <Route path={`${baseUrl}/register`} component={RegisterUser} isPrivate />
    <Route path={`${baseUrl}/internal`} component={InternalUsers} isPrivate />
    <Route path={`${baseUrl}/inactivated`} component={InactivatedUsers} isPrivate />

    <RouteDom
      path={`${baseUrl}/*`}
      component={() => <Redirect to={`${baseUrl}/user-approval`} />}
    />
  </Switch>
)

export default UserRoutes
