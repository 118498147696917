import React, { useCallback } from 'react'

import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { FaPlus } from 'react-icons/fa'

import { ICampaignGroup, useCreateCampaign } from 'pages/SalesCampaign/CreateCampaign/context'

import CustomDropdownList from 'components/ui/CustomDropdownList'
import CustomDatePickerInput from 'components/ui/CustomDatePickerInput'

import GroupBox from 'domain/components/CreateCampaignSetup/GroupBox'
import {
  Container,
  CampaignBasicData,
  GroupSetup,
  ProductList,
  GroupList,
  ProductListWrapper,
  GroupListWrapper,
  GroupListSetup
} from './styles'
import Modal, { AddProductModalRef } from './AddProductModal'
import Toast, { IToastRef } from 'components/ui/Toast'
import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'

registerLocale('pt', ptBR)

const modalRef = React.createRef<AddProductModalRef>()
const successToastRef = React.createRef<IToastRef>()
const errorToastRef = React.createRef<IToastRef>()

const CreateCampaignSetup = () => {
  const {
    selectedDate,
    getBaseProductList,
    setSelectedDate,
    productColumnGroup,
    campaignGroups,
    addCampaignGroup,
    futureHarvestList,
    selectedHarvest,
    updateHarvest,
    territoryList,
    selectedTerritory,
    setSelectedTerritory,
    formRef,
    hasSubmitted,
    addProduct
  } = useCreateCampaign()

  const handleAddProduct = useCallback(
    async productName => {
      const response = await addProduct({ name: productName })

      if (response.status === HTTP_RESPONSE_STATUS.FAIL) return errorToastRef.current?.showToast()

      await getBaseProductList()
      successToastRef.current?.showToast()
    },
    [errorToastRef, successToastRef, addProduct]
  )

  return (
    <>
      <Toast title="Sucesso" description="Produto adicionado com sucesso." ref={successToastRef} />
      <Toast title="Erro" description="Erro ao adicionar produto." ref={errorToastRef} />
      <Container>
        <CampaignBasicData>
          <label>
            Safra
            <CustomDropdownList
              defaultValue={selectedHarvest}
              onChangeHandler={value => {
                updateHarvest(value)
                formRef.current?.setFieldValue('harvest', value)
              }}
              data={futureHarvestList}
              placeholder="Selecione"
              name="harvest"
            />
          </label>
          <label>
            Região
            <CustomDropdownList
              defaultValue={selectedTerritory}
              onChangeHandler={value => {
                setSelectedTerritory(value)
                formRef.current?.setFieldValue('territory', value)
              }}
              data={territoryList}
              placeholder="Selecione"
              name="territory"
            />
          </label>
          <label>
            Data de início
            <CustomDatePickerInput
              selected={selectedDate}
              onChangeHandler={(date: Date) => {
                setSelectedDate(date)
                formRef.current?.setFieldValue('startDate', date)
              }}
              minDate={new Date()}
              name="startDate"
            />
          </label>
        </CampaignBasicData>
        <GroupSetup>
          <ProductListWrapper>
            <GroupListSetup>
              Produtos
              <FaPlus size={21} onClick={modalRef.current?.openModal} />
            </GroupListSetup>
            <ProductList>
              <GroupBox
                hasError={false}
                group={productColumnGroup}
                groupId={productColumnGroup.id}
                editable={false}
                hasSubmitted={hasSubmitted}
              />
            </ProductList>
          </ProductListWrapper>
          <GroupListWrapper>
            <GroupListSetup>
              Premiações
              <FaPlus size={21} onClick={addCampaignGroup} />
            </GroupListSetup>
            <GroupList>
              {campaignGroups.map((group: ICampaignGroup) => (
                <GroupBox
                  key={group.id}
                  group={group}
                  groupId={group.id}
                  editable
                  hasError={hasSubmitted && group.products && group.products.length === 0}
                  hasSubmitted={hasSubmitted}
                />
              ))}
            </GroupList>
          </GroupListWrapper>
        </GroupSetup>
      </Container>
      <Modal ref={modalRef} onSubmit={handleAddProduct} />
    </>
  )
}

export default CreateCampaignSetup
