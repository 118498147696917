import React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { EditCampaignProvider } from 'pages/SalesCampaign/EditCampaign/context'
import EditCampaignForm from 'pages/SalesCampaign/EditCampaign/EditCampaignForm'
import { pagesNames } from 'domain/constants/functionality'
import { useUser } from 'context/user'
import { hasPermission } from 'utils/permissions'
import { Redirect } from 'react-router-dom'

const screenName = pagesNames.CAMPAIGN.permissions.edit

const EditCampaign = () => {
  const { userFunctionality = [] } = useUser()

  if (!hasPermission({ functionality: 'CAMPAIGN', userFunctionality, screenName })) {
    return <Redirect to="/dashboard" />
  }

  return (
    <EditCampaignProvider>
      <DndProvider backend={HTML5Backend}>
        <EditCampaignForm />
      </DndProvider>
    </EditCampaignProvider>
  )
}

export default EditCampaign
