import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'

import { FiAlertCircle } from 'react-icons/fi'
import colors from 'styles/theme/colors'
import { Container, Error } from './styles'

interface IFileInput extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  setImageBlob: React.Dispatch<React.SetStateAction<File>>
  setImageBase64: React.Dispatch<React.SetStateAction<string | undefined>>
}

const FileInput: React.FC<IFileInput> = ({ name, setImageBlob, setImageBase64, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(false)

  const { fieldName, defaultValue, error, registerField } = useField(name)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setHasContent(!!inputRef.current?.value)
  }, [])

  const handleChange = useCallback(
    event => {
      setHasContent(!!inputRef.current?.value)

      const file = event.target.files?.[0]

      if (file) {
        setImageBlob(file)

        const reader = new FileReader()
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            setImageBase64(reader.result)
          }
        }
        reader.readAsDataURL(file)
      }
    },
    [setImageBase64, setImageBlob]
  )

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Container hasError={!!error} hasContent={hasContent} isFocused={isFocused}>
      <input
        id="fileInput"
        type="file"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        onChange={handleChange}
        placeholder={rest.placeholder}
        {...rest}
      />

      <label htmlFor="fileInput">{rest.placeholder}</label>

      {error && !hasContent && (
        <Error title={error}>
          <FiAlertCircle color={colors.feedback.negative()} size={20} />
        </Error>
      )}
    </Container>
  )
}

export default FileInput
