import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react'

import Modal, { IModalRef } from 'components/ui/Modal'
import Button from 'components/ui/Button'
import Select from 'components/ui/Select'

import { IUserRole } from 'domain/interfaces/IUserRole'

import { ButtonsContainer, ContentContainer, FieldContainer, FieldLabel } from './styles'
import { useUser } from 'context/user'
import theme from '../../../styles/theme'

const modalRef = React.createRef<IModalRef>()

interface EditUserModalProps extends React.HTMLAttributes<'div'> {
  description?: string
  title?: string
  isExternal?: boolean
  onSubmit?: () => void
  onDisableUser?: (user: EditUserModalType) => void
}

export type EditUserModalType = {
  readOnly?: boolean
  userId: number
}

export interface EditUserModalRef {
  openModal: (props: EditUserModalType) => void
  closeModal: () => void
}

const roles: IUserRole[] = [
  { code: 41, name: 'VENDEDOR', portugueseName: 'VENDEDOR' },
  { code: 40, name: 'DONO DA DISTRIBUIDORA', portugueseName: 'DONO DA DISTRIBUIDORA' }
]

const EditUserModal = React.forwardRef<EditUserModalRef, EditUserModalProps>(
  ({ isExternal, title, onSubmit, onDisableUser }, ref) => {
    const { assignableUserRole, getAssignableUserRole, updateUser } = useUser()
    const [currentUser, setCurrentUser] = useState<EditUserModalType>()

    const [userRole, setUserRole] = useState<IUserRole | null>()
    const [isLoadingAction, setIsLoadingAction] = useState(false)

    const cleanCurrentUser = useCallback(() => {
      setCurrentUser(undefined)
      setUserRole(null)
    }, [])

    const closeModal = useCallback(() => {
      modalRef.current?.closeModal()
      cleanCurrentUser()
    }, [])

    const openModal = useCallback((data: EditUserModalType) => {
      modalRef.current?.openModal()
      setCurrentUser(data)
      setUserRole(null)
    }, [])

    useEffect(() => {
      assignableUserRole.length < 1 && getAssignableUserRole()
    }, [assignableUserRole])
    useImperativeHandle(ref, () => ({ openModal, closeModal }))

    const onSave = async () => {
      if (!currentUser?.userId) return
      setIsLoadingAction(true)
      setIsLoadingAction(false)
      userRole?.code &&
        (await updateUser({ userId: currentUser?.userId, userRoleId: userRole?.code }))
      if (onSubmit) {
        onSubmit()
      }
      setUserRole(null)
      closeModal()
    }

    const onDisable = async () => {
      if (!currentUser?.userId) return
      if (onDisableUser) {
        onDisableUser(currentUser)
      }
    }

    return (
      <Modal title={title || 'Editar Perfil do Usuário'} ref={modalRef} onClose={cleanCurrentUser}>
        <ContentContainer>
          <FieldContainer>
            <FieldLabel>Perfil</FieldLabel>
            <Select<IUserRole>
              placeholder="Selecione o novo perfil do usuário"
              options={isExternal ? roles : assignableUserRole}
              disabled={currentUser?.readOnly}
              textField="portugueseName"
              value={userRole}
              dataKey="code"
              name="profile"
              onChange={setUserRole}
              autoFocus
            />
          </FieldContainer>
        </ContentContainer>
        <ButtonsContainer>
          <div>
            <Button
              type="button"
              width={148}
              small
              onClick={onDisable}
              disabled={isLoadingAction}
              style={{
                backgroundColor: theme.colors.brandPrimary.dark(isLoadingAction ? 0.5 : 1)
              }}>
              Desativar usuário
            </Button>
          </div>
          <div>
            <Button width={120} small light onClick={closeModal} disabled={isLoadingAction}>
              Cancelar
            </Button>
            <Button
              disabled={currentUser?.readOnly || isLoadingAction}
              width={120}
              onClick={onSave}
              small>
              Salvar
            </Button>
          </div>
        </ButtonsContainer>
      </Modal>
    )
  }
)

export default React.memo(EditUserModal)
