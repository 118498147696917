import styled, { css } from 'styled-components'

interface IRecipientCount {
  change: boolean
}

export const Recipients = styled.h3<IRecipientCount>`
  display: flex;
  align-items: stretch;

  margin-bottom: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brandPrimary.light()};

  span {
    padding: 0 4px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  ${props =>
    props.change &&
    css`
      opacity: 0;
    `}

  transition: opacity 0.2s;
`

interface IRecipientCount {
  change: boolean
}

export const RecipientCount = styled.div<IRecipientCount>`
  font-size: 180%;
  line-height: 0.8;
  padding: 0 4px;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  color: ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.brandPrimary.light()};

  ${props =>
    props.change &&
    css`
      opacity: 0;
    `}

  transition: opacity 0.2s;
`
