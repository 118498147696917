import Tooltip from 'components/ui/Tooltip'
import styled, { css } from 'styled-components'

interface IContainer {
  hasError: boolean
  hasContent: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  transition: all 0.4s;

  min-width: 190px;
  width: 190px;
  max-width: 190px;
  height: 100%;

  & + div {
    margin-left: 24px;
  }
  background-color: ${({ theme }) => theme.colors.white()};
  border-radius: 12px;

  border: 3px solid ${({ theme }) => theme.colors.white()};

  position: relative;
  ${props =>
    props.hasError &&
    !props.hasContent &&
    css`
      border-color: ${({ theme }) => theme.colors.feedback.negative()};
    `}
`

export const GroupPrizeController = styled.div`
  width: 100%;
  height: 68px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-size: 90%;
    color: ${({ theme }) => theme.colors.foundationalBlack.dark()};
    font-weight: bold;
    display: block;
    margin-top: 4px;
    text-align: center;
  }
`

interface IFakeInput {
  justify?: string
}

export const FakeInput = styled.div<IFakeInput>`
  background: ${({ theme }) => theme.colors.white()};
  border-radius: 12px;
  width: 100%;
  height: 24px;
  border: 3px solid ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.foundationalGray.dark()};
  position: relative;
  margin-top: 8px;

  display: flex;
  align-items: center;

  ${props =>
    props.justify === 'center' &&
    css`
      justify-content: center;
    `}
`

export const PrizeValue = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  height: 38px;

  width: 156px;
  text-align: center;

  border-radius: 8px;
  margin-top: 4px;

  border: 2px solid ${({ theme }) => theme.colors.foundationalGray.base()};

  background-color: ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
  font-weight: normal;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const GroupItems = styled.div`
  flex: 1;
  transition: all 0.4s;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 12px 0;
  width: 100%;

  overflow: scroll;
  overflow-x: hidden;

  background-color: transparent;
`

export const RemoveGroup = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
  color: ${({ theme }) => theme.colors.white()};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.brandPrimary.base()};
  }
`

export const Error = styled(Tooltip)`
  position: absolute;
  top: 4px;
  right: 4px;

  height: 20px;
  margin-left: 14px;
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.feedback.negative()};
    color: ${({ theme }) => theme.colors.white()};
    &::before {
      border-color: ${({ theme }) => theme.colors.feedback.negative()} transparent;
    }
  }
`
