import React from 'react'
import { IconType } from 'react-icons'
import { Link } from 'react-router-dom'

import { Container, Text } from './styles'

interface ISideBarItem {
  text: string
  link: string
  selected: boolean
  Icon: IconType | null
  menuOpen: boolean
}

const SideBarItem: React.FC<ISideBarItem> = ({ Icon, text, link, selected, menuOpen }) => {
  return (
    <Container selected={selected} menuOpen={menuOpen}>
      <Link to={link}>
        {Icon && <Icon size={20} />}
        <Text menuOpen={menuOpen}>{text}</Text>
      </Link>
    </Container>
  )
}

export default SideBarItem
