import styled, { css } from 'styled-components'

interface IState {
  open?: boolean
  menuOpen: boolean
  selected?: boolean
}

export const Container = styled.ul<IState>`
  padding: 0 16px;
  margin-bottom: 0px;
  transition: margin 0.2s;

  ${props =>
    props.open &&
    css`
      margin-bottom: 12px;
    `}

  ${props =>
    props.menuOpen === false &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`

export const Title = styled.h4<IState>`
  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.foundationalBlack.base()};

  /* text-shadow: 1px 1px 1px ${({ theme }) => theme.colors.foundationalGray.dark(0.4)}; */
  font-weight: 500;

  display: flex;
  align-items: flex-end;
  overflow: hidden;

  transition: margin 0.2s;

  margin-right: 0;
  padding: 12px 0;

  svg {
    width: 28px;
    z-index: 999;
    background-color: ${({ theme }) => theme.colors.white()};
    ${props =>
      props.menuOpen &&
      css`
        margin-right: 16px;
      `}
  }

  a {
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.foundationalBlack.base()};

    ${props =>
      props.selected &&
      css`
        color: ${({ theme }) => theme.colors.brandPrimary.light()};
      `}
  }
`

export const Text = styled.span<IState>`
  max-width: 0;
  transform: translateX(-100%);

  transition: width 0.2s, transform 0.2s;
  overflow: hidden;

  ${props =>
    props.menuOpen === true &&
    css`
      max-width: 10rem;
      transform: translateX(0);
    `}
`

export const Arrow = styled.span`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Items = styled.div<IState>`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.7s;

  ${props =>
    props.open &&
    css`
      max-height: 16rem;
    `}
`
