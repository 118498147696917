import React from 'react'
import format from 'date-fns/format'
import { BiUserPlus } from 'react-icons/bi'
import { v4 as uuidv4 } from 'uuid'

import { IInternalUser } from 'domain/interfaces/IInternalUser'
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableHeaderCell,
  DataTableLine
} from 'components/ui/DataTable'
import colors from 'styles/theme/colors'

import { ButtonContainer } from './styles'
import { useUser } from 'context/user'
import { pagesNames } from 'domain/constants/functionality'
import { hasPermission } from 'utils/permissions'
import ModalPortal, { MODAL_TYPES } from 'components/layout/ModalPortal'
import { IExternalUser } from '../../interfaces/IExternalUser'

interface IInternalUsersDataTable {
  userList: IInternalUser[] | IExternalUser[]
  onEdit: () => void
}

const userPermissions = pagesNames.USER.permissions
const editPermissionId = userPermissions.edit

const InactivatedUserDataTable: React.FC<IInternalUsersDataTable> = ({ userList = [], onEdit }) => {
  const { userFunctionality = [], enableUser } = useUser()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState<
    (IInternalUser & IExternalUser) | undefined
  >(undefined)

  const canAccess = (screenName: string) =>
    hasPermission({ functionality: 'USER', userFunctionality, screenName })

  const tableColumns = [
    'Nome',
    'Email',
    'Perfil/Cargo',
    'Revisor/Quem convidou',
    'Data de desativação',
    canAccess(editPermissionId) ? '' : null
  ]

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onActivateUser = async () => {
    if (!selectedUser?.id) return
    await enableUser({ userId: selectedUser?.id })
    onEdit()
    closeModal()
  }

  return (
    <>
      <DataTable>
        <DataTableHead>
          <DataTableLine>
            {tableColumns.map(
              column =>
                column !== null && <DataTableHeaderCell key={column}>{column}</DataTableHeaderCell>
            )}
          </DataTableLine>
        </DataTableHead>
        <DataTableBody>
          {userList.length > 0 &&
            userList.map((user, index) => {
              const disableDate = new Date(user?.disableDate)
              const formatedInvitationDate = user?.disableDate
                ? format(disableDate, 'dd/MM/yyyy')
                : 'N/A'

              return (
                <DataTableLine key={`${user.id}-${user?.email}-index=${index}`}>
                  <DataTableCell>{user?.name || 'N/A'}</DataTableCell>
                  <DataTableCell>{user?.email}</DataTableCell>
                  <DataTableCell>{user?.profile}</DataTableCell>
                  <DataTableCell>{user?.invitedBy}</DataTableCell>
                  <DataTableCell>{formatedInvitationDate}</DataTableCell>
                  {canAccess(editPermissionId) && (
                    <DataTableCell>
                      <ButtonContainer
                        onClick={() => {
                          setSelectedUser(user)
                          setIsModalOpen(true)
                        }}
                        aria-label="Reativar usuário"
                        title="Reativar usuário">
                        <BiUserPlus size={24} color={colors.foundationalBlack.light()} />
                      </ButtonContainer>
                    </DataTableCell>
                  )}
                </DataTableLine>
              )
            })}
        </DataTableBody>
      </DataTable>
      <ModalPortal
        id={uuidv4()}
        display={isModalOpen}
        type={MODAL_TYPES.CONFIRM_OR_CANCEL}
        props={{
          onConfirm: onActivateUser,
          onCancel: closeModal,
          title: 'Reativar Usuário',
          message: `Você confirma a reativação do usuário ${
            selectedUser?.name || selectedUser?.firstName
          } que estava inativo?`
        }}
      />
    </>
  )
}

export default InactivatedUserDataTable
