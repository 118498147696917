import React, { createContext, useCallback, useContext } from 'react'

import axios, { AxiosError, AxiosResponse } from 'axios'
import { useAuth } from 'context/auth'

import { HTTP_RESPONSE_STATUS, IHTTPResponse, mountHttpResponse } from 'utils/httpRequestHandler'

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL })

interface IApiCall {
  method: 'get' | 'post' | 'put' | 'delete'
  url: string
  payload?: any
}

interface IAPIContextWrapper {
  callApi: (data: IApiCall) => Promise<IHTTPResponse>
}

const APIContext = createContext<IAPIContextWrapper>({} as IAPIContextWrapper)

const APIProvider = ({ children }) => {
  const { refreshToken, signOut } = useAuth()

  const getNewToken = useCallback(async () => {
    const newToken = await refreshToken()
    if (newToken !== '') {
      api.defaults.headers['authorization'] = `Bearer ${newToken}`
    } else {
      signOut()
    }
  }, [refreshToken])

  const refreshTokenAndRetry = useCallback(
    async (data: IApiCall) => {
      await getNewToken()

      switch (data.method) {
        case 'get':
          return api
            .get(data.url)
            .then((res: AxiosResponse) => {
              return mountHttpResponse(
                res.data.message,
                HTTP_RESPONSE_STATUS.SUCCESS,
                res.data.content
              )
            })
            .catch((err: AxiosError) => {
              const errorResponse = err.response as AxiosResponse<any>
              // if (errorResponse.status === 401) signOut()

              return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
            })
        case 'put':
          return api
            .put(data.url, data.payload)
            .then((res: AxiosResponse) => {
              return mountHttpResponse(
                res.data.message,
                HTTP_RESPONSE_STATUS.SUCCESS,
                res.data.content
              )
            })
            .catch((err: AxiosError) => {
              const errorResponse = err.response as AxiosResponse<any>
              return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
            })
        case 'post':
          return api
            .post(data.url, data.payload)
            .then((res: AxiosResponse) => {
              return mountHttpResponse(
                res.data.message,
                HTTP_RESPONSE_STATUS.SUCCESS,
                res.data.content
              )
            })
            .catch((err: AxiosError) => {
              const errorResponse = err.response as AxiosResponse<any>
              return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
            })
        case 'delete':
          return api
            .delete(data.url)
            .then((res: AxiosResponse) => {
              return mountHttpResponse(
                res.data.message,
                HTTP_RESPONSE_STATUS.SUCCESS,
                res.data.content
              )
            })
            .catch((err: AxiosError) => {
              const errorResponse = err.response as AxiosResponse<any>
              return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
            })
        default:
          return mountHttpResponse('Unable to perform API call', HTTP_RESPONSE_STATUS.FAIL)
      }
    },
    [getNewToken]
  )

  const callApi = useCallback(
    async (data: IApiCall) => {
      const idToken = localStorage.getItem('@AzureAd:idToken')
      api.defaults.headers['authorization'] = `Bearer ${idToken}`

      switch (data.method) {
        case 'get':
          return api
            .get(data.url)
            .then((res: AxiosResponse) => {
              return mountHttpResponse(
                res.data.message,
                HTTP_RESPONSE_STATUS.SUCCESS,
                res.data.content
              )
            })
            .catch((err: AxiosError) => {
              const errorResponse = err.response as AxiosResponse<any>
              if (errorResponse.status === 401) {
                return refreshTokenAndRetry(data)
              }
              return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
            })
        case 'put':
          return api
            .put(data.url, data.payload)
            .then((res: AxiosResponse) => {
              return mountHttpResponse(
                res.data.message,
                HTTP_RESPONSE_STATUS.SUCCESS,
                res.data.content
              )
            })
            .catch((err: AxiosError) => {
              const errorResponse = err.response as AxiosResponse<any>
              if (errorResponse.status === 401) {
                return refreshTokenAndRetry(data)
              }
              return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
            })
        case 'post':
          return api
            .post(data.url, data.payload)
            .then((res: AxiosResponse) => {
              return mountHttpResponse(
                res.data.message,
                HTTP_RESPONSE_STATUS.SUCCESS,
                res.data.content
              )
            })
            .catch((err: AxiosError) => {
              const errorResponse = err.response as AxiosResponse<any>
              if (errorResponse.status === 401) {
                return refreshTokenAndRetry(data)
              }
              return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
            })
        case 'delete':
          return api
            .delete(data.url)
            .then((res: AxiosResponse) => {
              return mountHttpResponse(
                res.data.message,
                HTTP_RESPONSE_STATUS.SUCCESS,
                res.data.content
              )
            })
            .catch((err: AxiosError) => {
              const errorResponse = err.response as AxiosResponse<any>
              if (errorResponse.status === 401) {
                return refreshTokenAndRetry(data)
              }
              return mountHttpResponse(errorResponse.data.message, HTTP_RESPONSE_STATUS.FAIL)
            })
        default:
          return mountHttpResponse('Unable to perform API call', HTTP_RESPONSE_STATUS.FAIL)
      }
    },
    [refreshTokenAndRetry]
  )

  return <APIContext.Provider value={{ callApi }}>{children}</APIContext.Provider>
}

function useAPI(): IAPIContextWrapper {
  const context = useContext(APIContext)

  if (!context) {
    throw new Error('useAPI must be used within an APIProvider')
  }

  return context
}

export { APIProvider, useAPI }
