import React, { useImperativeHandle, useEffect, useRef, useState, useCallback } from 'react'
import { ToastProvider } from '@radix-ui/react-toast'
import { Viewport, StyledToast, Action, Title, Description, Button, ContentWapper } from './styles'

export interface IToastRef {
  showToast: () => void
  hideToast: () => void
}

export interface IToastProps {
  onClose?: () => any
  description: string
  actionLabel?: string
  title: string
}

const Toast = React.forwardRef<IToastRef, IToastProps>(({ onClose, title, description }, ref) => {
  const [open, setOpen] = useState(false)
  const timerRef = useRef(0)

  useEffect(() => {
    return () => clearTimeout(timerRef.current)
  }, [])

  const hideToast = useCallback(() => {
    setOpen(false)
    onClose && onClose()
  }, [setOpen])

  const showToast = useCallback(() => {
    setOpen(true)
    window.clearTimeout(timerRef.current)
    timerRef.current = window.setTimeout(hideToast, 3000)
  }, [])

  useImperativeHandle(ref, () => ({ hideToast, showToast }))

  return (
    <ToastProvider swipeDirection="right">
      <StyledToast open={open} onOpenChange={setOpen}>
        <ContentWapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </ContentWapper>
        <Action asChild altText="Ok">
          <Button>Ok</Button>
        </Action>
      </StyledToast>
      <Viewport />
    </ToastProvider>
  )
})
export default Toast
