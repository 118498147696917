import React from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'

import { appPages, IAppPage, ISubPage } from 'utils/appPages'

import { Container, Step, CurrentLocation } from './styles'

const Breadcrumb = () => {
  const { pathname } = useLocation()

  const allSubpages = appPages.reduce((acc: ISubPage[], page: IAppPage) => {
    page.subPages.forEach((subPage: ISubPage) => acc.push(subPage))
    return acc
  }, [] as ISubPage[])

  const thisPage = allSubpages.find(
    (subPage: ISubPage) => subPage.link === pathname || pathname.includes(subPage.link)
  )

  const breadcrumb = thisPage ? thisPage.breadcrumb : undefined

  return (
    <Container>
      {breadcrumb &&
        breadcrumb.map((step: string, index) => {
          if (index === breadcrumb.length - 1) {
            return <CurrentLocation key={step}>{step}</CurrentLocation>
          }
          return (
            <Step key={step}>
              {step}
              <RiArrowRightSLine size={24} />
            </Step>
          )
        })}
    </Container>
  )
}

export default Breadcrumb
