import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'

import { FiAlertCircle } from 'react-icons/fi'
import colors from 'styles/theme/colors'
import { Container, Error, MaxLength } from './styles'

interface ITextArea extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string
  maxLength?: number
}

const TextArea: React.FC<ITextArea> = ({ name, maxLength, ...rest }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(false)
  const { fieldName, defaultValue, error, registerField } = useField(name)

  const [valueLength, setValueLength] = useState(0)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    setHasContent(!!textAreaRef.current?.value)
  }, [])

  const handleChange = useCallback(() => {
    setHasContent(!!textAreaRef.current?.value)
    if (textAreaRef.current && textAreaRef.current.value) {
      setValueLength(textAreaRef.current?.value.length)
    }
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Container hasError={!!error} hasContent={hasContent} isFocused={isFocused}>
      <textarea
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={textAreaRef}
        onChange={handleChange}
        maxLength={maxLength}
        {...rest}
      />

      {error && !hasContent && (
        <Error title={error}>
          <FiAlertCircle color={colors.feedback.negative()} size={20} />
        </Error>
      )}

      {maxLength && textAreaRef.current && textAreaRef.current.value && (
        <MaxLength>{`${valueLength} / ${maxLength}`}</MaxLength>
      )}
    </Container>
  )
}

export default TextArea
