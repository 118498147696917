import { ICampaignProduct } from 'pages/SalesCampaign/ViewCampaignDetails/context'
import React from 'react'

import { Container } from './styles'

interface IProductTag {
  product: ICampaignProduct
}

const ProductTag: React.FC<IProductTag> = ({ product }) => {
  return <Container>{product.name}</Container>
}

export default ProductTag
