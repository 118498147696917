import React from 'react'
import { Redirect, Route as RouteDom, Switch } from 'react-router-dom'

import CreateCampaign from 'pages/SalesCampaign/CreateCampaign'
import EditCampaign from 'pages/SalesCampaign/EditCampaign'
import ListCampaigns from 'pages/SalesCampaign/ListCampaigns'
import ViewCampaignDetails from 'pages/SalesCampaign/ViewCampaignDetails'

import Route from 'routes/Route'

const baseUrl = '/dashboard/sales-campaign'

const SalesCampaignRoutes = () => (
  <Switch>
    <Route path={`${baseUrl}/create/`} component={CreateCampaign} isPrivate />
    <Route path={`${baseUrl}/list/`} component={ListCampaigns} isPrivate />
    <Route path={`${baseUrl}/edit/:campaignId/`} component={EditCampaign} isPrivate />
    <Route path={`${baseUrl}/details/:campaignId/`} component={ViewCampaignDetails} isPrivate />

    <RouteDom path={`${baseUrl}/*`} component={() => <Redirect to={`${baseUrl}/list`} />} />
  </Switch>
)

export default SalesCampaignRoutes
