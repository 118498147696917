import React from 'react'
import { Redirect, Route as RouteDom, Switch } from 'react-router-dom'

import Route from 'routes/Route'

import CreateNotification from 'pages/Notifications/Create'
import ListNotifications from 'pages/Notifications/List'
import ReviewAndSendNotification from 'pages/Notifications/ReviewAndSend'

const baseUrl = '/dashboard/notifications'

const NotificationRoutes = () => (
  <Switch>
    <Route path={`${baseUrl}/create/`} component={CreateNotification} isPrivate />
    <Route path={`${baseUrl}/list/`} component={ListNotifications} isPrivate />
    <Route path={`${baseUrl}/review/`} component={ReviewAndSendNotification} isPrivate />

    <RouteDom path={`${baseUrl}/*`} component={() => <Redirect to={`${baseUrl}/list`} />} />
  </Switch>
)

export default NotificationRoutes
