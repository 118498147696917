import * as Yup from 'yup'

// @reppioneer.com
// @repcorteva.com
// @repbrevant.com
// @corteva.com

const regexInternalEmail = /^([\w.-]+)@(corteva|repbrevant).com$/

export const InviteUserSchema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório').max(80).trim(),
  profile: Yup.object().nullable().required('O perfil é obrigatório'),
  email: Yup.string()
    .email()
    .trim()
    .required('O email é obrigatório')
    .lowercase()
    .trim()
    .matches(regexInternalEmail, {
      message: 'Email deve ser do grupo Corteva (@repbrevant.com, @corteva.com)',
      excludeEmptyString: true,
      name: 'email'
    })
})
