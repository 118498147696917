import React from 'react'

import { v4 as uuidv4 } from 'uuid'

import { notificationCategories } from 'utils/notificationCategories'

import { Form } from '@unform/web'

import ImageInputFilled from 'components/ui/ImageInputFilled'
import RadioGroup from 'components/ui/RadioGroup'
import Button from 'components/ui/Button'
import Input from 'components/ui/Input'
import TextArea from 'components/ui/TextArea'
import FileInput from 'components/ui/FileInput'

import { usePushNotifications } from 'context/pushNotification'
import { Container, Label } from './styles'

const CreateNotificationForm = () => {
  const {
    formRef,
    imageBlob,
    setImageBlob,
    handleClearFile,
    handleSubmit,
    notificationData,
    setImageBase64
  } = usePushNotifications()

  const inputImageId = uuidv4()

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Label>Selecione o tipo de notificação</Label>
        <RadioGroup
          initialValue={notificationData.notificationType || ''}
          name="notificationType"
          options={notificationCategories}
        />

        <Label>Título da notificação</Label>
        <Input
          defaultValue={notificationData.title}
          name="title"
          placeholder="Título da notificação"
          maxLength={40}
        />

        <Label>Conteúdo da mensagem</Label>
        <TextArea
          defaultValue={notificationData.message}
          name="message"
          placeholder="Conteúdo da mensagem"
          maxLength={1024}
        />

        <Label>Imagem</Label>

        {imageBlob && imageBlob.name && (
          <ImageInputFilled
            previewURL={URL.createObjectURL(imageBlob)}
            handleClearFile={handleClearFile}
          />
        )}

        {!imageBlob.name && (
          <FileInput
            key={inputImageId}
            setImageBlob={setImageBlob}
            setImageBase64={setImageBase64}
            name="image"
            placeholder="Imagem"
            accept="image/png, image/jpeg"
          />
        )}

        <Label>Link Externo</Label>
        <Input
          defaultValue={notificationData.link}
          name="link"
          placeholder="Link Externo"
          maxLength={40}
        />

        <Button type="submit">Enviar Notificação</Button>
      </Form>
    </Container>
  )
}

export default CreateNotificationForm
