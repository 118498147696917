import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
`

export const CampaignCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white()};
  border-radius: 12px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.12);

  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    width: 100%;
  }
`

export const CampaignTitle = styled.h3`
  margin-bottom: 24px;
  ${({ theme }) => theme.font.heading3}
  color: ${({ theme }) => theme.colors.brandPrimary.light()};

  a {
    color: ${({ theme }) => theme.colors.brandPrimary.light()};
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      color: ${({ theme }) => theme.colors.brandPrimary.base()};
    }
  }
`

export const SubTitle = styled.h4`
  margin-bottom: 12px;
  ${({ theme }) => theme.font.heading4}
  color: ${({ theme }) => theme.colors.foundationalBlack.base()};
`

export const DataBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
`

export const DateInfo = styled.h4`
  ${({ theme }) => theme.font.heading4}
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      height: 100%;
      width: 100%;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.white()};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
