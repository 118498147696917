import React, { useCallback, useState } from 'react'
import { IconType } from 'react-icons'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { Link, useLocation } from 'react-router-dom'
import colors from 'styles/theme/colors'

import { Container, Items, Title, Text, Arrow } from './styles'

interface ISideBarSection {
  title: string
  Icon: IconType
  menuOpen: boolean
  startOpen: boolean
  link: string | null
  selected: boolean
  children: React.ReactNode
}

const SideBarSection: React.FC<ISideBarSection> = ({
  Icon,
  title,
  menuOpen,
  children,
  startOpen,
  link,
  selected
}) => {
  const [open, setOpen] = useState<boolean>(startOpen)
  const { pathname } = useLocation()

  const toggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <Container open={open} menuOpen={menuOpen}>
      <Title onClick={toggle} menuOpen={menuOpen} selected={selected}>
        {!link && (
          <Link to={pathname}>
            {Icon && <Icon size={24} color={colors.foundationalBlack.base()} />}
            <Text menuOpen={menuOpen}>{title}</Text>
          </Link>
        )}
        {link && (
          <Link to={link}>
            {Icon && <Icon size={24} />}
            <Text menuOpen={menuOpen}>{title}</Text>
          </Link>
        )}

        {React.Children.count(children) > 0 && (
          <Arrow>
            {!open && <IoIosArrowDown size={20} color={colors.foundationalBlack.base()} />}
            {open && <IoIosArrowUp size={20} color={colors.foundationalBlack.base()} />}
          </Arrow>
        )}
      </Title>
      <Items open={open} menuOpen={menuOpen}>
        {children}
      </Items>
    </Container>
  )
}

export default SideBarSection
