import React from 'react'
import format from 'date-fns/format'

import { formatNumber } from 'utils/formatNumbers'

import { ISalesCampaign, useListSalesCampaign } from 'pages/SalesCampaign/ListCampaigns/context'

import { IoPause } from 'react-icons/io5'

import Button from 'components/ui/Button'

import { Link } from 'react-router-dom'
import {
  Container,
  CampaignCard,
  CampaignTitle,
  SubTitle,
  DataBox,
  StatusBar,
  StatusData,
  DateInfo
} from './styles'

interface IActiveCampaignList {
  onStartFinishCampaign: (campaign: ISalesCampaign) => void
}

const ActiveCampaignList: React.FC<IActiveCampaignList> = ({ onStartFinishCampaign }) => {
  const { activeCampaigns } = useListSalesCampaign()

  return (
    <Container>
      {activeCampaigns.map((campaign: ISalesCampaign) => {
        return (
          <CampaignCard key={campaign.code}>
            <CampaignTitle>
              <Link to={`/dashboard/sales-campaign/details/${campaign.code}`}>{campaign.name}</Link>
            </CampaignTitle>

            <SubTitle>Data de Início</SubTitle>
            <DataBox>
              <DateInfo>{format(new Date(campaign.startDate), 'dd / MM / yyyy')}</DateInfo>
            </DataBox>

            <SubTitle>Usuários Participantes</SubTitle>
            <DataBox>
              <StatusBar done={campaign.enrolledUsers} total={campaign.totalUsers} />
              <StatusData>
                <span>{formatNumber(campaign.enrolledUsers.toString())}</span>
                <span>{formatNumber(campaign.totalUsers.toString())}</span>
              </StatusData>
            </DataBox>

            <SubTitle>Metas Realizadas</SubTitle>
            <DataBox>
              <StatusBar done={campaign.achievedGoals} total={campaign.totalGoals} />
              <StatusData>
                <span>{formatNumber(campaign.achievedGoals.toString())}</span>
                <span>{formatNumber(campaign.totalGoals.toString())}</span>
              </StatusData>
            </DataBox>

            <Button onClick={() => onStartFinishCampaign(campaign)} LeftIcon={IoPause}>
              Encerrar Campanha
            </Button>
          </CampaignCard>
        )
      })}
    </Container>
  )
}

export default ActiveCampaignList
