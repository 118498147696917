export const USER_STATUS = {
  PENDING_APPROVAL: 'PendingApproval',
  ACTIVE: 'Active',
  REJECTED: 'Rejected',
  INTERNAL: 'Internal',
  INACTIVE: 'Inactive'
}

export const USER_STATUS_ID = {
  CREATED: 0,
  PENDING_APPROVAL: 1,
  REJECTED: 2,
  ACTIVE: 3,
  INACTIVE: 4,
  REMOVED: 5,
  PENDING_ACTIVATION: 6,
  SYSTEM: 7
}

export const AUTH_MECHANISM = {
  AAD: 0,
  B2C: 1,
  NO_AUTH: 2
}
