import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white()};
  border-radius: 12px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.12);
  width: 100%;

  margin-bottom: 24px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 0;
`

export const Category = styled.span`
  text-transform: uppercase;
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.foundationalBlack.base()};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.largeTextBold};
  line-height: 100%;
`

export const RecipientCount = styled.span`
  border-left: 2px solid ${({ theme }) => theme.colors.foundationalGray.base()};
  padding-left: 8px;
  color: ${({ theme }) => theme.colors.brandSecundary.base()};
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.largeTextBold};
  line-height: 100%;
`

export const CardBody = styled.div`
  display: flex;
`

export const CardText = styled.div`
  flex: 1;
  padding-right: 24px;
  white-space: pre-line;
`
export const CardMessage = styled.p`
  flex: 1;
  padding-right: 24px;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.foundationalBlack.dark()};
`

export const CardTitle = styled.h2`
  margin-bottom: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brandPrimary.light()};
`

export const CardImage = styled.div`
  max-width: 400px;
  width: 400px;
  height: auto;

  img {
    max-width: 400px;
    width: 400px;
    height: auto;
  }
`

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`

export const CardFooterTitle = styled.h4`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brandPrimary.light()};
  margin-right: 8px;
`

export const CardFooterDetail = styled.h5`
  color: ${({ theme }) => theme.colors.foundationalBlack.base()};
  font-weight: bold;
`
