import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'

import { msalConfig, loginRequest } from 'utils/configureAzureAd'
import { getUserAvatar, getUserDetails } from 'utils/graphService'

import api, { apiHandler } from 'service/api'
import { IInternalUserAccount } from 'domain/interfaces/IInternalUserAccount'
import { IInternalUserProfile } from 'domain/interfaces/IInternalUser'
import { IInternalUserGraphAccount } from 'domain/interfaces/IInternalUserGraphAccount'
import { IInternalUserAuthData } from 'domain/interfaces/IInternalUserAuthData'
import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'

interface IAuthContextWrapper {
  signIn: () => Promise<void>
  signOut: () => void
  accountInfo: IInternalUserAccount
  loggedInUser: IInternalUserProfile
  authFailed: boolean
  refreshToken: () => Promise<string>
  currentIdToken: string
}

const AuthContext = createContext<IAuthContextWrapper>({} as IAuthContextWrapper)

const msalInstance = new PublicClientApplication(msalConfig)

const AuthProvider = ({ children }) => {
  const [accountInfo, setAccountInfo] = useState<IInternalUserAccount>({
    isAuthenticated: false,
    user: {} as IInternalUserGraphAccount,
    error: null
  })

  const [loggedInUser, setLoggedInUser] = useState<IInternalUserProfile>({} as IInternalUserProfile)

  const [authFailed, setAuthFailed] = useState(false)

  const [currentIdToken, setCurrentIdToken] = useState<string>('')

  const setAuthData = (data: IInternalUserAuthData) => {
    const { accessToken, idToken } = data
    localStorage.setItem('@AzureAd:accessToken', accessToken)
    localStorage.setItem('@AzureAd:idToken', idToken)
    api.defaults.headers['authorization'] = `Bearer ${idToken}`
    setCurrentIdToken(idToken)
  }

  const clearAuthData = useCallback(() => {
    localStorage.removeItem('@AzureAd:accessToken')
    localStorage.removeItem('@AzureAd:idToken')
    api.defaults.headers['authorization'] = undefined
    setCurrentIdToken('')
  }, [])

  const signIn = async () => {
    try {
      await msalInstance.loginPopup({
        scopes: loginRequest.scopes,
        prompt: 'select_account'
      })
      setAuthFailed(false)
      await getUserProfile()
    } catch (err) {
      setAccountInfo({
        isAuthenticated: false,
        user: {} as IInternalUserGraphAccount,
        error: err
      })
    }
  }

  const signOut = useCallback(() => {
    clearAuthData()
    setAccountInfo({
      isAuthenticated: false,
      user: {} as IInternalUserGraphAccount,
      error: null
    })
  }, [clearAuthData])

  const getAccessToken = async (scopes: string[]) => {
    try {
      const accounts = msalInstance.getAllAccounts()

      if (accounts.length <= 0) throw new Error('Login required')
      const silentResult = await msalInstance.acquireTokenSilent({
        scopes,
        account: accounts[0]
      })

      return {
        accessToken: silentResult.accessToken,
        idToken: silentResult.idToken
      }
    } catch (err) {
      if (err) {
        const interactiveResult = await msalInstance.acquireTokenPopup({
          scopes
        })

        return {
          accessToken: interactiveResult.accessToken,
          idToken: interactiveResult.idToken
        }
      }
      throw err
    }
  }

  const getUserProfile = useCallback(async () => {
    try {
      const { accessToken, idToken } = await getAccessToken(loginRequest.scopes)

      if (accessToken && idToken) {
        const user = await getUserDetails(accessToken)

        let avatar

        try {
          avatar = await getUserAvatar(accessToken)
        } catch {
          avatar = undefined
        }

        setAuthData({ accessToken, idToken })

        const getUserDataResponse = await apiHandler.call({
          method: 'get',
          url: '/User/Me/Internal'
        })

        if (getUserDataResponse.status === HTTP_RESPONSE_STATUS.SUCCESS) {
          let loggedInUserData = getUserDataResponse.data

          if (loggedInUserData.userStatus === 6) {
            const activateUserResponse = await apiHandler.call({
              method: 'put',
              url: '/User/ActivateInternalUser',
              payload: { displayName: user.displayName }
            })

            if (activateUserResponse.status === HTTP_RESPONSE_STATUS.SUCCESS) {
              loggedInUserData = activateUserResponse.data
            }
          }

          setLoggedInUser(loggedInUserData)

          setAccountInfo({
            isAuthenticated: true,
            user: {
              displayName: user.displayName,
              email: user.mail || user.userPrincipalName,
              avatar
            },
            error: null
          })

          setAuthFailed(false)
          return idToken
        } else {
          clearAuthData()

          setAccountInfo({
            isAuthenticated: false,
            user: {} as IInternalUserGraphAccount,
            error: null
          })

          setAuthFailed(true)
          return idToken
        }
      }
    } catch (err) {
      clearAuthData()

      setAccountInfo({
        isAuthenticated: false,
        user: {} as IInternalUserGraphAccount,
        error: err
      })

      setAuthFailed(true)
      return ''
    }
    return ''
  }, [clearAuthData])

  const refreshToken = useCallback(async () => {
    try {
      const accounts = msalInstance.getAllAccounts()

      if (accounts.length <= 0) throw new Error('Login required')
      const silentResult = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: accounts[0]
      })

      const newAuthData = {
        accessToken: silentResult.accessToken,
        idToken: silentResult.idToken
      }
      setAuthData(newAuthData)
      return newAuthData.idToken
    } catch (err) {
      if (err) {
        const interactiveResult = await msalInstance.acquireTokenPopup({
          scopes: loginRequest.scopes
        })

        const newAuthData = {
          accessToken: interactiveResult.accessToken,
          idToken: interactiveResult.idToken
        }
        setAuthData(newAuthData)
        return newAuthData.idToken
      }
      throw err
    }
  }, [])

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts()
    const hasLoggedInUser = accounts && accounts.length > 0

    if (hasLoggedInUser) {
      getUserProfile()
    }
  }, [getUserProfile])

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        accountInfo,
        loggedInUser,
        authFailed,
        refreshToken,
        currentIdToken
      }}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): IAuthContextWrapper {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
