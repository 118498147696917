import React, { createContext, useCallback, useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { FormHandles } from '@unform/core'
import * as Yup from 'yup'

import getValidationErrors from 'utils/getValidationErrors'

import { INotification } from 'domain/interfaces/INotification'
import { IHTTPResponse } from 'utils/httpRequestHandler'
import { useAPI } from 'context/api'

export interface PushNotification {
  title: string
  message: string
  link: string
  image: string
  imageBlob: File
  notificationType: string
  systemNotification: boolean
}

export interface SubmitPushNotification {
  notification: PushNotification
  mailList: string[]
}

const linkRegex =
  /^(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

interface PushNotificationsContextWrapper {
  notificationData: PushNotification
  sendNotification(): Promise<IHTTPResponse>
  formRef: React.RefObject<FormHandles>
  recipientList: string[]
  setRecipientList: React.Dispatch<React.SetStateAction<string[]>>
  imageBlob: File
  setImageBlob: React.Dispatch<React.SetStateAction<File>>
  handleClearFile: () => void
  handleSubmit: (data: PushNotification) => Promise<void>
  notificationList: INotification[]
  currentPage: number
  pagesNumber: number
  getNotificationList: (page: number) => Promise<void>
  clearFormAfterFinished: () => void
  setImageBase64: React.Dispatch<React.SetStateAction<string | undefined>>
  hasSubmitted: boolean
}

const PushNotificationsContext = createContext<PushNotificationsContextWrapper>(
  {} as PushNotificationsContextWrapper
)

const PushNotificationsProvider = ({ children }) => {
  const history = useHistory()
  const { callApi } = useAPI()

  const [recipientList, setRecipientList] = useState<string[]>([])

  const [notificationData, setNotificationData] = useState<PushNotification>({} as PushNotification)

  const [imageBlob, setImageBlob] = useState<File>({} as File)
  const [imageBase64, setImageBase64] = useState<string | undefined>()

  const [notificationList, setNotificationList] = useState<INotification[]>([])

  const [currentPage, setCurrentPage] = useState(1)
  const [pagesNumber, setPagesNumber] = useState(1)

  const formRef = useRef<FormHandles>(null)

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const handleClearFile = useCallback(() => {
    formRef.current?.clearField('image')
    setImageBlob({} as File)
  }, [])

  const getNotificationList = useCallback(
    async (page: number) => {
      const response = await callApi({
        method: 'get',
        url: `/Notification/All?page=${page}`
      })

      setCurrentPage(page)
      setPagesNumber(response.data.pagesCount)

      setNotificationList(response.data.notifications)
    },
    [callApi]
  )

  const sendNotification = useCallback(async () => {
    const userList = recipientList

    const submitNotificationData = {
      notification: notificationData,
      userList
    }

    const response = await callApi({
      method: 'post',
      url: '/Notification/Send',
      payload: submitNotificationData
    })

    return response
  }, [callApi, notificationData, recipientList])

  const clearFormAfterFinished = useCallback(() => {
    formRef.current?.clearField('image')
    formRef.current?.clearField('title')
    formRef.current?.clearField('message')
    formRef.current?.clearField('link')
    formRef.current?.clearField('notificationType')

    setHasSubmitted(false)
    setRecipientList([])
    setNotificationData({} as PushNotification)
    setImageBlob({} as File)
    setImageBase64(undefined)
  }, [])

  const handleSubmit = useCallback(
    async (data: PushNotification) => {
      setHasSubmitted(true)

      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          notificationType: Yup.string()
            .required('O tipo da notificação é obrigatório')
            .lowercase()
            .trim()
            .max(13),
          title: Yup.string().required('O título é obrigatório').lowercase().trim().max(40),
          message: Yup.string()
            .required('O conteúdo da mensagem não pode estar vazio')
            .lowercase()
            .trim()
            .max(1024),
          link: Yup.string()
            .lowercase()
            .trim()
            .max(300)
            .matches(linkRegex, { message: 'Link inválido', excludeEmptyString: true })
            .nullable()
        })

        await schema.validate(data, {
          abortEarly: false
        })

        setNotificationData({
          title: data.title,
          message: data.message,
          link: data.link,
          image: imageBase64 as string,
          notificationType: data.notificationType,
          imageBlob,
          systemNotification: false
        })

        if (recipientList?.length > 0) {
          history.push('/dashboard/notifications/review')
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }

        console.log('Error:', err)
      }
    },
    [history, imageBase64, imageBlob, recipientList?.length]
  )

  return (
    <PushNotificationsContext.Provider
      value={{
        notificationData,
        formRef,
        hasSubmitted,
        recipientList,
        setRecipientList,
        imageBlob,
        setImageBlob,
        handleClearFile,
        handleSubmit,
        sendNotification,
        notificationList,
        getNotificationList,
        clearFormAfterFinished,
        setImageBase64,
        currentPage,
        pagesNumber
      }}>
      {children}
    </PushNotificationsContext.Provider>
  )
}

function usePushNotifications(): PushNotificationsContextWrapper {
  const context = useContext(PushNotificationsContext)

  if (!context) {
    throw new Error('usePushNotifications must be used within an PushNotificationsProvider')
  }

  return context
}

export { PushNotificationsProvider, usePushNotifications }
