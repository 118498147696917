import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import PageContainer from 'components/layout/PageContainer'
import Button from 'components/ui/Button'
import Pagination from 'components/ui/Pagination'

import { usePushNotifications } from 'context/pushNotification'

import NotificationCard from 'domain/components/NotificationCard'
import { INotification } from 'domain/interfaces/INotification'

import { notificationCategoriesMap } from 'utils/notificationCategories'
import { useUser } from 'context/user'
import { pagesNames } from 'domain/constants/functionality'
import { hasPermission } from 'utils/permissions'

const notificationPermissions = pagesNames.NOTIFICATION.permissions
const listPermission = notificationPermissions.list
const createPermission = notificationPermissions.create

const ListNotifications = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const { userFunctionality = [] } = useUser()
  const { notificationList, getNotificationList, currentPage, pagesNumber } = usePushNotifications()

  const handleFetchData = useCallback(async () => {
    setIsLoading(true)
    await getNotificationList(currentPage)
    setIsLoading(false)
  }, [currentPage, getNotificationList])

  const handlePageChange = useCallback(
    async (page: number) => {
      setIsLoading(true)
      await getNotificationList(page)
      setIsLoading(false)
    },
    [getNotificationList]
  )

  useEffect(() => {
    handleFetchData()
  }, [])

  const canAccess = (screenName: string) =>
    hasPermission({ functionality: 'NOTIFICATION', userFunctionality, screenName })

  const headerChildren = canAccess(createPermission) && (
    <Button width={300} onClick={() => history.push('/dashboard/notifications/create')}>
      Nova Notificação
    </Button>
  )

  const bodyChildren = (
    <Pagination currentPage={currentPage} onPageChange={handlePageChange} pagesNumber={pagesNumber}>
      {notificationList.map((notification: INotification) => {
        return (
          <NotificationCard
            key={notification.dateCreated}
            category={notificationCategoriesMap[notification.notificationCategoryName]}
            recipientCount={notification.recipients}
            title={notification.title}
            body={notification.body}
            imageSrc={notification.image}
            imageAltText={notification.image}
            link={notification.link}
          />
        )
      })}
    </Pagination>
  )

  if (!canAccess(listPermission)) {
    return <Redirect to="/dashboard" />
  }

  return (
    <PageContainer
      title="Notificações Enviadas"
      headerChildren={headerChildren}
      bodyChildren={bodyChildren}
      modalList={[]}
      isLoading={isLoading}
    />
  )
}

export default ListNotifications
