import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
`

export const CampaignBasicData = styled.div`
  width: 100%;
  display: flex;

  label {
    flex: 1;
    color: ${({ theme }) => theme.colors.brandPrimary.light()};
    font-weight: bold;
    & + label {
      margin-left: 24px;
    }
  }
`

export const FakeInput = styled.div`
  background: ${({ theme }) => theme.colors.white()};
  border-radius: 12px;
  width: 100%;
  height: 44px;
  border: 3px solid ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.foundationalGray.dark()};
  position: relative;
  margin-top: 8px;
  padding: 16px;

  display: flex;
  align-items: center;
`

export const GroupSetup = styled.div`
  flex: 1;

  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  width: 100%;
  max-width: 100%;

  max-height: calc(100% - 140px);
  height: calc(100% - 140px);

  overflow-x: hidden;
`

export const ProductListWrapper = styled.div`
  h3 {
    color: ${({ theme }) => theme.colors.brandPrimary.light()};
    font-weight: bold;
  }

  width: 190px;
  max-height: 100%;
  padding-top: 24px;
`

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-top: 12px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.white()};
  border-radius: 12px;

  width: 100%;
  height: 100%;
  max-height: calc(100% - 33px);
  overflow-y: scroll;
  overflow-x: hidden;
`

export const GroupListWrapper = styled.div`
  h3 {
    color: ${({ theme }) => theme.colors.brandPrimary.light()};
    font-weight: bold;
  }

  flex: 1;
  overflow-x: hidden;

  padding-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const GroupListSetup = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
    color: ${({ theme }) => theme.colors.white()};
    padding: 4px;
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.brandPrimary.base()};
    }
  }
`

export const GroupList = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  margin-top: 12px;

  width: 100%;
  max-width: 100%;

  height: 100%;

  overflow-x: scroll;
`
