import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  max-width: 500px;
  flex-wrap: wrap;
`
