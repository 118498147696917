import styled from 'styled-components'

export const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const DataTableHead = styled.thead`
  tr:first-child th:first-child {
    border-top-left-radius: 32px;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 32px;
  }
`

export const DataTableBody = styled.tbody`
  tr td {
    border-top: 1px solid ${({ theme }) => theme.colors.foundationalBlack.light()};
  }
`

export const DataTableLine = styled.tr`
  background-color: ${({ theme }) => theme.colors.white()};
  line-height: 28px;

  td:first-child {
    padding-left: 32px;
  }

  th:first-child {
    padding-left: 32px;
  }
`

export const DataTableHeaderCell = styled.th`
  padding: 16px 16px;
  font-weight: 500;
  text-align: left;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.brandPrimary.light()};
`

export const DataTableCell = styled.td`
  padding: 16px 16px;
  text-align: left;
`
