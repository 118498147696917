import React from 'react'
import { Redirect } from 'react-router-dom'

import { useUser } from 'context/user'
import UserRoutes from 'routes/users'
import { hasFunctionality } from 'utils/permissions'

const Users = () => {
  const { userFunctionality = [] } = useUser()

  if (!hasFunctionality({ functionality: 'NOTIFICATION', userFunctionality })) {
    return <Redirect to="/dashboard" />
  }

  return <UserRoutes />
}

export default Users
