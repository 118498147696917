import React from 'react'

import ModalPortal, { IModalPortal } from 'components/layout/ModalPortal'
import LoadingPortal from 'components/layout/LoadingPortal'

import Breadcrumb from 'components/layout/Breadcrumb'

import { Container, PageHeader, PageHeaderChildren, PageTitle, PageBody } from './styles'

export interface IPageContainer {
  title?: string
  headerChildren?: React.ReactNode
  bodyChildren: React.ReactNode
  modalList: IModalPortal[]
  isLoading: boolean
}

const PageContainer: React.FC<IPageContainer> = ({
  title,
  headerChildren,
  bodyChildren,
  modalList,
  isLoading
}) => {
  return (
    <Container>
      <PageHeader>
        <Breadcrumb />
        <PageHeaderChildren>{headerChildren}</PageHeaderChildren>
      </PageHeader>
      <PageTitle>{title}</PageTitle>
      <PageBody>{bodyChildren}</PageBody>
      {modalList.length > 0 &&
        modalList.map((modal: IModalPortal) => (
          <ModalPortal
            key={modal.id}
            id={modal.id}
            type={modal.type}
            display={modal.display}
            props={modal.props}
          />
        ))}
      <LoadingPortal display={isLoading} />
    </Container>
  )
}

export default PageContainer
