import Button from 'components/ui/Button'
import { useByGeographicLocation } from 'domain/components/NotificationRecipientsSetup/context/selectionByGeoLocation'

import React, { useCallback } from 'react'
import { FiRefreshCcw } from 'react-icons/fi'
import { Multiselect } from 'react-widgets'

import { Container, SelectionSection } from './styles'

const ByGeographicLocationSelection = () => {
  const {
    stateProvinceList,
    stateProvinceSelection,
    updateStateProvinceSelection,
    filteredCities,
    citySelection,
    setCitySelection,
    updateExternalUsersByCityList,
    updateCitySelectionOptions,
    loadingStates,
    loadingCities,
    loadingUsers
  } = useByGeographicLocation()

  const handleApplyCitySelectionFilter = useCallback(() => {
    updateExternalUsersByCityList()
  }, [updateExternalUsersByCityList])

  return (
    <Container>
      <SelectionSection>
        <h3>Estados</h3>
        <Multiselect
          dataKey="name"
          textField="name"
          data={stateProvinceList}
          value={stateProvinceSelection}
          onChange={value => updateStateProvinceSelection(value)}
          busy={loadingStates}
        />
      </SelectionSection>
      <SelectionSection>
        <Button type="button" small onClick={updateCitySelectionOptions} busy={loadingCities}>
          <FiRefreshCcw size={21} />
        </Button>
      </SelectionSection>
      <SelectionSection>
        <h3>Cidades</h3>
        <Multiselect
          dataKey="cityName"
          textField="cityName"
          data={filteredCities}
          value={citySelection}
          onChange={value => setCitySelection(value)}
          busy={loadingCities}
        />
      </SelectionSection>
      <SelectionSection>
        <Button type="button" onClick={handleApplyCitySelectionFilter} busy={loadingUsers}>
          Aplicar Filtros
        </Button>
      </SelectionSection>
    </Container>
  )
}

export default ByGeographicLocationSelection
