/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react'
import { useDrop } from 'react-dnd'

import { FaTrash } from 'react-icons/fa'
import { FiAlertCircle } from 'react-icons/fi'
import { IoCloseOutline } from 'react-icons/io5'

import Modal, { RemoveProductModalRef } from './RemoveProductModal'
import Toast, { IToastRef } from 'components/ui/Toast'

import ProductTag from 'domain/components/CreateCampaignSetup/ProductTag'

import colors from 'styles/theme/colors'
import {
  ICampaignGroup,
  ICampaignProduct,
  useCreateCampaign
} from 'pages/SalesCampaign/CreateCampaign/context'

import {
  Container,
  GroupPrizeController,
  PrizeValueWrapper,
  PrizeValue,
  GroupItems,
  RemoveGroup,
  Error,
  PrizeValueError,
  ProductTagWapper
} from './styles'
import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'

interface IGroupBox {
  group: ICampaignGroup
  groupId: string
  editable: boolean
  hasError: boolean
  hasSubmitted: boolean
}

const modalRef = React.createRef<RemoveProductModalRef>()
const successToastRef = React.createRef<IToastRef>()
const errorToastRef = React.createRef<IToastRef>()

const GroupBox: React.FC<IGroupBox> = ({ group, groupId, editable, hasError, hasSubmitted }) => {
  const {
    forceRender,
    removeCampaignGroup,
    moveProduct,
    groupsWithRepeatedPrize,
    removeProduct,
    getBaseProductList
  } = useCreateCampaign()

  const [, dropRef] = useDrop({
    accept: 'PRODUCT',
    hover: (item: { id: string; groupId: string }) => {
      const fromGroupId = item.groupId
      const targetGroupId = groupId

      if (targetGroupId === fromGroupId) return null

      moveProduct({
        fromList: fromGroupId,
        toList: targetGroupId,
        productId: item.id
      })
      item.groupId = targetGroupId
      return null
    }
  })

  const handlePrizeChange = useCallback(
    e => {
      group.setPrize(Number(e.target.value))
      forceRender()
    },
    [forceRender, group]
  )

  const handleRemoveProduct = useCallback(
    async productId => {
      const response = await removeProduct(productId)
      if (response.status === HTTP_RESPONSE_STATUS.FAIL) return errorToastRef.current?.showToast()

      await getBaseProductList()
      successToastRef.current?.showToast()
    },
    [successToastRef, errorToastRef, removeProduct]
  )

  const hasProducts = group.products && group.products.length > 0

  return (
    <>
      <Toast title="Erro" description="Erro ao remover produto." ref={errorToastRef} />
      <Toast title="Sucesso" description="Produto removido com sucesso." ref={successToastRef} />
      <Container ref={dropRef} hasError={hasError} hasContent={hasProducts}>
        {editable && (
          <GroupPrizeController>
            <label>Valor da premiação</label>
            <PrizeValueWrapper
              hasError={
                (hasSubmitted && group.prize === 0) ||
                (hasSubmitted && groupsWithRepeatedPrize.includes(group))
              }>
              <PrizeValue
                type="number"
                min={1}
                value={group.prize}
                onChange={e => handlePrizeChange(e)}
                onFocus={e => e.target.select()}
              />
              {hasSubmitted && group.prize === 0 && (
                <PrizeValueError title="O valor da premiação não pode ser 0.">
                  <FiAlertCircle color={colors.feedback.negative()} size={20} />
                </PrizeValueError>
              )}
              {hasSubmitted && group.prize > 0 && groupsWithRepeatedPrize.includes(group) && (
                <PrizeValueError title="Não é permitido premiações com valores repetidos.">
                  <FiAlertCircle color={colors.feedback.negative()} size={20} />
                </PrizeValueError>
              )}
            </PrizeValueWrapper>
          </GroupPrizeController>
        )}
        <GroupItems>
          {hasProducts &&
            group.products.map((product: ICampaignProduct, index) => {
              return (
                <ProductTagWapper key={`${product.id}-index=${index}`}>
                  <ProductTag product={product} groupId={groupId} />
                  {!editable && (
                    <IoCloseOutline
                      size={24}
                      onClick={() => modalRef.current?.openModal(product.productId)}
                    />
                  )}
                </ProductTagWapper>
              )
            })}
        </GroupItems>
        {editable && group.products.length === 0 && (
          <RemoveGroup onClick={() => removeCampaignGroup(group.id)}>
            <FaTrash size={18} />
          </RemoveGroup>
        )}

        {hasError && !hasProducts && (
          <Error title="Todos os grupos devem conter pelo menos um produto.">
            <FiAlertCircle color={colors.feedback.negative()} size={20} />
          </Error>
        )}
      </Container>
      <Modal ref={modalRef} onSubmit={handleRemoveProduct} />
    </>
  )
}

export default GroupBox
