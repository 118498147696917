import React from 'react'
import { IUserTerritory } from 'domain/interfaces/IUserTerritory'

import Tag from 'components/ui/Tag'

import { Container } from './styles'

interface ILoggedInUserTerritoryList {
  territories: IUserTerritory[]
}

const LoggedInUserTerritoryList: React.FC<ILoggedInUserTerritoryList> = ({ territories }) => {
  return (
    <Container id="loggedInUserTerritoryList">
      {territories.map((userTerritory: IUserTerritory) => {
        return <Tag key={userTerritory.territory.code}>{userTerritory.territory.name}</Tag>
      })}
    </Container>
  )
}

export default LoggedInUserTerritoryList
