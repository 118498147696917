import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { IAccount } from 'domain/interfaces/IAccount'
import { IExternalUser } from 'domain/interfaces/IExternalUser'
import { usePushNotifications } from 'context/pushNotification'
import { useAPI } from 'context/api'

interface ByDistributionChannelContextWrapper {
  parentAccountList: IAccount[]
  parentAccountSelection: IAccount[]
  updateParentAccountSelection: (value: IAccount[]) => Promise<void>
  filteredChildrenAccounts: IAccount[]
  updateChildrenAccountSelectionOptions: () => void
  childrenAccountSelection: IAccount[]
  setChildrenAccountSelection: React.Dispatch<React.SetStateAction<IAccount[]>>
  filteredByChildrenAccountExternalUsers: IExternalUser[]
  updateExternalUsersByChildrenAccounts: () => Promise<void>
  loadingParentAccounts: boolean
  loadingChildrenAccounts: boolean
  loadingUsers: boolean
}

const ByDistributionChannelContext = createContext<ByDistributionChannelContextWrapper>(
  {} as ByDistributionChannelContextWrapper
)

const ByDistributionChannelProvider = ({ children }) => {
  const mounted = useRef(false)
  const { callApi } = useAPI()
  const { setRecipientList } = usePushNotifications()
  const [loadingParentAccounts, setLoadingParentAccounts] = useState(false)
  const [loadingChildrenAccounts, setLoadingChildrenAccounts] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)

  const [parentAccountList, setParentAccountList] = useState<IAccount[]>([])

  const [parentAccountSelection, setParentAccountSelection] = useState<IAccount[]>([])

  const [filteredChildrenAccounts, setFilteredChildrenAccounts] = useState<IAccount[]>([])

  const [childrenAccountSelection, setChildrenAccountSelection] = useState<IAccount[]>([])

  const [filteredByChildrenAccountExternalUsers, setFilteredByChildrenAccountExternalUsers] =
    useState<IExternalUser[]>([])

  const getParentAccountList = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: 'Account/ParentAccounts'
    })

    if (mounted) {
      setParentAccountList(response.data)
    }
  }, [callApi, setParentAccountList])

  const updateExternalUsersByChildrenAccounts = useCallback(async () => {
    setLoadingUsers(true)
    const childrenAccountNames = childrenAccountSelection.map((account: IAccount) => account.name)

    const parentAccountNames = parentAccountSelection.map((account: IAccount) => account.name)

    const accountNames = [...childrenAccountNames, ...parentAccountNames]

    const response = await callApi({
      method: 'post',
      url: '/User/ExternalUsersByAccount',
      payload: accountNames
    })

    if (mounted) {
      setFilteredByChildrenAccountExternalUsers(response.data)
      setRecipientList(response.data)
    }
    setLoadingUsers(false)
  }, [callApi, childrenAccountSelection, parentAccountSelection, setRecipientList])

  const updateChildrenAccountSelectionOptions = useCallback(async () => {
    if (parentAccountSelection.length > 0) {
      setLoadingChildrenAccounts(true)
      const parentAccountNames = parentAccountSelection.map((account: IAccount) => account.name)
      const response = await callApi({
        method: 'post',
        url: '/Account/ByParentAccount',
        payload: parentAccountNames
      })

      if (mounted) {
        setFilteredChildrenAccounts(response.data)
        const newChildrenAccountSelection = childrenAccountSelection.filter(
          (childrenAccount: IAccount) =>
            parentAccountSelection
              .map((parentAccount: IAccount) => parentAccount.name)
              .includes(childrenAccount.name)
        )
        setChildrenAccountSelection(newChildrenAccountSelection)
      }
      setLoadingChildrenAccounts(false)
    } else {
      setFilteredChildrenAccounts([])
      setChildrenAccountSelection([])
      setLoadingChildrenAccounts(false)
    }
  }, [callApi, childrenAccountSelection, parentAccountSelection])

  const updateParentAccountSelection = useCallback(async (value: IAccount[]) => {
    await setParentAccountSelection(value)
  }, [])

  const buildInitialDataLoad = useCallback(async () => {
    setLoadingParentAccounts(true)
    await getParentAccountList()
    setLoadingParentAccounts(false)
  }, [getParentAccountList])

  useEffect(() => {
    mounted.current = true
    buildInitialDataLoad()

    return () => {
      mounted.current = false
    }
  }, [buildInitialDataLoad])

  return (
    <ByDistributionChannelContext.Provider
      value={{
        parentAccountList,
        parentAccountSelection,
        updateParentAccountSelection,
        filteredChildrenAccounts,
        updateChildrenAccountSelectionOptions,
        childrenAccountSelection,
        setChildrenAccountSelection,
        filteredByChildrenAccountExternalUsers,
        updateExternalUsersByChildrenAccounts,
        loadingParentAccounts,
        loadingChildrenAccounts,
        loadingUsers
      }}>
      {children}
    </ByDistributionChannelContext.Provider>
  )
}

function useByDistributionChannel(): ByDistributionChannelContextWrapper {
  const context = useContext(ByDistributionChannelContext)

  if (!context) {
    throw new Error('useByDistributionChannel must be used within an ByDistributionChannelProvider')
  }

  return context
}

export { ByDistributionChannelProvider, useByDistributionChannel }
