import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'

import { FiAlertCircle } from 'react-icons/fi'
import colors from 'styles/theme/colors'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { Container, Error } from './styles'

registerLocale('pt', ptBR)

interface CustomDatePickerInput {
  name: string
  selected: Date | null
  minDate: Date | null
  onChangeHandler: (date: Date) => void
}

const CustomDatePickerInput: React.FC<CustomDatePickerInput> = ({
  name,
  selected,
  minDate,
  onChangeHandler,
  children: _children,
  ...rest
}) => {
  const datePickerRef = useRef<React.ElementRef<typeof DatePicker>>(null)

  const [hasContent, setHasContent] = useState(false)
  const [open, setOpen] = useState(false)
  const { fieldName, error, registerField } = useField(name)

  const handleChange = useCallback(
    (date: Date) => {
      setHasContent(!!date)
      onChangeHandler(date)

      setOpen(false)
      datePickerRef.current?.setBlur()
    },
    [onChangeHandler, datePickerRef]
  )

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePickerRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Container hasError={!!error} hasContent={hasContent}>
      <DatePicker
        ref={datePickerRef}
        locale="pt-BR"
        selected={selected}
        dateFormat="dd/MM/yyyy"
        name={name}
        minDate={minDate}
        placeholderText="Selecione"
        onChange={handleChange}
        onSelect={handleChange}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        open={open}
        shouldCloseOnSelect
        onInputClick={() => setOpen(open)}
        {...rest}></DatePicker>

      {error && !hasContent && (
        <Error title={error}>
          <FiAlertCircle color={colors.feedback.negative()} size={20} />
        </Error>
      )}
    </Container>
  )
}

export default CustomDatePickerInput
