import React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { ViewCampaignDetailsProvider } from 'pages/SalesCampaign/ViewCampaignDetails/context'
import ViewCampaignDetailsForm from 'pages/SalesCampaign/ViewCampaignDetails/ViewCampaignDetailsForm'

const ViewCampaignDetails = () => {
  return (
    <ViewCampaignDetailsProvider>
      <DndProvider backend={HTML5Backend}>
        <ViewCampaignDetailsForm />
      </DndProvider>
    </ViewCampaignDetailsProvider>
  )
}

export default ViewCampaignDetails
