import Tooltip from 'components/ui/Tooltip'
import styled, { css } from 'styled-components'

interface IContainer {
  hasError: boolean
  hasContent: boolean
}

export const Container = styled.div<IContainer>`
  background: ${({ theme }) => theme.colors.white()};
  border-radius: 10px;
  width: 100%;
  height: 48px;
  padding: 4px;
  border: 2px solid ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.foundationalGray.dark()};

  ${props =>
    props.hasError &&
    !props.hasContent &&
    css`
      border-color: ${({ theme }) => theme.colors.feedback.negative()};
    `}

  position: relative;
  margin-top: 8px;

  .react-datepicker-wrapper {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.white()};

    input {
      padding: 0 0.857em;
      width: 100%;
      min-height: 34px;
      border-radius: 8px;
    }
  }
  .react-datepicker {
    margin-top: 8px;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.foundationalGray.base()};
  }

  .react-datepicker__triangle {
    transform: translate3d(126px, 0px, 0px);
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: ${({ theme }) => theme.colors.foundationalGray.base()};
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: ${({ theme }) => theme.colors.foundationalGray.base()};
  }

  .react-datepicker__header {
    border-bottom: 1px solid ${({ theme }) => theme.colors.foundationalGray.base()};
  }

  .react-datepicker__tab-loop {
    position: relative;
  }

  .react-datepicker-popper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
  }
`

export const Error = styled(Tooltip)`
  position: absolute;
  top: calc(50% - 10px);
  right: 16px;

  height: 20px;
  margin-left: 14px;
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.feedback.negative()};
    color: ${({ theme }) => theme.colors.white()};
    &::before {
      border-color: ${({ theme }) => theme.colors.feedback.negative()} transparent;
    }
  }
`
