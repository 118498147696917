import React from 'react'

import format from 'date-fns/format'

import { ISalesCampaign, useListSalesCampaign } from 'pages/SalesCampaign/ListCampaigns/context'

import Button from 'components/ui/Button'

import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
  Container,
  CampaignCard,
  CampaignTitle,
  SubTitle,
  DataBox,
  DateInfo,
  Actions
} from './styles'

interface INotStartedCampaignList {
  onStartRemoveCampaign: (campaign: ISalesCampaign) => void
}

const NotStartedCampaignList: React.FC<INotStartedCampaignList> = ({ onStartRemoveCampaign }) => {
  const { notStartedCampaigns } = useListSalesCampaign()
  return (
    <Container>
      {notStartedCampaigns.map((campaign: ISalesCampaign) => {
        return (
          <CampaignCard key={campaign.code}>
            <CampaignTitle>
              <Link to={`/dashboard/sales-campaign/details/${campaign.code}`}>{campaign.name}</Link>
            </CampaignTitle>
            <SubTitle>Data de Início</SubTitle>
            <DataBox>
              <DateInfo>{format(new Date(campaign.startDate), 'dd / MM / yyyy')}</DateInfo>
            </DataBox>
            <Actions>
              <Button>
                <Link to={`/dashboard/sales-campaign/edit/${campaign.code}`}>
                  <FaPencilAlt size={18} />
                </Link>
              </Button>
              <Button light onClick={() => onStartRemoveCampaign(campaign)}>
                <FaTrash size={18} />
              </Button>
            </Actions>
          </CampaignCard>
        )
      })}
    </Container>
  )
}

export default NotStartedCampaignList
