import { ICampaignProduct, useCreateCampaign } from 'pages/SalesCampaign/CreateCampaign/context'
import React, { useRef } from 'react'

import { useDrag, useDrop } from 'react-dnd'
import { Container } from './styles'

interface IProductTag {
  product: ICampaignProduct
  groupId: string
}

const ProductTag: React.FC<IProductTag> = ({ product, groupId }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { moveProduct } = useCreateCampaign()
  const productId = product.id

  const [{ isDragging }, dragRef] = useDrag({
    type: 'PRODUCT',
    item: { id: productId, groupId },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [, dropRef] = useDrop({
    accept: 'PRODUCT',
    hover: (item: { id: string; groupId: string }) => {
      const fromGroupId = item.groupId
      const targetGroupId = groupId

      if (fromGroupId === targetGroupId) return null

      moveProduct({
        fromList: fromGroupId,
        toList: targetGroupId,
        productId: item.id
      })
      item.groupId = targetGroupId

      return null
    }
  })

  dragRef(dropRef(ref))

  return (
    <Container ref={ref} isDragging={isDragging}>
      {product.name.toUpperCase()}
    </Container>
  )
}

export default React.memo(ProductTag)
