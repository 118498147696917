/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react'

import ViewCampaignDetailsSetup from 'domain/components/ViewCampaignDetailsSetup'
import PageContainer from 'components/layout/PageContainer'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useViewCampaignDetails } from 'pages/SalesCampaign/ViewCampaignDetails/context'

import { IModalPortal } from 'components/layout/ModalPortal'

import Button from 'components/ui/Button'
import { Container } from './styles'
import { pagesNames } from 'domain/constants/functionality'
import { hasPermission } from 'utils/permissions'
import { useUser } from 'context/user'

const campaignPermissions = pagesNames.CAMPAIGN.permissions
const listPermission = campaignPermissions.list
const editPermission = campaignPermissions.edit

const ViewCampaignDetails = () => {
  const history = useHistory()
  const { campaignId } = useParams<{ campaignId: string }>()
  const { userFunctionality = [] } = useUser()

  const { campaignData, getCampaignData } = useViewCampaignDetails()

  const [isLoading, setIsLoading] = useState(false)

  const pageTitle = campaignData
    ? `Detalhes da Campanha ${campaignData.name}`
    : 'Detalhes da Campanha'

  const canAccess = (screenName: string) =>
    hasPermission({ functionality: 'CAMPAIGN', userFunctionality, screenName })

  const showEditButton =
    campaignData && campaignData.campaignStatus !== 0 && canAccess(editPermission)

  const headerChildren = useCallback(
    () =>
      showEditButton || isLoading ? null : (
        <Button
          width={300}
          onClick={() => history.push(`/dashboard/sales-campaign/edit/${campaignId}`)}>
          Editar Campanha
        </Button>
      ),
    []
  )

  const bodyChildren = campaignData ? <ViewCampaignDetailsSetup campaign={campaignData} /> : null

  const modalList: IModalPortal[] = []

  const loadBaseData = useCallback(async () => {
    setIsLoading(true)
    await getCampaignData(Number(campaignId))
    setIsLoading(false)
  }, [campaignId, getCampaignData])

  useEffect(() => {
    loadBaseData()
  }, [loadBaseData])

  if (!canAccess(listPermission)) return <Redirect to="/dashboard" />

  return (
    <Container>
      <PageContainer
        title={pageTitle}
        headerChildren={headerChildren}
        bodyChildren={bodyChildren}
        modalList={modalList}
        isLoading={isLoading}
      />
    </Container>
  )
}

export default ViewCampaignDetails
