import React from 'react'
import { Redirect } from 'react-router-dom'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { useUser } from 'context/user'
import { pagesNames } from 'domain/constants/functionality'

import { CreateCampaignProvider } from 'pages/SalesCampaign/CreateCampaign/context'
import CreateCampaignForm from 'pages/SalesCampaign/CreateCampaign/CreateCampaignForm'

import { hasPermission } from 'utils/permissions'

const screenName = pagesNames.CAMPAIGN.permissions.create

const CreateCampaign = () => {
  const { userFunctionality = [] } = useUser()

  if (!hasPermission({ functionality: 'CAMPAIGN', userFunctionality, screenName })) {
    return <Redirect to="/dashboard" />
  }

  return (
    <CreateCampaignProvider>
      <DndProvider backend={HTML5Backend}>
        <CreateCampaignForm />
      </DndProvider>
    </CreateCampaignProvider>
  )
}

export default CreateCampaign
