import { pagesNames } from 'domain/constants/functionality'
import { IUserFunctionality } from 'domain/interfaces/IInternalUser'

type PagesNamesKeys = keyof typeof pagesNames

type Functionality = {
  userFunctionality: IUserFunctionality[]
  functionality: PagesNamesKeys
}

type Permission = {
  userFunctionality: IUserFunctionality[]
  functionality: PagesNamesKeys
  screenName: string
}

type Filter = {
  functionality: PagesNamesKeys
  userPermission: IUserFunctionality
}

function filterFunctionality({ functionality, userPermission }: Filter) {
  return userPermission.code === pagesNames[functionality].code
}

export function hasPermission({
  userFunctionality,
  functionality,
  screenName
}: Permission): boolean {
  const handlePermission = (currentFunctionality: IUserFunctionality) => {
    if (!filterFunctionality({ functionality, userPermission: currentFunctionality })) return false

    return currentFunctionality.permissions.filter(p => p.screen === screenName).length > 0
  }

  return userFunctionality.filter(handlePermission).length > 0
}

export function hasFunctionality({ userFunctionality, functionality }: Functionality): boolean {
  return (
    userFunctionality.filter(userPermission =>
      filterFunctionality({ functionality, userPermission })
    ).length > 0
  )
}
