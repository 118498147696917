import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { v4 as uuidv4 } from 'uuid'

import { notificationCategoriesMap } from 'utils/notificationCategories'

import Button from 'components/ui/Button'

import NotificationCard from 'domain/components/NotificationCard'
import { IModalPortal, MODAL_TYPES, RESPONSE_MODAL_TITLE } from 'components/layout/ModalPortal'
import PageContainer from 'components/layout/PageContainer'
import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'
import { usePushNotifications } from 'context/pushNotification'

const ReviewAndSendNotification = () => {
  const history = useHistory()
  const { recipientList, notificationData, sendNotification, clearFormAfterFinished } =
    usePushNotifications()

  const imageSrc = notificationData.imageBlob?.name
    ? URL.createObjectURL(notificationData.imageBlob)
    : ''

  const goBackToForm = useCallback(() => {
    history.push('/dashboard/notifications/create')
  }, [history])

  const [isLoading, setIsLoading] = useState(false)

  const [displaySubmitModal, setDisplaySubmitModal] = useState(false)

  const [displayResponseModal, setDisplayResponseModal] = useState(false)
  const [responseTitle, setResponseTitle] = useState<string | undefined>()
  const [responseMessage, setResponseMessage] = useState<string | undefined>()

  const onStartSubmit = useCallback(() => {
    setDisplaySubmitModal(true)
  }, [])

  const onConfirmSubmit = useCallback(async () => {
    setDisplaySubmitModal(false)
    setIsLoading(true)
    const response = await sendNotification()
    setResponseMessage(response.message)
    const newResponseTitle =
      response.status === HTTP_RESPONSE_STATUS.SUCCESS
        ? RESPONSE_MODAL_TITLE.SUCCESS
        : RESPONSE_MODAL_TITLE.FAIL
    setResponseTitle(newResponseTitle)
    setIsLoading(false)
    setDisplayResponseModal(true)
  }, [sendNotification])

  const onCancelSubmit = useCallback(() => {
    setDisplaySubmitModal(false)
  }, [])

  const onFinish = useCallback(() => {
    setDisplayResponseModal(false)
    clearFormAfterFinished()
    history.push('/dashboard/notifications/list')
  }, [clearFormAfterFinished, history])

  const modalList: IModalPortal[] = [
    {
      id: uuidv4(),
      display: displaySubmitModal,
      type: MODAL_TYPES.CONFIRM_OR_CANCEL,
      props: {
        title: 'Confirmar envio',
        message: 'Você confirma o envio da notificação?',
        onConfirm: onConfirmSubmit,
        onCancel: onCancelSubmit
      }
    },
    {
      id: uuidv4(),
      display: displayResponseModal,
      type: MODAL_TYPES.INFORMATION,
      props: {
        title: responseTitle as string,
        message: responseMessage as string,
        onConfirm: onFinish
      }
    }
  ]

  const headerChildren = (
    <>
      <Button
        key="cancelSubmitNotification"
        width={200}
        style={{ marginRight: 8 }}
        light
        onClick={goBackToForm}>
        Voltar
      </Button>
      <Button key="confirmSubmitNotification" width={200} onClick={onStartSubmit}>
        Enviar
      </Button>
    </>
  )

  const bodyChildren = (
    <NotificationCard
      category={notificationCategoriesMap[notificationData.notificationType]}
      recipientCount={recipientList?.length}
      title={notificationData.title}
      body={notificationData.message}
      imageSrc={imageSrc}
      imageAltText={notificationData.imageBlob.name}
      link={notificationData.link}
    />
  )

  return (
    <PageContainer
      title="Confirmar Envio"
      headerChildren={headerChildren}
      bodyChildren={bodyChildren}
      modalList={modalList}
      isLoading={isLoading}
    />
  )
}

export default ReviewAndSendNotification
