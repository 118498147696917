import React from 'react'

import PageContainer from 'components/layout/PageContainer'

import { useUser } from 'context/user'

import CreateNotificationForm from 'domain/components/CreateNotificationForm'
import NotificationRecipientsSetup from 'domain/components/NotificationRecipientsSetup'

import { Flex } from './styles'
import { Redirect } from 'react-router-dom'
import { hasPermission } from 'utils/permissions'
import { pagesNames } from 'domain/constants/functionality'

const screenName = pagesNames.NOTIFICATION.permissions.create

const CreateNotification = () => {
  const { userFunctionality = [] } = useUser()

  if (!hasPermission({ functionality: 'NOTIFICATION', userFunctionality, screenName })) {
    return <Redirect to="/dashboard" />
  }

  const bodyChildren = (
    <Flex>
      <CreateNotificationForm />
      <NotificationRecipientsSetup />
    </Flex>
  )

  return (
    <PageContainer
      title="Criar nova notificação"
      headerChildren={null}
      bodyChildren={bodyChildren}
      modalList={[]}
      isLoading={false}
    />
  )
}

export default CreateNotification
