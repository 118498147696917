import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import SideBarSection from 'components/layout/SideBar/SideBarSection'
import SideBarItem from 'components/layout/SideBar/SideBarSection/SideBarItem'

import { ReactComponent as BrandLogoVertical } from 'assets/images/svg/Brevant_VerColor_Sementes_R.svg'

import { ReactComponent as BrandLogoHorizontal } from 'assets/images/svg/Brevant_Colors_horizontal.svg'

import { RiArrowLeftSLine } from 'react-icons/ri'
import { IoLogOutOutline } from 'react-icons/io5'
import { useAuth } from 'context/auth'
import { IAppPage, ISubPage, appPages } from 'utils/appPages'
import {
  Container,
  MenuItems,
  BrandSection,
  MenuControl,
  UserSection,
  UserBar,
  AvatarWrapper,
  Avatar,
  EmptyAvatar,
  Username,
  Logout,
  LogoSmall,
  LogoBig,
  MenuPages
} from './styles'
import { useUser } from 'context/user'
import { showMenu, showSubMenu } from './helper'

const SideBar = () => {
  const { pathname } = useLocation()

  const [open, setOpen] = useState(true)
  const toggle = useCallback(() => {
    setOpen(!open)
  }, [open])

  const { accountInfo, loggedInUser, signOut } = useAuth()
  const { fetchUserFunctionality, userFunctionality = [] } = useUser()

  const { isAuthenticated, user } = accountInfo
  const { firstName, lastName } = loggedInUser

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserFunctionality()
    }
  }, [])

  const getUsernameInitials = useCallback((first: string, last: string) => {
    return `${first[0]}${last[0]}`
  }, [])

  const getUsernameDisplayName = useCallback((first: string, last: string) => {
    return `${first} ${last[0]}.`
  }, [])

  let url = ''
  try {
    if (user.avatar) {
      url = isAuthenticated ? URL.createObjectURL(user.avatar) : ''
    }
  } catch {
    url = ''
  }

  return (
    <Container open={open}>
      <MenuControl open={open}>
        <RiArrowLeftSLine size={24} onClick={toggle} />
      </MenuControl>
      <MenuItems>
        <BrandSection open={open}>
          <LogoSmall open={open}>
            <BrandLogoVertical />
          </LogoSmall>
          <LogoBig open={open}>
            <BrandLogoHorizontal />
          </LogoBig>
        </BrandSection>
        <MenuPages open={open}>
          {appPages.map((section: IAppPage) => {
            if (!showMenu({ functionalityCode: section.code, userFunctionality })) return null

            return (
              <SideBarSection
                key={section.menuSection}
                Icon={section.Icon}
                title={section.menuSection}
                menuOpen={open}
                startOpen={section.sectionOpen}
                link={section.link}
                selected={section.link === pathname}>
                {section.subPages.map((item: ISubPage) => {
                  const show =
                    item.displayOnMenu &&
                    showSubMenu({
                      functionalityCode: section.code,
                      screenName: item.screenName,
                      userFunctionality
                    })
                  return show ? (
                    <SideBarItem
                      key={item.text}
                      Icon={item.Icon}
                      text={item.text}
                      link={item.link}
                      selected={item.link === pathname}
                      menuOpen={open}
                    />
                  ) : null
                })}
              </SideBarSection>
            )
          })}
        </MenuPages>
      </MenuItems>
      <UserSection open={open}>
        <UserBar open={open}>
          <AvatarWrapper>
            {url !== '' ? (
              <Avatar src={url} width={56} height={56} alt="avatar" />
            ) : (
              <EmptyAvatar>{getUsernameInitials(firstName, lastName)}</EmptyAvatar>
            )}
          </AvatarWrapper>
          <Username>{getUsernameDisplayName(firstName, lastName)}</Username>
        </UserBar>
        <Logout onClick={signOut}>
          <IoLogOutOutline size={24} />
        </Logout>
      </UserSection>
    </Container>
  )
}

export default SideBar
