import React, { useCallback, useEffect, useState } from 'react'
import { ReactComponent as BrandLogoWhite } from 'assets/images/svg/Brevant_HorWhite_Sementes_R.svg'
import { ReactComponent as BrandLogoDefault } from 'assets/images/svg/Brevant_VerColor_Sementes_R.svg'

import Button from 'components/ui/Button'
import { Loading } from 'components/ui/Loading'
import { useAuth } from 'context/auth'
import { Container, ColumnContainer, BrandLogoWrapper, ContentWrapper, Title, Text } from './styles'

const SignIn = () => {
  const { signIn, authFailed } = useAuth()
  const [isWaiting, setIsWaiting] = useState(true)

  const handleSignIn = useCallback(() => {
    const accessToken = localStorage.getItem('@AzureAd:accessToken')

    if (!accessToken) {
      signIn()
      setIsWaiting(true)
    }
  }, [signIn])

  useEffect(() => {
    const accessToken = localStorage.getItem('@AzureAd:accessToken')

    if (!accessToken) {
      setIsWaiting(false)
    }
  }, [])

  return isWaiting && !authFailed ? (
    <ColumnContainer>
      <div>
        <BrandLogoDefault />
      </div>
      <Loading />
    </ColumnContainer>
  ) : (
    <Container>
      <BrandLogoWrapper>
        <BrandLogoWhite />
      </BrandLogoWrapper>
      <ContentWrapper>
        {authFailed && (
          <>
            <Title>Erro no Login</Title>
            <Text>Não foi possível realizar seu login. Tente novamente.</Text>
          </>
        )}
        {!authFailed && (
          <>
            <Title>Login</Title>
            <Text>
              Faça login com sua conta para acessar as funcionalidades disponíveis para você.
            </Text>
          </>
        )}
        <Button type="button" onClick={handleSignIn}>
          Entrar
        </Button>
      </ContentWrapper>
    </Container>
  )
}

export default SignIn
