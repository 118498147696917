import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 2000;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.foundationalBlack.darkest(0.6)};
`

export const Container = styled.div`
  width: 90vw;
  max-width: 620px;
  min-height: 320px;
  background-color: ${({ theme }) => theme.colors.white()};
  padding: 48px 24px;
  box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.12), 10px -10px 20px rgba(0, 0, 0, 0.12);

  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  @keyframes slideUp {
    0% {
      transform: translateY(36px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 1023px) {
    width: 90vw;
    max-width: 620px;
  }

  animation: slideUp 0.4s;
`
