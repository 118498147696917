import Tooltip from 'components/ui/Tooltip'
import styled, { css } from 'styled-components'

interface IContainer {
  hasError: boolean
  hasContent: boolean
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  padding-bottom: 16px;
  margin: 12px 0;

  /* background: ${({ theme }) => theme.colors.white()}; */
  border: 3px solid transparent;
  border-radius: 12px;
  ${props =>
    props.hasError &&
    props.hasContent === false &&
    css`
      border-color: ${({ theme }) => theme.colors.feedback.negative()};
    `}
`

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  right: 16px;
  top: calc(50% - 10px);
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.feedback.negative()};
    color: ${({ theme }) => theme.colors.white()};
    &::before {
      border-color: ${({ theme }) => theme.colors.feedback.negative()} transparent;
    }
  }
`

interface IRadioButton {
  checked: boolean
}

export const RadioButton = styled.div<IRadioButton>`
  margin: 5px;
  cursor: pointer;
  width: auto;
  height: 20px;
  & + div {
    margin-left: 18px;
  }
  position: relative;
  display: flex;
  align-items: center;

  label {
    color: ${({ theme }) => theme.colors.foundationalBlack.dark()};
    margin-left: 28px;
    cursor: pointer;
  }
  &::before {
    content: '';
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;

    ${props =>
      !props.checked &&
      css`
        background: ${({ theme }) => theme.colors.white()};
        border: 1px solid ${({ theme }) => theme.colors.foundationalBlack.light()};
      `}
    ${props =>
      props.checked &&
      css`
        background: ${({ theme }) => theme.colors.brandPrimary.lightest(0.2)};
        border: 1px solid ${({ theme }) => theme.colors.brandPrimary.base()};
      `}
  }
`

export const Fill = styled.div`
  background: ${({ theme }) => theme.colors.brandPrimary.base()};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    opacity: 0;
    width: calc(20px - 4px);
    position: absolute;
    height: calc(20px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${({ theme }) => theme.colors.brandPrimary.base()};
    border-radius: 100%;
  }
`

export const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: 12px;
      height: 12px;
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`
