import styled, { css } from 'styled-components'

export const ContentContainer = styled.div`
  padding: 24px;
  min-height: 240px;
`

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.foundationalGray.dark()};
    color: ${theme.colors.foundationalBlack.dark()};
    padding-top: 24px;

    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      gap: 16px;
    }

    button {
      padding: 0;
      margin: 0;
    }
  `}
`

export const FieldLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.foundationalBlack.dark()};
    ${theme.font.smallText}
  `}
`

export const FieldContainer = styled.fieldset`
  display: grid;
  gap: 8px;
`
