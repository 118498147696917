/* eslint-disable react/require-default-props */
import React from 'react'
import { DropdownList } from 'react-widgets'
import { Container, Error } from './styles'
import { IoIosArrowDown } from 'react-icons/io'
import { FiAlertCircle } from 'react-icons/fi'

import colors from 'styles/theme/colors'
import { DropdownProps } from 'react-widgets/cjs/DropdownList'

export type ISelect<T = any> = DropdownProps<T> & {
  dataKey?: keyof T
  textField?: keyof T
  options: Array<T>
  name?: string
  value?: T | null | undefined
  defaultValue?: T
  error?: string
  onChange?: (selectedItem: T) => void
}

function Select<T>({ options, dataKey, textField, error, onChange, value, ...rest }: ISelect<T>) {
  const onSelect = (selectedItem: T) => {
    onChange && onChange(selectedItem)
  }

  return (
    <Container>
      <DropdownList
        {...rest}
        onChange={onSelect}
        textField={textField as string}
        dataKey={dataKey as string}
        focusFirstItem
        value={value}
        data={options}
        selectIcon={<IoIosArrowDown size={20} color={colors.foundationalBlack.light()} />}
      />

      {error && !value && (
        <Error title={error}>
          <FiAlertCircle color={colors.feedback.negative()} size={20} />
        </Error>
      )}
    </Container>
  )
}

export default Select
