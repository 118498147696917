import styled from 'styled-components'

export const Container = styled.h3`
  margin: 0;

  display: flex;
  align-items: center;
`

export const Step = styled.span`
  color: ${({ theme }) => theme.colors.foundationalBlack.light()};
  display: flex;
  align-items: center;

  svg {
    margin: 0 4px;
  }
`

export const CurrentLocation = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
`
