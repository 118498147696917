/* eslint-disable react/require-default-props */
import { useAuth } from 'context/auth'
import React from 'react'
import { RouteProps, Route as ReactDOMRoute, Redirect, useLocation } from 'react-router-dom'

interface RouteWrapper extends RouteProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const Route: React.FC<RouteWrapper> = ({ isPrivate = false, component: Component, ...rest }) => {
  const { accountInfo } = useAuth()
  const { pathname } = useLocation()

  if (localStorage.getItem('@BrevantApp:CurrentPath') === null) {
    localStorage.setItem('@BrevantApp:CurrentPath', '/dashboard')
  }

  if (pathname !== '/' && pathname !== '/dashboard') {
    localStorage.setItem('@BrevantApp:CurrentPath', pathname)
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!accountInfo.user.email ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate
                ? '/'
                : (localStorage.getItem('@BrevantApp:CurrentPath') as string),
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

export default Route
