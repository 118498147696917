import React, { createRef } from 'react'
import { IExternalUser } from 'domain/interfaces/IExternalUser'

import { pagesNames } from 'domain/constants/functionality'

import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableHeaderCell,
  DataTableLine
} from 'components/ui/DataTable'
import { hasPermission } from 'utils/permissions'
import { useUser } from 'context/user'
import colors from 'styles/theme/colors'

import { ButtonContainer } from './styles'
import EditUserModal, { EditUserModalRef, EditUserModalType } from '../EditUserModal'
import { RiMore2Fill } from 'react-icons/ri'
import { v4 as uuidv4 } from 'uuid'
import ModalPortal, { MODAL_TYPES } from '../../../components/layout/ModalPortal'
import { IInternalUser } from '../../interfaces/IInternalUser'
import DisableUserModal from '../DisableUserModal'

interface IActiveUsersDataTable {
  userList: IExternalUser[]
  onEdit: () => void
}

const userPermissions = pagesNames.USER.permissions
const editPermissionId = userPermissions.edit

const modalRef = createRef<EditUserModalRef>()

const ActiveUsersDataTable: React.FC<IActiveUsersDataTable> = ({ userList = [], onEdit }) => {
  const { userFunctionality = [] } = useUser()
  const [selectedDisableUser, setSelectedDisableUser] = React.useState<
    EditUserModalType | undefined
  >(undefined)

  const canAccess = (screenName: string) =>
    hasPermission({ functionality: 'USER', userFunctionality, screenName })

  const tableColumns = [
    'Nome',
    'Distribuidora',
    'Cidade',
    'Cargo',
    'Revisor',
    'Data',
    canAccess(editPermissionId) ? '' : null
  ]

  const openConfirmationModal = user => {
    modalRef.current?.closeModal()
    setSelectedDisableUser(user)
  }

  const onConfirmDisableUser = async () => {
    setSelectedDisableUser(undefined)
    onEdit()
  }

  return (
    <>
      <DataTable>
        <DataTableHead>
          <DataTableLine>
            {tableColumns.map(
              column =>
                column !== null && <DataTableHeaderCell key={column}>{column}</DataTableHeaderCell>
            )}
          </DataTableLine>
        </DataTableHead>
        <DataTableBody>
          {userList.length > 0 &&
            userList.map((user, index) => {
              const { email, city, firstName, lastName, userAccount, userAccountReview } = user

              const approvedOn =
                userAccountReview && userAccountReview.accountReviewedOn
                  ? new Date(userAccountReview.accountReviewedOn)
                  : null

              const approvedOnParsedDate = approvedOn
                ? `${approvedOn.getDate()}/${approvedOn.getMonth()}/${approvedOn.getFullYear()}`
                : ''

              const fullName = `${firstName} ${lastName}`
              const { account, userRole } = userAccount || { account: {}, userRole: {} }

              return (
                <DataTableLine key={`${email}-index=${index}`}>
                  <DataTableCell>{fullName}</DataTableCell>
                  <DataTableCell>{account?.name}</DataTableCell>
                  <DataTableCell>{city?.cityName}</DataTableCell>
                  <DataTableCell>{userRole?.portugueseName}</DataTableCell>
                  <DataTableCell>{userAccountReview?.accountReviewedBy}</DataTableCell>
                  <DataTableCell>{approvedOnParsedDate}</DataTableCell>
                  <DataTableCell>
                    <ButtonContainer
                      onClick={() => modalRef.current?.openModal({ userId: user?.id })}>
                      <RiMore2Fill size={24} color={colors.foundationalBlack.light()} />
                    </ButtonContainer>
                  </DataTableCell>
                </DataTableLine>
              )
            })}
        </DataTableBody>
      </DataTable>
      <EditUserModal
        onSubmit={onEdit}
        ref={modalRef}
        isExternal
        onDisableUser={openConfirmationModal}
      />
      <DisableUserModal
        userId={selectedDisableUser?.userId}
        isOpen={selectedDisableUser?.userId}
        onConfirm={onConfirmDisableUser}
        onCancel={() => setSelectedDisableUser(undefined)}
      />
    </>
  )
}

export default ActiveUsersDataTable
