import React, { useEffect, useRef, useState } from 'react'

import { useField } from '@unform/core'
import { FiAlertCircle } from 'react-icons/fi'
import { Container, Error, RadioButton, Input, Fill } from './styles'

export interface IRadioButton {
  id: string
  value: string
  name: string
  labelText: string
}

interface IRadioGroup {
  name: string
  options: IRadioButton[]
  initialValue: string
}

const RadioGroup: React.FC<IRadioGroup> = ({ name, options, initialValue, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, defaultValue = initialValue, error, registerField } = useField(name)

  const [hasContent, setHascontent] = useState(false)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs,
      getValue: refs => {
        return refs.current.find((input: HTMLInputElement) => input?.checked)?.value
      },
      setValue: (refs, id) => {
        const inputRef = refs.current.find((ref: HTMLInputElement) => ref.id === id)
        if (inputRef) inputRef.checked = true
      },
      clearValue: refs => {
        const inputRef = refs.current.find((ref: HTMLInputElement) => ref.checked === true)
        if (inputRef) inputRef.checked = false
      }
    })
  }, [fieldName, registerField])

  return (
    <Container hasError={!!error} hasContent={hasContent}>
      {options.map((option: IRadioButton, index) => {
        return (
          <RadioButton key={option.id} checked={defaultValue.includes(option.id)}>
            <label>
              {option.labelText}
              <Input
                type="radio"
                ref={(ref: HTMLInputElement) => {
                  inputRefs.current[index] = ref
                }}
                id={option.id}
                name={name}
                defaultChecked={defaultValue.includes(option.id)}
                value={option.value}
                {...rest}
                onClick={() => setHascontent(true)}
              />
              <Fill />
            </label>
          </RadioButton>
        )
      })}
      {error && !hasContent && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  )
}

export default RadioGroup
