import { Loading } from 'components/ui/Loading'
import React from 'react'

import { Wrapper } from './styles'

const FullPageLoading = () => {
  return (
    <Wrapper>
      <Loading />
    </Wrapper>
  )
}

export default FullPageLoading
