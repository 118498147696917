import { pagesNames } from 'domain/constants/functionality'

import { IconType } from 'react-icons'
import {
  AiOutlineCheck,
  AiOutlineExclamationCircle,
  AiOutlinePlus,
  AiOutlineUnorderedList
} from 'react-icons/ai'
import { FiSend, FiUser, FiUserX } from 'react-icons/fi'
import { HiOutlineCalculator, HiOutlineUserGroup } from 'react-icons/hi'
import { IoIosAddCircleOutline } from 'react-icons/io'
import { VscChromeClose } from 'react-icons/vsc'

export interface IAppPage {
  code: string
  Icon: IconType
  menuSection: string
  sectionOpen: boolean
  link: string | null
  subPages: ISubPage[]
}

export interface ISubPage {
  Icon: IconType | null
  text: string
  link: string
  displayOnMenu: boolean
  screenName: string
  breadcrumb: string[]
}

export const appPages: IAppPage[] = [
  {
    code: pagesNames.USER.code,
    Icon: HiOutlineUserGroup,
    menuSection: 'Usuários',
    sectionOpen: true,
    link: null,
    subPages: [
      {
        screenName: pagesNames.USER.permissions.approve,
        Icon: AiOutlineExclamationCircle,
        text: 'Pendentes',
        link: '/dashboard/users/user-approval',
        displayOnMenu: true,
        breadcrumb: ['Usuários', 'Pendentes']
      },
      {
        screenName: pagesNames.USER.permissions.list,
        Icon: AiOutlineCheck,
        text: 'Ativos',
        link: '/dashboard/users/active',
        displayOnMenu: true,
        breadcrumb: ['Usuários', 'Ativos']
      },
      {
        screenName: pagesNames.USER.permissions.register,
        Icon: FiUser,
        text: 'Internos',
        link: '/dashboard/users/internal',
        displayOnMenu: true,
        breadcrumb: ['Usuários', 'Internos']
      },
      {
        screenName: pagesNames.USER.permissions.list,
        Icon: VscChromeClose,
        text: 'Reprovados',
        link: '/dashboard/users/rejected',
        displayOnMenu: true,
        breadcrumb: ['Usuários', 'Reprovados']
      },
      {
        screenName: pagesNames.USER.permissions.list,
        Icon: FiUserX,
        text: 'Inativos',
        link: '/dashboard/users/inactivated',
        displayOnMenu: true,
        breadcrumb: ['Usuários', 'Inativos']
      },
      {
        screenName: pagesNames.USER.permissions.register,
        Icon: IoIosAddCircleOutline,
        text: 'Cadastrar',
        link: '/dashboard/users/register',
        displayOnMenu: true,
        breadcrumb: ['Usuários', 'Cadastrar usuários']
      }
    ]
  },
  {
    code: pagesNames.NOTIFICATION.code,
    Icon: FiSend,
    menuSection: 'Notificações',
    sectionOpen: true,
    link: null,
    subPages: [
      {
        screenName: pagesNames.NOTIFICATION.permissions.list,
        Icon: AiOutlineUnorderedList,
        text: 'Enviadas',
        link: '/dashboard/notifications/list',
        displayOnMenu: true,
        breadcrumb: ['Notificações', 'Enviadas']
      },
      {
        screenName: pagesNames.NOTIFICATION.permissions.create,
        Icon: AiOutlinePlus,
        text: 'Criar',
        link: '/dashboard/notifications/create',
        displayOnMenu: true,
        breadcrumb: ['Notificações', 'Criar Nova']
      },
      {
        screenName: pagesNames.NOTIFICATION.permissions.create,
        Icon: null,
        text: 'Revisão',
        link: '/dashboard/notifications/review',
        displayOnMenu: false,
        breadcrumb: ['Notificações', 'Revisar e Enviar']
      }
    ]
  },
  {
    code: pagesNames.CAMPAIGN.code,
    Icon: HiOutlineCalculator,
    menuSection: 'Campanhas',
    sectionOpen: true,
    link: null,
    subPages: [
      {
        screenName: pagesNames.CAMPAIGN.permissions.list,
        Icon: AiOutlineUnorderedList,
        text: 'Listar',
        link: '/dashboard/sales-campaign/list',
        displayOnMenu: true,
        breadcrumb: ['Campanhas', 'Listar']
      },
      {
        screenName: pagesNames.CAMPAIGN.permissions.create,
        Icon: AiOutlinePlus,
        text: 'Criar',
        link: '/dashboard/sales-campaign/create',
        displayOnMenu: true,
        breadcrumb: ['Campanhas', 'Criar Nova']
      },
      {
        screenName: pagesNames.CAMPAIGN.permissions.edit,
        Icon: null,
        text: 'Criar',
        link: '/dashboard/sales-campaign/edit',
        displayOnMenu: false,
        breadcrumb: ['Campanhas', 'Editar']
      },
      {
        screenName: pagesNames.CAMPAIGN.permissions.list,
        Icon: null,
        text: 'Criar',
        link: '/dashboard/sales-campaign/details',
        displayOnMenu: false,
        breadcrumb: ['Campanhas', 'Detalhes']
      }
    ]
  }
]
