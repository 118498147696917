import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import PageContainer from 'components/layout/PageContainer'
import ActiveUsersDataTable from 'domain/components/ActiveUsersDataTable'
import { useAuth } from 'context/auth'
import { useUser } from 'context/user'
import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'
import { IModalPortal, MODAL_TYPES, RESPONSE_MODAL_TITLE } from 'components/layout/ModalPortal'
import Pagination from 'components/ui/Pagination'
import { pagesNames } from 'domain/constants/functionality'
import { hasPermission } from 'utils/permissions'
import { Redirect } from 'react-router-dom'
import { USER_STATUS } from 'domain/constants/user'
import UserHeader from 'domain/components/UserHeader'

const screenName = pagesNames.USER.permissions.list

const ActiveUsers = () => {
  const {
    activeUsers,
    fetchUserList,
    pagesActiveNumber,
    currentActivePage,
    userFunctionality = []
  } = useUser()
  const { loggedInUser } = useAuth()

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  const [displayResponseModal, setDisplayResponseModal] = useState(false)
  const [responseTitle, setResponseTitle] = useState<string | undefined>()
  const [responseMessage, setResponseMessage] = useState<string | undefined>()

  const handleFetchData = useCallback(async () => {
    setIsLoading(true)
    const response = await fetchUserList(USER_STATUS.ACTIVE, currentActivePage)
    if (response && response.status === HTTP_RESPONSE_STATUS.FAIL) {
      setResponseMessage(response.message)
      setResponseTitle(RESPONSE_MODAL_TITLE.FAIL)
      setIsLoading(false)
      setDisplayResponseModal(true)
    }
    setIsLoading(false)
  }, [fetchUserList, currentActivePage])

  const handlePageChange = useCallback(
    async (page: number) => {
      setIsLoading(true)
      await fetchUserList(USER_STATUS.ACTIVE, page, search)
      setIsLoading(false)
    },
    [fetchUserList, search]
  )

  useEffect(() => {
    handleFetchData()
  }, [])

  const onFinishInformation = useCallback(() => {
    setDisplayResponseModal(false)
  }, [])

  const handleSearch = useCallback(
    async value => {
      const searchPage = 1
      await fetchUserList(USER_STATUS.ACTIVE, searchPage, value)
      setSearch(value)
    },
    [setSearch]
  )

  if (!hasPermission({ functionality: 'USER', userFunctionality, screenName })) {
    return <Redirect to="/dashboard" />
  }

  const modalList: IModalPortal[] = [
    {
      id: uuidv4(),
      display: displayResponseModal,
      type: MODAL_TYPES.INFORMATION,
      props: {
        title: responseTitle as string,
        message: responseMessage as string,
        onConfirm: onFinishInformation
      }
    }
  ]

  const headerChildren = (
    <UserHeader
      territories={loggedInUser.userTerritories}
      handleSearch={handleSearch}
      userStatus="ACTIVE"
    />
  )

  const bodyChildren = (
    <Pagination
      currentPage={currentActivePage}
      pagesNumber={pagesActiveNumber}
      onPageChange={handlePageChange}>
      <ActiveUsersDataTable
        userList={activeUsers}
        onEdit={() => handlePageChange(currentActivePage)}
      />
    </Pagination>
  )

  return (
    <PageContainer
      title="Usuários Ativos"
      headerChildren={headerChildren}
      bodyChildren={bodyChildren}
      modalList={modalList}
      isLoading={isLoading}
    />
  )
}

export default ActiveUsers
