/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React from 'react'

import { ICampaignGroup, ICampaignProduct } from 'pages/SalesCampaign/ViewCampaignDetails/context'

import ProductTag from '../ProductTag'

import { Container, GroupPrizeController, FakeInput, GroupItems } from './styles'

interface IGroupBox {
  group: ICampaignGroup
  hasError: boolean
}

const GroupBox: React.FC<IGroupBox> = ({ group, hasError }) => {
  const hasProducts = group.products && group.products.length > 0

  return (
    <Container hasError={hasError} hasContent={hasProducts}>
      <GroupPrizeController>
        <label>Valor da premiação</label>
        <FakeInput justify="center">{group.prize}</FakeInput>
      </GroupPrizeController>

      <GroupItems>
        {hasProducts &&
          group.products.map((product: ICampaignProduct) => {
            return <ProductTag key={product.id} product={product} />
          })}
      </GroupItems>
    </Container>
  )
}

export default GroupBox
