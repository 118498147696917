import { useAPI } from 'context/api'
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { IHTTPResponse } from 'utils/httpRequestHandler'

export interface ISalesCampaign {
  code: number
  name: string
  enrolledUsers: number
  totalUsers: number
  achievedGoals: number
  totalGoals: number
  campaignStatus: number
  startDate: Date
  endDate: Date | null
}

interface ListSalesCampaignContextWrapper {
  activeCampaigns: ISalesCampaign[]
  finishedCampaigns: ISalesCampaign[]
  notStartedCampaigns: ISalesCampaign[]
  getSalesCampaignData: () => Promise<void>
  finishCampaign: (code: number) => Promise<IHTTPResponse>
  removeCampaign: (code: number) => Promise<IHTTPResponse>
}

const ListSalesCampaignContext = createContext<ListSalesCampaignContextWrapper>(
  {} as ListSalesCampaignContextWrapper
)

const ListSalesCampaignProvider = ({ children }) => {
  const mounted = useRef(false)
  const { callApi } = useAPI()

  const [activeCampaigns, setActiveCampaigns] = useState<ISalesCampaign[]>([])

  const [notStartedCampaigns, setNotStartedCampaigns] = useState<ISalesCampaign[]>([])

  const [finishedCampaigns, setFinishedCampaigns] = useState<ISalesCampaign[]>([])

  const getSalesCampaignData = useCallback(async () => {
    setActiveCampaigns([])
    setFinishedCampaigns([])
    setNotStartedCampaigns([])
    const response = await callApi({
      method: 'get',
      url: '/Campaign/All'
    })

    if (mounted) {
      const campaigns = response?.data || []
      setActiveCampaigns(() => {
        return campaigns.filter((campaign: ISalesCampaign) => campaign.campaignStatus === 1)
      })
      setFinishedCampaigns(() => {
        return campaigns.filter((campaign: ISalesCampaign) => campaign.campaignStatus === 2)
      })
      setNotStartedCampaigns(() => {
        return campaigns.filter((campaign: ISalesCampaign) => campaign.campaignStatus === 0)
      })
    }
  }, [callApi])

  const finishCampaign = useCallback(
    async (code: number) => {
      const response = await callApi({
        method: 'put',
        url: `/Campaign/Finish/${code}`
      })

      await getSalesCampaignData()
      return response
    },
    [callApi, getSalesCampaignData]
  )

  const removeCampaign = useCallback(
    async (code: number) => {
      const response = await callApi({
        method: 'put',
        url: `/Campaign/Remove/${code}`
      })

      await getSalesCampaignData()
      return response
    },
    [callApi, getSalesCampaignData]
  )

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <ListSalesCampaignContext.Provider
      value={{
        activeCampaigns,
        finishedCampaigns,
        notStartedCampaigns,
        getSalesCampaignData,
        finishCampaign,
        removeCampaign
      }}>
      {children}
    </ListSalesCampaignContext.Provider>
  )
}

function useListSalesCampaign(): ListSalesCampaignContextWrapper {
  const context = useContext(ListSalesCampaignContext)

  if (!context) {
    throw new Error('useListSalesCampaign must be used within an ListSalesCampaignProvider')
  }

  return context
}

export { ListSalesCampaignProvider, useListSalesCampaign }
