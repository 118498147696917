import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'

import { FiAlertCircle } from 'react-icons/fi'
import colors from 'styles/theme/colors'
import { Container, Error, MaxLength } from './styles'

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  maxLength?: number
  name: string
  borderAlways?: boolean
}

const Input: React.FC<IInput> = ({ name, maxLength, borderAlways, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState(borderAlways || false)
  const [hasContent, setHasContent] = useState(false)
  const { fieldName, defaultValue, error, registerField } = useField(name)

  const [valueLength, setValueLength] = useState(0)

  const handleInputFocus = useCallback(() => {
    if (!borderAlways) setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    if (!borderAlways) setIsFocused(false)

    setHasContent(!!inputRef.current?.value)
  }, [])

  const handleChange = useCallback(() => {
    setHasContent(!!inputRef.current?.value)
    if (inputRef.current && inputRef.current.value) {
      setValueLength(inputRef.current?.value.length)
    }
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Container hasError={!!error} hasContent={hasContent} isFocused={isFocused}>
      <input
        type="text"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        onChange={handleChange}
        maxLength={maxLength}
        {...rest}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle color={colors.feedback.negative()} size={20} />
        </Error>
      )}

      {maxLength && inputRef.current && inputRef.current.value && (
        <MaxLength>{`${valueLength} / ${maxLength}`}</MaxLength>
      )}
    </Container>
  )
}

export default Input
