import React, { useRef, useState } from 'react'
import { useField } from '@unform/core'
import { WidgetHandle } from 'react-widgets'

import SelectComponent, { ISelect } from '../../Select'

function Select<T>({ name, ...rest }: ISelect<T>) {
  const selectRef = useRef<WidgetHandle & { value?: any }>(null)
  const [value, setValue] = useState<T | null>()
  const { fieldName, defaultValue, error, registerField } = useField(name || 'select')

  registerField({
    setValue: (_, e: any) => setValue(e),
    ref: selectRef.current,
    getValue: () => value,
    clearValue: () => setValue(null),
    name: fieldName
  })

  return (
    <SelectComponent<T>
      {...rest}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={setValue}
      error={error}
    />
  )
}

export default Select
