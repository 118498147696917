import React, { useCallback, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import EditCampaignSetup from 'domain/components/EditCampaignSetup'
import PageContainer from 'components/layout/PageContainer'
import { useHistory, useParams } from 'react-router-dom'
import { ICampaignFormData, useEditCampaign } from 'pages/SalesCampaign/EditCampaign/context'
import Button from 'components/ui/Button'
import { IModalPortal, MODAL_TYPES, RESPONSE_MODAL_TITLE } from 'components/layout/ModalPortal'
import { Form } from '@unform/web'

import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'
import { Container } from './styles'

const EditCampaignForm = () => {
  const history = useHistory()

  const { campaignId } = useParams<{ campaignId: string }>()

  const {
    campaignData,
    getCampaignData,
    buildInitialDataLoad,
    formRef,
    handleSubmit,
    editSalesCampaing
  } = useEditCampaign()

  const [isLoading, setIsLoading] = useState(false)

  const [displayCrationModal, setDisplayCreationModal] = useState(false)

  const [displayResponseModal, setDisplayResponseModal] = useState(false)
  const [responseTitle, setResponseTitle] = useState<string | undefined>()
  const [responseMessage, setResponseMessage] = useState<string | undefined>()

  const onStartCreation = useCallback(() => {
    setDisplayCreationModal(true)
  }, [])

  const onConfirmChanges = useCallback(async () => {
    setDisplayCreationModal(false)
    setIsLoading(true)
    const response = await editSalesCampaing()
    setResponseMessage(response.message)
    const newResponseTitle =
      response.status === HTTP_RESPONSE_STATUS.SUCCESS
        ? RESPONSE_MODAL_TITLE.SUCCESS
        : RESPONSE_MODAL_TITLE.FAIL
    setResponseTitle(newResponseTitle)
    setIsLoading(false)
    setDisplayResponseModal(true)
  }, [editSalesCampaing])

  const onCancelChanges = useCallback(() => {
    setDisplayCreationModal(false)
  }, [])

  const onFinishChanges = useCallback(() => {
    setDisplayResponseModal(false)
    history.push('/dashboard/sales-campaign/list')
  }, [history])

  const onSubmitForm = useCallback(
    async (data: ICampaignFormData) => {
      const passedValidation = await handleSubmit(data)
      if (passedValidation) {
        onStartCreation()
      }
    },
    [handleSubmit, onStartCreation]
  )

  const pageTitle = campaignData ? `Editar Campanha ${campaignData.name}` : 'Editar Campanha'

  const headerChildren = (
    <Button type="submit" width={300}>
      Salvar Alterações
    </Button>
  )

  const bodyChildren = campaignData ? <EditCampaignSetup campaign={campaignData} /> : null

  const modalList: IModalPortal[] = [
    {
      id: uuidv4(),
      display: displayCrationModal,
      type: MODAL_TYPES.CONFIRM_OR_CANCEL,
      props: {
        title: 'Editar Campanha de Vendas',
        message: 'Confirma as alterações nos detalhes da campanha?',
        onConfirm: onConfirmChanges,
        onCancel: onCancelChanges
      }
    },
    {
      id: uuidv4(),
      display: displayResponseModal,
      type: MODAL_TYPES.INFORMATION,
      props: {
        title: responseTitle as string,
        message: responseMessage as string,
        onConfirm: onFinishChanges
      }
    }
  ]

  const loadBaseData = useCallback(async () => {
    setIsLoading(true)
    await buildInitialDataLoad()
    await getCampaignData(Number(campaignId))
    setIsLoading(false)
  }, [buildInitialDataLoad, campaignId, getCampaignData])

  useEffect(() => {
    loadBaseData()
  }, [loadBaseData])

  return (
    <Container>
      <Form ref={formRef} onSubmit={onSubmitForm}>
        <PageContainer
          title={pageTitle}
          headerChildren={headerChildren}
          bodyChildren={bodyChildren}
          modalList={modalList}
          isLoading={isLoading}
        />
      </Form>
    </Container>
  )
}

export default EditCampaignForm
