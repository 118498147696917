import styled from 'styled-components'

export const LayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  transition: 0.6s all;
`

export const Main = styled.main`
  padding: 36px;
  height: 100%;
  flex: 1;

  overflow-y: scroll;
`
