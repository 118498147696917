import React from 'react'
import ReactDOM from 'react-dom'

import { Wrapper } from 'components/layout/LoadingPortal/styles'

import FullPageLoading from 'components/ui/FullPageLoading'

export interface ILoadingPortal {
  display: boolean
}

const LoadingPortal: React.FC<ILoadingPortal> = ({ display }) => {
  if (!display) return null

  const loadingPortal = document.getElementById('loadingPortal') as HTMLElement
  return ReactDOM.createPortal(
    <Wrapper>
      <FullPageLoading />
    </Wrapper>,
    loadingPortal
  )
}

export default LoadingPortal
