import styled, { css } from 'styled-components'

export const ContentContainer = styled.div`
  padding: 24px;
  min-height: 100px;
`

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.foundationalGray.dark()};
    color: ${theme.colors.foundationalBlack.dark()};
    padding-top: 24px;

    display: grid;
    gap: 16px;
    grid-template-columns: auto auto;

    justify-content: end;

    button {
      padding: 0;
      margin: 0;
    }
  `}
`

export const FieldLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.foundationalBlack.dark()};
    ${theme.font.smallText}
  `}
`

export const FieldContainer = styled.fieldset`
  display: grid;
  gap: 8px;
`

export const Input = styled.input`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.foundationalBlack.light()};
    color: ${theme.colors.foundationalBlack.darkest()};
    padding: 10px;
    border-radius: 8px;
    height: 40px;

    ${theme.font.breadCrumbActive}
  `}
`
