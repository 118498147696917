export default {
  white: (op = 1): string => `rgba(255,255,255,${op})`,

  brandPrimary: {
    lightest: (op = 1): string => `rgba(247, 201, 215, ${op})`,
    light: (op = 1): string => `rgba(172, 38, 77, ${op})`,
    base: (op = 1): string => `rgba(117, 13, 43, ${op})`,
    dark: (op = 1): string => `rgba(87, 0, 25, ${op})`
  },

  brandSecundary: {
    lightest: (op = 1): string => `rgba(255, 198, 143, ${op})`,
    light: (op = 1): string => `rgba(246, 160, 75, ${op})`,
    base: (op = 1): string => `rgba(244, 124, 6, ${op})`,
    dark: (op = 1): string => `rgba(202, 104, 8, ${op})`
  },

  foundationalBlack: {
    darkest: (op = 1): string => `rgba(0, 0, 0, ${op})`,
    dark: (op = 1): string => `rgba(94, 94, 94, ${op})`,
    base: (op = 1): string => `rgba(136, 136, 136, ${op})`,
    light: (op = 1): string => `rgba(170, 170, 170, ${op})`,
    lightest: (op = 1): string => `rgba(204, 204, 204, ${op})`
  },

  foundationalGray: {
    dark: (op = 1): string => `rgba(169, 179, 189, ${op})`,
    base: (op = 1): string => `rgba(200, 207, 213, ${op})`,
    light: (op = 1): string => `rgba(230, 232, 234, ${op})`,
    lightest: (op = 1): string => `rgba(238, 238, 238, ${op})`
  },

  feedback: {
    afirmative: (op = 1): string => `rgba(10, 165, 110, ${op})`,
    negative: (op = 1): string => `rgba(220, 47, 54, ${op})`
  },

  catalog: {
    sorghum: (op = 1): string => `rgba(47, 90, 170, ${op})`,
    corn: (op = 1): string => `rgba(0, 137, 116, ${op})`,
    soy: (op = 1): string => `rgba(255, 97, 74, ${op})`
  }
}
