import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-top: 36px;

  .rw-dropdown-list {
    width: 100%;
    margin-right: 24px;
  }

  .rw-widget-input {
    font-weight: normal;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.foundationalGray.light()};
  }

  .rw-widget-picker.rw-widget-input {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.foundationalGray.light()};
    box-shadow: none;
  }

  .rw-state-focus {
    border-radius: 8px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.foundationalGray.base()},
      inset 0 1px 1px ${({ theme }) => theme.colors.foundationalGray.base()};
  }

  .rw-popup {
    border-radius: 8px;
  }

  .rw-list-option.rw-state-selected {
    background-color: ${({ theme }) => theme.colors.foundationalGray.dark()};
    border-color: ${({ theme }) => theme.colors.foundationalGray.dark()};
  }
`

export const Title = styled.h3`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.brandPrimary.light()};
  font-weight: bold;
  margin-bottom: 24px;
`

export const Text = styled.p`
  width: 100%;
  text-align: center;
  margin-bottom: 24px;

  span {
    color: ${({ theme }) => theme.colors.brandPrimary.light()};
    font-weight: bold;
  }
`

export const Actions = styled.div`
  margin-top: 48px;

  grid-template-columns: 1fr 1fr;
  display: grid;
  gap: 16px;

  button {
    width: 100%;
  }
`
