import Tooltip from '../Tooltip'
import styled, { css } from 'styled-components'

interface IContainer {
  isFocused: boolean
  hasError: boolean
  hasContent: boolean
}

export const Container = styled.div<IContainer>`
  background: ${({ theme }) => theme.colors.white()};
  border-radius: 10px;
  padding: 8px 16px;

  width: 100%;
  height: 48px;
  border: 2px solid ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.foundationalGray.dark()};
  display: flex;
  align-items: center;
  justify-content: space-between;
  & + div {
    margin-top: 12px;
  }
  ${props =>
    props.hasError &&
    !props.hasContent &&
    css`
      border-color: ${({ theme }) => theme.colors.feedback.negative()};
    `}
  ${props =>
    props.isFocused &&
    css`
      color: ${({ theme }) => theme.colors.foundationalGray.base()};
      border-color: ${({ theme }) => theme.colors.foundationalGray.base()};
    `}
  input {
    flex: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    background: transparent;
    border: 0;
    &::placeholder {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.foundationalGray.dark()};
      font-size: ${({ theme }) => theme.font.largeTextBold};
    }
  }
  svg {
    margin-right: 14px;
  }

  position: relative;
`

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 14px;
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.feedback.negative()};
    color: ${({ theme }) => theme.colors.white()};
    &::before {
      border-color: ${({ theme }) => theme.colors.feedback.negative()} transparent;
    }
  }
`

export const MaxLength = styled.div`
  position: absolute;

  top: calc(100% + 10px);
  right: 10px;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.foundationalGray.dark()};
`
