import React, { useCallback, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { IModalPortal, MODAL_TYPES } from 'components/layout/ModalPortal'
import PageContainer from 'components/layout/PageContainer'
import { useUser } from 'context/user'
import { pagesNames } from 'domain/constants/functionality'
import { hasPermission } from 'utils/permissions'

import Form from './Form'
// import { Container } from './styles';

type ShowModalPropsType = {
  onConfirm: () => void
  message: string
  open: boolean
}

const defaultModalProps: ShowModalPropsType = {
  open: false,
  message: '',
  onConfirm: () => null
}

const screenName = pagesNames.USER.permissions.register

const RegisterUser = () => {
  const { userFunctionality = [] } = useUser()

  const [showModalProps, setShowModalProps] = useState<ShowModalPropsType>(defaultModalProps)
  const [loading, setLoading] = useState(false)

  const closeModal = () => setShowModalProps(prev => ({ ...prev, open: false }))

  const showModal = useCallback((props: ShowModalPropsType) => {
    setShowModalProps(props)
  }, [])

  if (!hasPermission({ functionality: 'USER', userFunctionality, screenName })) {
    return <Redirect to="/dashboard" />
  }

  const handleConfirmModal = async () => {
    setLoading(true)
    await showModalProps?.onConfirm()
    closeModal()
    setLoading(false)
  }

  const modalList: IModalPortal[] = [
    {
      id: uuidv4(),
      display: showModalProps?.open,
      type: MODAL_TYPES.CONFIRM_OR_CANCEL,
      props: {
        onConfirm: handleConfirmModal,
        onCancel: closeModal,
        title: 'Cadastro de Usuários',
        message: showModalProps?.message
      }
    }
  ]

  const bodyChildren = <Form showModal={showModal} />

  return <PageContainer bodyChildren={bodyChildren} modalList={modalList} isLoading={loading} />
}

export default RegisterUser
