import Tooltip from 'components/ui/Tooltip'
import styled, { css } from 'styled-components'

interface IContainer {
  hasError: boolean
  hasContent: boolean
}

export const Container = styled.div<IContainer>`
  background: ${({ theme }) => theme.colors.white()};
  border-radius: 10px;
  width: 100%;
  height: 48px;
  border: 2px solid ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.foundationalGray.dark()};

  font-weight: normal;

  ${props =>
    props.hasError &&
    !props.hasContent &&
    css`
      border-color: ${({ theme }) => theme.colors.feedback.negative()};
    `}

  position: relative;
  margin-top: 8px;

  .rw-state-focus,
  .rw-widget-input,
  .rw-widget-picker,
  .rw-dropdown-list-inp,
  .rw-input-add,
  .rw-picker-b,
  .rw-state-selected {
    border: none !important;
    box-shadow: none !important;

    padding: 0;
    margin: 0;
  }

  .rw-widget-picker {
    flex: 1;
    height: 44px;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
  }

  .rw-input-addon.rw-picker-btn {
    border: none;
    &:hover {
      background-color: ${({ theme }) => theme.colors.foundationalGray.lightest(0.3)};
    }
  }

  .rw-state-selected {
    background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
    border-color: ${({ theme }) => theme.colors.brandPrimary.light()};

    &:hover {
      background-color: ${({ theme }) => theme.colors.brandPrimary.light(0.9)} !important;
    }
  }

  .rw-widget-input {
    color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
    box-shadow: none;
    padding: 0px;
  }

  .rw-list-option {
    padding: 8px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.foundationalGray.lightest(0.3)};
      border: none;
    }
  }
`

export const Error = styled(Tooltip)`
  position: absolute;
  top: calc(50% - 10px);
  right: 32px;

  height: 20px;
  margin-left: 14px;
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.feedback.negative()};
    color: ${({ theme }) => theme.colors.white()};
    &::before {
      border-color: ${({ theme }) => theme.colors.feedback.negative()} transparent;
    }
  }
`
