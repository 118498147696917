import { BiCheck } from 'react-icons/bi'
import { BsX } from 'react-icons/bs'
import styled from 'styled-components'

export const Approve = styled(BiCheck)`
  width: 28px;
  height: auto;
  margin-right: 12px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white()};
  background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
  border-radius: 8px;
  padding: 2px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.brandPrimary.base()};
  }
`

export const Reject = styled(BsX)`
  width: 28px;
  height: auto;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
  border: 1px solid ${({ theme }) => theme.colors.foundationalBlack.darkest()};
  border-radius: 8px;
  padding: 2px;

  &:hover {
    color: ${({ theme }) => theme.colors.foundationalBlack.base()};
    border: 1px solid ${({ theme }) => theme.colors.foundationalBlack.base()};
  }
`
