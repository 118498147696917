import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'

import Modal, { IModalRef } from 'components/ui/Modal'
import Button from 'components/ui/Button'

import { ButtonsContainer, ContentContainer, FieldContainer, FieldLabel, Input } from './styles'

const modalRef = React.createRef<IModalRef>()

interface AddProductModalProps extends React.HTMLAttributes<'div'> {
  onSubmit?: (productName) => any
}

export interface AddProductModalRef {
  openModal: () => void
  closeModal: () => void
}

const EditUserModal = React.forwardRef<AddProductModalRef, AddProductModalProps>(
  ({ onSubmit }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [disabled, setDisabled] = useState(true)

    const cleanData = useCallback(() => null, [])

    const closeModal = useCallback(() => {
      modalRef.current?.closeModal()
      cleanData()
    }, [])

    const openModal = useCallback(() => {
      modalRef.current?.openModal()
    }, [])

    useImperativeHandle(ref, () => ({ openModal, closeModal }))

    const onSave = async () => {
      onSubmit && (await onSubmit(inputRef.current?.value))
      cleanData()
      closeModal()
    }

    const handleButtonDisabled: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      ({ target }) => {
        target.value = target.value.trim().toUpperCase()
        if (target.value.trim().length < 1) return setDisabled(true)
        return setDisabled(false)
      },
      []
    )

    return (
      <Modal title="Adicionar Produto" ref={modalRef} onClose={cleanData}>
        <ContentContainer>
          <FieldContainer>
            <FieldLabel>Produto</FieldLabel>
            <Input autoFocus ref={inputRef} onChange={handleButtonDisabled} maxLength={10} />
          </FieldContainer>
        </ContentContainer>
        <ButtonsContainer>
          <Button width={120} small light onClick={closeModal}>
            Cancelar
          </Button>
          <Button width={120} onClick={onSave} small disabled={disabled}>
            Salvar
          </Button>
        </ButtonsContainer>
      </Modal>
    )
  }
)

export default React.memo(EditUserModal)
