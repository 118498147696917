import React from 'react'
import { IExternalUser } from 'domain/interfaces/IExternalUser'

import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableHeaderCell,
  DataTableLine
} from 'components/ui/DataTable'

interface IRejectedUsersDataTable {
  userList: IExternalUser[]
}

const RejectedUsersDataTable: React.FC<IRejectedUsersDataTable> = ({ userList = [] }) => {
  const tableColumns = ['Nome', 'Distribuidora', 'Cidade', 'Cargo', 'Revisor', 'Data', 'Motivo']

  return (
    <DataTable>
      <DataTableHead>
        <DataTableLine>
          {tableColumns.map((column: string) => (
            <DataTableHeaderCell key={column}>{column}</DataTableHeaderCell>
          ))}
        </DataTableLine>
      </DataTableHead>
      <DataTableBody>
        {userList.length > 0 &&
          userList.map((user: IExternalUser, index) => {
            const { email, city, firstName, lastName, userAccount, userAccountReview } = user

            const rejectedOn =
              userAccountReview && userAccountReview.accountReviewedOn
                ? new Date(userAccountReview.accountReviewedOn)
                : null

            const rejectedOnParsedDate = rejectedOn
              ? `${rejectedOn.getDate()}/${rejectedOn.getMonth()}/${rejectedOn.getFullYear()}`
              : ''

            const fullName = `${firstName} ${lastName}`
            const { account, userRole } = userAccount || { account: {}, userRole: {} }

            return (
              <DataTableLine key={`${email}-index=${index}`}>
                <DataTableCell>{fullName}</DataTableCell>
                <DataTableCell>{account?.name}</DataTableCell>
                <DataTableCell>{city?.cityName}</DataTableCell>
                <DataTableCell>{userRole?.portugueseName}</DataTableCell>
                <DataTableCell>{userAccountReview?.accountReviewedBy}</DataTableCell>
                <DataTableCell>{rejectedOnParsedDate}</DataTableCell>
                <DataTableCell>{userAccountReview?.rejectionReason}</DataTableCell>
              </DataTableLine>
            )
          })}
      </DataTableBody>
    </DataTable>
  )
}

export default RejectedUsersDataTable
