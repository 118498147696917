/* eslint-disable react/require-default-props */
import React from 'react'

import { Container } from './styles'

interface TooltipProps {
  className?: string
  children?: React.ReactNode
  title: string
}

const Tooltip: React.FC<TooltipProps> = ({ title, className, children }) => {
  return (
    <Container className={className}>
      {children}
      <span>{title}</span>
    </Container>
  )
}

export default Tooltip
