import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import typography from 'styles/theme/typography'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  button {
    margin-left: 16px;
  }

  .Tag {
    height: 36px;
  }
`

export const InputWapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`

type InputContainerProps = HTMLAttributes<'div'> & {
  loading?: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  display: grid;
  grid-template-columns: 32px auto 32px;
  padding-left: 8px;
  align-items: center;

  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.foundationalBlack.darkest()};
  border-radius: 8px;
  width: 300px;

  margin: 0 16px;

  #loading {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.foundationalBlack.dark()};

    margin-left: 6px;
  }
`

export const Input = styled.input`
  ${typography.breadCrumbActive}
  background: transparent;
  vertical-align: baseline;
  border: none;
  height: 100%;
`
