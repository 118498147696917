import React from 'react'
import format from 'date-fns/format'

import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import {
  ICampaignGroup,
  useViewCampaignDetails
} from 'pages/SalesCampaign/ViewCampaignDetails/context'

import { ICampaign } from 'domain/interfaces/ICampaign'
import GroupBox from './GroupBox'
import {
  Container,
  CampaignBasicData,
  GroupSetup,
  GroupList,
  GroupListWrapper,
  GroupListSetup,
  FakeInput
} from './styles'

registerLocale('pt', ptBR)

interface IViewCampaignDetailsSetup {
  campaign: ICampaign
}

const ViewCampaignDetailsSetup: React.FC<IViewCampaignDetailsSetup> = ({ campaign }) => {
  const { campaignGroups } = useViewCampaignDetails()

  return (
    <Container>
      <CampaignBasicData>
        <label>
          Safra
          <FakeInput>{campaign.harvest.name}</FakeInput>
        </label>
        <label>
          Região
          <FakeInput>{campaign.territory.name}</FakeInput>
        </label>
        <label>
          Data de início
          <FakeInput>{format(new Date(campaign.startDate), 'dd / MM / yyyy')}</FakeInput>
        </label>
        {campaign.campaignStatus === 2 && campaign.endDate && (
          <label>
            Data de Encerramento
            <FakeInput>{format(new Date(campaign.endDate), 'dd / MM / yyyy')}</FakeInput>
          </label>
        )}
      </CampaignBasicData>
      <GroupSetup>
        <GroupListWrapper>
          <GroupListSetup>Premiações</GroupListSetup>
          <GroupList>
            {campaignGroups.map((group: ICampaignGroup) => (
              <GroupBox key={group.id} group={group} hasError={false} />
            ))}
          </GroupList>
        </GroupListWrapper>
      </GroupSetup>
    </Container>
  )
}

export default ViewCampaignDetailsSetup
