import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  max-height: 100%;
`

export const PageHeader = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  margin-bottom: 24px;
`

export const PageHeaderChildren = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const PageTitle = styled.h1`
  margin-bottom: 24px;
  text-transform: capitalize;

  ${({ theme }) => theme.font.heading2};
  color: ${({ theme }) => theme.colors.brandPrimary.light()};
`

export const PageBody = styled.div`
  padding-bottom: 24px;
`
