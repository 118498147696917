export default {
  family: {
    default: 'Open Sans, sans-serif'
  },
  heading1: {
    fontWeight: 800,
    fontSize: '2.75rem',
    lineHeight: '120%'
  },
  heading2: {
    fontWeight: 800,
    fontSize: '2rem',
    lineHeight: '120%'
  },
  heading3: {
    fontWeight: 800,
    fontSize: '1.5rem',
    lineHeight: '120%'
  },
  heading4: {
    fontWeight: 800,
    fontSize: '1.125rem',
    lineHeight: '120%'
  },
  largeTextRegular: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '140%'
  },
  largeTextBold: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '140%'
  },
  bodyText: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '120%'
  },
  bodyTextBold: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '120%'
  },
  smallText: {
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: '120%'
  },
  smallTextBold: {
    fontWeight: 800,
    fontSize: '0.75rem',
    lineHeight: '120%'
  },
  extraSmallText: {
    fontWeight: 700,
    fontSize: '0.625rem',
    lineHeight: '100%'
  },
  breadCrumbActive: {
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: '100%'
  },
  breadCrumbDisabled: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '100%'
  },
  caption: {
    fontWeight: 700,
    fontSize: '0.625rem',
    lineHeight: '120%'
  }
}
