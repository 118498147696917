import Tooltip from 'components/ui/Tooltip'
import { BiSitemap, BiStore } from 'react-icons/bi'
import { HiLocationMarker } from 'react-icons/hi'
import styled, { css } from 'styled-components'

interface IContainer {
  hasError: boolean
}

export const Container = styled.div<IContainer>`
  width: 380px;
  background-color: ${({ theme }) => theme.colors.white()};
  border-radius: 32px;
  box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.24);
  position: sticky;
  top: 0;
  margin-bottom: 48px;

  padding: 24px;

  border: 3px solid ${({ theme }) => theme.colors.white()};
  color: ${({ theme }) => theme.colors.foundationalBlack.dark()};

  button {
    width: 100%;
  }

  ${props =>
    props.hasError &&
    css`
      border-color: ${({ theme }) => theme.colors.feedback.negative()};
    `}
`

export const FilterTypeSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 2px solid ${({ theme }) => theme.colors.brandPrimary.light()};
  border-radius: 12px;
  margin-bottom: 28px;

  svg {
    cursor: pointer;
  }
`

interface IFilterTypeSelection {
  selected: boolean
}

interface IFilterTypeSelectionTab extends IFilterTypeSelection {
  tooltip: string
}

export const FilterTypeSelectionTab = styled.div<IFilterTypeSelectionTab>`
  padding: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.brandPrimary.light()};
  &:hover {
    background-color: ${({ theme }) => theme.colors.brandPrimary.light(0.87)};
    color: ${({ theme }) => theme.colors.white()};
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
      &:hover {
        background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
      }
    `};

  &:hover {
    position: relative;
    &:after {
      content: '${props => props.tooltip}';
      position: absolute;
      top: calc(100% + 4px);
      padding: 4px 12px;
      border-radius: 8px;

      background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
      color: ${({ theme }) => theme.colors.white()};
    }
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export const ByGeoLocation = styled(HiLocationMarker)<IFilterTypeSelection>`
  width: 28px;
  height: auto;

  ${props =>
    props.selected &&
    css`
      color: ${({ theme }) => theme.colors.white()};
    `};
`

export const ByCommercialLocation = styled(BiSitemap)<IFilterTypeSelection>`
  width: 28px;
  height: auto;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      color: ${({ theme }) => theme.colors.white()};
    `};
`

export const ByDistributionChannel = styled(BiStore)<IFilterTypeSelection>`
  width: 28px;
  height: auto;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      color: ${({ theme }) => theme.colors.white()};
    `};
`

export const Error = styled(Tooltip)`
  position: absolute;
  top: 12px;
  right: 12px;

  height: 20px;
  margin-left: 14px;
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.feedback.negative()};
    color: ${({ theme }) => theme.colors.white()};
    &::before {
      border-color: ${({ theme }) => theme.colors.feedback.negative()} transparent;
    }
  }
`
