import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { IExternalUser } from 'domain/interfaces/IExternalUser'
import { ITerritory } from 'domain/interfaces/ITerritory'
import { usePushNotifications } from 'context/pushNotification'
import { useAPI } from 'context/api'

interface ByCommercialLocationContextWrapper {
  mainRegionList: ITerritory[]
  mainRegionsSelection: ITerritory[]
  updateMainRegionsSelection: (value: ITerritory[]) => Promise<void>
  subRegionList: ITerritory[]
  subRegionSelection: ITerritory[]
  updateSubRegionsSelectionOptions: () => Promise<void>
  updateSubRegionSelection: (value: ITerritory[]) => Promise<void>
  updateChildrenTerritorySelectionOptions: () => Promise<void>
  filteredChildrenTerritories: ITerritory[]
  childrenTerritorySelection: ITerritory[]
  setChildrenTerritorySelection: React.Dispatch<React.SetStateAction<ITerritory[]>>
  updateExternalUsersByTerritorySelection: () => Promise<void>
  filteredByTerritoryExternalUsers: IExternalUser[]
  loadingMainRegions: boolean
  loadingSubRegions: boolean
  loadingChildrenTerritories: boolean
  loadingUsers: boolean
}

const ByCommercialLocationContext = createContext<ByCommercialLocationContextWrapper>(
  {} as ByCommercialLocationContextWrapper
)

const ByCommercialLocationProvider = ({ children }) => {
  const mounted = useRef(false)
  const { callApi } = useAPI()

  const { setRecipientList } = usePushNotifications()
  const [loadingMainRegions, setLoadingMainRegions] = useState(false)
  const [loadingSubRegions, setLoadingSubRegions] = useState(false)
  const [loadingChildrenTerritories, setLoadingChildrenTerritories] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)

  const [filteredByTerritoryExternalUsers, setFilteredByTerritoryExternalUsers] = useState<
    IExternalUser[]
  >([])

  const [mainRegionList, setMainRegionList] = useState<ITerritory[]>([])

  const [mainRegionsSelection, setMainRegionsSelection] = useState<ITerritory[]>([])

  const [subRegionList, setSubRegionList] = useState<ITerritory[]>([])

  const [subRegionSelection, setSubRegionSelection] = useState<ITerritory[]>([])

  const [filteredChildrenTerritories, setFilteredChildrenTerritories] = useState<ITerritory[]>([])

  const [childrenTerritorySelection, setChildrenTerritorySelection] = useState<ITerritory[]>([])

  const getMainRegionList = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: '/Territory/MainRegions'
    })

    if (mounted) {
      setMainRegionList(response.data)
    }
  }, [callApi, setMainRegionList])

  const updateSubRegionsSelectionOptions = useCallback(async () => {
    setLoadingSubRegions(true)
    const params = new URLSearchParams()

    const directParentTerritoryNames = mainRegionsSelection.map((territory: ITerritory) => {
      params.append('directParentTerritoryNames', territory.finalParentTerritoryName)
      return territory.finalParentTerritoryName
    })

    const response = await callApi({
      method: 'get',
      url: `/Territory/ByDirectParentTerritory?${params}`
    })

    setSubRegionList(response.data)
    const newChildrenTerritorySelection = subRegionSelection.filter((territory: ITerritory) =>
      directParentTerritoryNames.includes(territory.finalParentTerritoryName)
    )
    setSubRegionSelection(newChildrenTerritorySelection)
    setLoadingSubRegions(false)
  }, [callApi, mainRegionsSelection, subRegionSelection])

  const updateChildrenTerritorySelectionOptions = useCallback(async () => {
    setLoadingChildrenTerritories(true)

    const params = new URLSearchParams()

    const parentTerritoryNames = subRegionSelection.map((territory: ITerritory) => {
      params.append('parentTerritoryNames', territory.districtName)
      return territory.name
    })

    const response = await callApi({
      method: 'get',
      url: `/Territory/ByParentTerritoryWithAllChildren?${params}`
    })

    setFilteredChildrenTerritories(response.data)
    const newChildrenTerritorySelection = childrenTerritorySelection.filter(
      (territory: ITerritory) => parentTerritoryNames.includes(territory.districtName)
    )
    setChildrenTerritorySelection(newChildrenTerritorySelection)
    setLoadingChildrenTerritories(false)
  }, [callApi, childrenTerritorySelection, subRegionSelection])

  const updateMainRegionsSelection = useCallback(async (value: ITerritory[]) => {
    await setMainRegionsSelection(value)
  }, [])

  const updateSubRegionSelection = useCallback(async (value: ITerritory[]) => {
    await setSubRegionSelection(value)
  }, [])

  const updateExternalUsersByTerritorySelection = useCallback(async () => {
    setLoadingUsers(true)
    const territoryNames = childrenTerritorySelection.map((territory: ITerritory) => territory.name)
    const response = await callApi({
      method: 'post',
      url: '/User/ExternalUsersByTerritory',
      payload: territoryNames
    })

    setFilteredByTerritoryExternalUsers(response.data)
    setRecipientList(response.data)
    setLoadingUsers(false)
  }, [callApi, childrenTerritorySelection, setRecipientList])

  const buildInitialDataLoad = useCallback(async () => {
    setLoadingMainRegions(true)
    await getMainRegionList()
    setLoadingMainRegions(false)
  }, [getMainRegionList])

  useEffect(() => {
    mounted.current = true
    buildInitialDataLoad()

    return () => {
      mounted.current = false
    }
  }, [buildInitialDataLoad])

  return (
    <ByCommercialLocationContext.Provider
      value={{
        mainRegionList,
        mainRegionsSelection,
        updateMainRegionsSelection,
        subRegionList,
        subRegionSelection,
        updateSubRegionsSelectionOptions,
        updateSubRegionSelection,
        filteredChildrenTerritories,
        childrenTerritorySelection,
        setChildrenTerritorySelection,
        updateChildrenTerritorySelectionOptions,
        updateExternalUsersByTerritorySelection,
        filteredByTerritoryExternalUsers,
        loadingMainRegions,
        loadingSubRegions,
        loadingChildrenTerritories,
        loadingUsers
      }}>
      {children}
    </ByCommercialLocationContext.Provider>
  )
}

function useByCommercialLocation(): ByCommercialLocationContextWrapper {
  const context = useContext(ByCommercialLocationContext)

  if (!context) {
    throw new Error('useByCommercialLocation must be used within an ByCommercialLocationProvider')
  }

  return context
}

export { ByCommercialLocationProvider, useByCommercialLocation }
