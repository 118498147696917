import React, { useCallback, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form as Unform } from '@unform/web'
import Button from 'components/ui/Button'
import Input from 'components/ui/Input'
import Select from 'components/ui/Unform/Select'
import { IUserRole } from 'domain/interfaces/IUserRole'
import { IInviteUser } from 'domain/interfaces/IInternalUser'
import { ValidationError } from 'yup'

import {
  ButtonsContainer,
  Container,
  FieldContainer,
  FieldLabel,
  FieldValue,
  HintTextContainer,
  SubmitButtonContainer,
  UserContainer
} from './styles'
import { InviteUserSchema } from './schema'
import { useUser } from 'context/user'

const _roles: IUserRole[] = [
  { code: 1, name: 'DISTRICT SALES MANAGER', portugueseName: 'DSM' },
  { code: 5, name: 'FIELD MARKETING MANAGER', portugueseName: 'FMM' },
  { code: 24, name: 'SALES REPRESENTATIVE', portugueseName: 'REPRESENTANTE COMERCIAL' },
  { code: 42, name: 'ADMINISTRATOR', portugueseName: 'ADMINISTRADOR DO SISTEMA' }
]

type RegisterUserType = {
  showModal: (any) => void
}

const RegisterUser = ({ showModal }: RegisterUserType) => {
  const formRef = useRef<FormHandles>(null)
  const [users, setUser] = useState<IInviteUser[]>([])
  const [editing, setEditing] = useState<boolean>(false)
  const [response, setResponse] = useState({ error: 0, invited: 0, existing: 0 })
  const { inviteUser, getAssignableUserRole, assignableUserRole } = useUser()

  const onSubmit: SubmitHandler<IInviteUser> = async (data, { reset }) => {
    try {
      formRef.current?.setErrors({})

      await InviteUserSchema.validate(data, { abortEarly: false })
      setUser(prevUsers => [...prevUsers, { ...data, code: uuidv4() }] as IInviteUser[])

      reset()

      const nameInput = formRef.current?.getFieldRef('name')

      setEditing(false)
      if (nameInput) nameInput.focus()
    } catch (err) {
      const validationErrors = {}

      if (err instanceof ValidationError) {
        err.inner.forEach(error => (validationErrors[error?.path || 'unknow'] = error.message))
        formRef.current?.setErrors(validationErrors)
      }
    }
  }

  const handleRemove = useCallback(user => {
    setUser(prevUser => prevUser.filter(u => u.code !== user.code))
  }, [])

  const onConfirm = async () => {
    try {
      const invitedUser = users.map(u => ({ ...u, userRoleId: u.profile?.code }))
      const res = await inviteUser(invitedUser)

      setResponse({
        invited: res.data.invited.length,
        error: res.data.erros.length,
        existing: res.data.existing.length
      })

      setUser(userList =>
        userList.filter(
          u => !res.data.invited.includes(u.email) && !res.data.existing.includes(u.email)
        )
      )
    } catch (err) {
      console.log(err)
    }
  }

  const handleInvite = useCallback(async () => {
    const message = `Você confirma o cadastro de ${
      users.length > 1 ? `${users.length} novos usuários` : users[0].email
    }?`

    showModal({ open: true, message, onConfirm })
  }, [users])

  const handleEdit = useCallback(
    (user: IInviteUser) => {
      if (editing) return

      formRef.current?.reset()
      formRef.current?.setFieldValue('name', user.name)
      formRef.current?.setFieldValue('email', user.email)
      formRef.current?.setFieldValue('profile', user.profile)

      setEditing(true)
      handleRemove(user)
    },
    [handleRemove, editing]
  )

  const renderInvitedText = response.invited > 0 && (
    <>
      O cadastro de <b>{response.invited}</b> {response.invited > 1 ? ' usuários' : ' usuário'} foi
      feito com sucesso.
    </>
  )

  const renderExistingText = response.existing > 0 && (
    <>
      <b> {response.existing}</b>
      {response.existing > 1
        ? ' usuários da lista já existentem não foram adicionados.'
        : ' usuário da lista já existente não foi adicionado.'}
    </>
  )

  const renderErrorText = (
    <HintTextContainer hasError>
      Ocorreram erros com <b>{response.error}</b>
      {response.error > 1 ? ' usuários' : ' usuário'} abaixo. Confirme o e-mail digitado, edite ou
      remova o usuário da lista para continuar.
    </HintTextContainer>
  )

  useEffect(() => {
    getAssignableUserRole()
  }, [])

  return (
    <Container>
      {(response.existing > 0 || response.invited > 0) && (
        <HintTextContainer>
          {renderInvitedText}
          {renderExistingText}
        </HintTextContainer>
      )}
      {response.error > 0 && renderErrorText}

      <Unform ref={formRef} onSubmit={onSubmit}>
        <UserContainer>
          <FieldContainer>
            <FieldLabel>Nome</FieldLabel>
            <Input name="name" borderAlways autoFocus />
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>E-mail</FieldLabel>
            <Input name="email" borderAlways />
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>Perfil</FieldLabel>
            <Select<IUserRole>
              textField="portugueseName"
              options={assignableUserRole}
              name="profile"
              dataKey="code"
            />
          </FieldContainer>
          <ButtonsContainer style={{ gridTemplateAreas: "'button button'" }}>
            <Button style={{ gridArea: 'button' }} type="submit">
              {editing ? 'Salvar' : 'Adicionar usuário'}
            </Button>
          </ButtonsContainer>
        </UserContainer>
      </Unform>

      {users.map((invitedUser, index) => (
        <UserContainer key={invitedUser.email || index}>
          <FieldContainer>
            <FieldLabel>Nome</FieldLabel>
            <FieldValue>{invitedUser.name}</FieldValue>
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>E-mail</FieldLabel>
            <FieldValue>{invitedUser.email}</FieldValue>
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>Perfil</FieldLabel>
            <FieldValue>{invitedUser.profile?.portugueseName}</FieldValue>
          </FieldContainer>

          <ButtonsContainer>
            <Button
              className="added-user"
              disabled={editing}
              onClick={() => handleEdit(invitedUser)}
              light>
              Editar
            </Button>
            <Button className="added-user" onClick={() => handleRemove(invitedUser)} light>
              Remover
            </Button>
          </ButtonsContainer>
        </UserContainer>
      ))}

      <SubmitButtonContainer>
        <Button disabled={!users.length} width={300} onClick={handleInvite}>
          Enviar lista de usuários
        </Button>
      </SubmitButtonContainer>
    </Container>
  )
}

export default React.memo(RegisterUser)
