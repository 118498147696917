export function fileEncode(arrayBuffer): string {
  const b64encoded = btoa(
    [].reduce.call(
      new Uint8Array(arrayBuffer),
      (p, c): string => p + String.fromCharCode(c),
      ''
    ) as string
  )
  return b64encoded
}

export function downloadBase64({ filename, midiatype, codeBade64 }): void {
  const temp = `data:${midiatype};base64,${encodeURIComponent(codeBade64)}`
  const download = document.createElement('a')

  download.href = temp
  download.download = filename

  document.body.appendChild(download)
  download.click()
  document.body.removeChild(download)
}
