export const HTTP_RESPONSE_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail'
}

export interface IHTTPResponse {
  message: string
  status: string
  data?: any
}

export function mountHttpResponse(message: string, status: string, data?: any): IHTTPResponse {
  return {
    message,
    status,
    data
  }
}
