import React from 'react'
import { Redirect, Route as RouteDom, Switch } from 'react-router-dom'

import Route from 'routes/Route'

import Notifications from 'pages/Notifications'
import SalesCampaign from 'pages/SalesCampaign'
import Users from 'pages/Users'

const baseUrl = '/dashboard'

const DashboardRoutes = () => (
  <Switch>
    <Route path={`${baseUrl}/users`} component={Users} isPrivate />
    <Route path={`${baseUrl}/notifications`} component={Notifications} isPrivate />
    <Route path={`${baseUrl}/sales-campaign`} component={SalesCampaign} isPrivate />

    <RouteDom path={`${baseUrl}/*`} component={() => <Redirect to={baseUrl} />} />
  </Switch>
)

export default DashboardRoutes
