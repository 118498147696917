import styled from 'styled-components'

export const Container = styled.span`
  & + span {
    margin-left: 8px;
  }
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
  color: ${({ theme }) => theme.colors.white()};

  ${({ theme }) => theme.font.smallText};
`
