import React, { createContext, useCallback, useContext, useState } from 'react'
import { IExternalUser } from 'domain/interfaces/IExternalUser'

import { HTTP_RESPONSE_STATUS, IHTTPResponse } from 'utils/httpRequestHandler'
import { useAPI } from 'context/api'
import { IInternalUser, IInviteUser, IUserFunctionality } from 'domain/interfaces/IInternalUser'
import axios from 'axios'
import { AUTH_MECHANISM, USER_STATUS, USER_STATUS_ID } from 'domain/constants/user'
import { IExportExcel, IUpdateUser, UserStatusIdKeys } from 'domain/interfaces/IUsers'
import { IUserRole } from 'domain/interfaces/IUserRole'

interface UserContextWrapper {
  pendingApprovalUsers: IExternalUser[]
  activeUsers: IExternalUser[]
  internalUsers: IInternalUser[]
  rejectedUsers: IExternalUser[]
  disabledUsers: IExternalUser[] & IInternalUser[]
  userAccountRejectionReasons: string[]
  userFunctionality: IUserFunctionality[]
  assignableUserRole: IUserRole[]
  fetchUserList(userStatus: string, ...any): Promise<IHTTPResponse | null>
  getUserAccountRejectionReasons(): Promise<void>
  removeUserAfterReview(userEmail: string): void
  approveUserAccount(userId: number): Promise<IHTTPResponse>
  rejectUserAccount(userEmail: string, rejectionReason: string): Promise<IHTTPResponse>
  inviteUser(users: IInviteUser[]): Promise<IHTTPResponse>
  fetchUserFunctionality: () => Promise<IUserFunctionality[]>
  exportExcel: (config: IExportExcel) => Promise<IHTTPResponse>
  updateUser: (config: IUpdateUser) => Promise<IHTTPResponse>
  disableUser: (config: IUpdateUser) => Promise<IHTTPResponse>
  enableUser: (config: IUpdateUser) => Promise<IHTTPResponse>
  getAssignableUserRole: () => Promise<string>

  currentActivePage: number
  pagesActiveNumber: number
  currentPaddingApprovalPage: number
  pagesPaddingApprovalNumber: number
  currentRejectedPage: number
  pagesRejectedNumber: number
  currentInternalPage: number
  pagesInternalNumber: number
}

const UserContext = createContext<UserContextWrapper>({} as UserContextWrapper)

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: { Accept: '*/*' } })

const idToken = localStorage.getItem('@AzureAd:idToken')
api.defaults.headers['authorization'] = `Bearer ${idToken}`

const UserProvider = ({ children }) => {
  const { callApi } = useAPI()
  const [pendingApprovalUsers, setPendingApprovalUsers] = useState<IExternalUser[]>([])
  const [assignableUserRole, setAssignableUserRole] = useState<IUserRole[]>([])

  const [userFunctionality, setUserFunctionality] = useState<IUserFunctionality[]>([])

  const [currentActivePage, setCurrentActivePage] = useState(1)
  const [pagesActiveNumber, setPagesActiveNumber] = useState(1)

  const [currentPaddingApprovalPage, setCurrentPaddingApprovalPage] = useState(1)
  const [pagesPaddingApprovalNumber, setPagesPaddingApprovalNumber] = useState(1)

  const [currentRejectedPage, setCurrentRejectedPage] = useState(1)
  const [pagesRejectedNumber, setPagesRejectedNumber] = useState(1)

  const [currentInternalPage, setCurrentInternalPage] = useState(1)
  const [pagesInternalNumber, setPagesInternalNumber] = useState(1)

  const fetchUserFunctionality = useCallback(async () => {
    try {
      const response = await callApi({ method: 'get', url: '/User/Functionality' })

      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) setUserFunctionality(response.data)

      return response.data
    } catch (err) {
      console.log(err)
    }
  }, [callApi, setUserFunctionality])

  const getPendingApprovalUsers = useCallback(
    async (PageNumber = 1, search?) => {
      const params = new URLSearchParams({ PageNumber, search: search || '', PageSize: '10' })

      const response = await callApi({ method: 'get', url: `/User/PendingApproval?${params}` })

      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        setCurrentPaddingApprovalPage(PageNumber)
        setPagesPaddingApprovalNumber(response.data.pagesCount)

        setPendingApprovalUsers(response.data.users)
      }

      return response
    },
    [callApi, setPendingApprovalUsers]
  )

  const [activeUsers, setActiveUsers] = useState<IExternalUser[]>([])
  const getActiveUsers = useCallback(
    async (PageNumber = 1, search?) => {
      const params = new URLSearchParams({ PageNumber, search: search || '', PageSize: '10' })

      const response = await callApi({ method: 'get', url: `/User/ExternalActive?${params}` })

      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        setCurrentActivePage(PageNumber)
        setPagesActiveNumber(response.data.pagesCount)

        setActiveUsers(response.data.users)
      }

      return response
    },
    [callApi, setActiveUsers]
  )

  const [rejectedUsers, setRejectedUsers] = useState<IExternalUser[]>([])
  const getRejectedUsers = useCallback(
    async (PageNumber = 1, search?) => {
      const params = new URLSearchParams({ PageNumber, search: search || '', PageSize: '10' })

      const response = await callApi({ method: 'get', url: `/User/ExternalRejected?${params}` })

      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        setCurrentRejectedPage(PageNumber)
        setPagesRejectedNumber(response.data.pagesCount)

        setRejectedUsers(response.data.users)
      }

      return response
    },
    [callApi, setRejectedUsers]
  )

  const removeUserAfterReview = useCallback(
    (userEmail: string) => {
      const newList = pendingApprovalUsers.filter(user => user.email !== userEmail)
      setPendingApprovalUsers(newList)
    },
    [pendingApprovalUsers, setPendingApprovalUsers]
  )

  const [internalUsers, setInternalUsers] = useState<IInternalUser[]>([])
  const getInternalUsers = useCallback(
    async (PageNumber = 1, search?) => {
      const params = new URLSearchParams({ PageNumber, search: search || '', PageSize: '10' })

      const response = await callApi({ method: 'get', url: `/User/Internals?${params}` })

      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        setCurrentInternalPage(PageNumber)
        setPagesInternalNumber(response.data.pagesCount)

        setInternalUsers(response.data.users)
      }

      return response
    },
    [callApi, setInternalUsers]
  )

  const [disabledUsers, setDisabledUser] = useState<IInternalUser[] & IExternalUser[]>([])
  const getInactiveUsers = useCallback(
    async (PageNumber = 1, search?) => {
      const params = new URLSearchParams({ PageNumber, search: search || '', PageSize: '10' })

      const response = await callApi({ method: 'get', url: `/User/DisabledUsers?${params}` })

      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        setCurrentInternalPage(PageNumber)
        setPagesInternalNumber(response.data.pagesCount)
        setDisabledUser(response.data.users)
      }

      return response
    },
    [callApi, setDisabledUser]
  )

  const fetchUserList = useCallback(
    async (userStatus: string, ...prop) => {
      let response: IHTTPResponse
      switch (userStatus) {
        case USER_STATUS.PENDING_APPROVAL:
          response = await getPendingApprovalUsers(...prop)
          return response
        case USER_STATUS.ACTIVE:
          response = await getActiveUsers(...prop)
          return response
        case USER_STATUS.REJECTED:
          response = await getRejectedUsers(...prop)
          return response
        case USER_STATUS.INTERNAL:
          response = await getInternalUsers(...prop)
          return response
        case USER_STATUS.INACTIVE:
          response = await getInactiveUsers(...prop)
          return response
        default:
          return null
      }
    },
    [getActiveUsers, getPendingApprovalUsers, getRejectedUsers, getInternalUsers, getInactiveUsers]
  )

  const approveUserAccount = useCallback(
    async (userId: number) => {
      const response = await callApi({
        method: 'put',
        url: `/User/${userId}/Approve`
      })

      return response
    },
    [callApi]
  )

  const rejectUserAccount = useCallback(
    async (userEmail: string, rejectionReason: string) => {
      const response = await callApi({
        method: 'put',
        url: `/User/Reject/${userEmail}`,
        payload: { rejectionReason }
      })

      return response
    },
    [callApi]
  )

  const [userAccountRejectionReasons, setUserAccountRejectionReasons] = useState<string[]>([])

  const getUserAccountRejectionReasons = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: '/User/UserAccountRejectionReasons'
    })

    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
      setUserAccountRejectionReasons(response.data)
    } else {
      const reasons = ['Usuário desconhecido', 'Usuário não vinculado à distribuidora']
      setUserAccountRejectionReasons(reasons)
    }
  }, [callApi, setUserAccountRejectionReasons])

  const inviteUser = useCallback(
    async users => {
      const response = await callApi({ method: 'post', url: '/User/InviteUser', payload: users })

      return response
    },
    [callApi]
  )

  const updateUser = useCallback(async ({ userId, userRoleId }: IUpdateUser) => {
    const response = await callApi({
      payload: { userRoleId },
      url: `/User/${userId}`,
      method: 'put'
    })

    return response
  }, [])

  const disableUser = useCallback(async ({ userId }: IUpdateUser) => {
    const response = await callApi({
      url: `/User/${userId}/Disable`,
      method: 'put'
    })

    return response
  }, [])

  const enableUser = useCallback(async ({ userId }: IUpdateUser) => {
    const response = await callApi({
      url: `/User/${userId}/Enable`,
      method: 'put'
    })

    return response
  }, [])

  const getExportExcelExternal = (userStatus: UserStatusIdKeys): string => {
    const buildurl = status => `User/${status}/Export`

    switch (userStatus) {
      case 'ACTIVE':
        return buildurl('ExternalActive')
      case 'PENDING_APPROVAL':
        return buildurl('PendingApproval')
      case 'REJECTED':
        return buildurl('ExternalRejected')
    }

    return ''
  }

  const exportExcel = useCallback(
    async ({ userStatus, authMechanism = 'B2C', search = '' }: IExportExcel) => {
      const params = new URLSearchParams({ search })

      const response = await callApi({
        url:
          authMechanism === 'B2C'
            ? `${getExportExcelExternal(userStatus)}?${params}`
            : `/User/Internals/Export?${params}`,
        method: 'get'
      })

      return response
    },
    [callApi]
  )

  const getAssignableUserRole = useCallback(async () => {
    const response = await callApi({ url: '/User/UserRoles', method: 'get' })

    if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) setAssignableUserRole(response.data)
    else setAssignableUserRole([{ code: 43, name: 'MARKETING', portugueseName: 'MARKETING' }])

    return response.data
  }, [callApi])

  return (
    <UserContext.Provider
      value={{
        pendingApprovalUsers,
        activeUsers,
        internalUsers,
        rejectedUsers,
        disabledUsers,
        userAccountRejectionReasons,
        fetchUserList,
        getUserAccountRejectionReasons,
        removeUserAfterReview,
        approveUserAccount,
        rejectUserAccount,
        inviteUser,
        exportExcel,
        updateUser,
        getAssignableUserRole,
        fetchUserFunctionality,
        userFunctionality,
        currentInternalPage,
        pagesInternalNumber,
        currentActivePage,
        pagesActiveNumber,
        currentPaddingApprovalPage,
        pagesPaddingApprovalNumber,
        currentRejectedPage,
        pagesRejectedNumber,
        assignableUserRole,
        disableUser,
        enableUser
      }}>
      {children}
    </UserContext.Provider>
  )
}

function useUser(): UserContextWrapper {
  const context = useContext(UserContext)

  if (!context) console.error('useUser must be used within an UserProvider')

  return context
}

export { UserProvider, useUser }
