import React, { useCallback } from 'react'

import { FaTimes } from 'react-icons/fa'
import { Container, ImageContainer, RemoveFile } from './styles'

interface IImageInputFilled {
  handleClearFile: () => void
  previewURL: string
}

const ImageInputFilled: React.FC<IImageInputFilled> = ({ handleClearFile, previewURL }) => {
  const handleRemoveFile = useCallback(() => {
    handleClearFile()
  }, [handleClearFile])

  return (
    <Container>
      <ImageContainer>
        <img src={previewURL} alt="Preview da imagem" />
      </ImageContainer>

      <RemoveFile onClick={handleRemoveFile}>
        <FaTimes size={24} />
      </RemoveFile>
    </Container>
  )
}

export default ImageInputFilled
