import Button from 'components/ui/Button'
import { useByDistributionChannel } from 'domain/components/NotificationRecipientsSetup/context/selectionByDistributionChannel'

import React, { useCallback } from 'react'
import { FiRefreshCcw } from 'react-icons/fi'
import { Multiselect } from 'react-widgets'

import { Container, SelectionSection } from './styles'

const ByDistributionChannelSelection = () => {
  const {
    parentAccountList,
    parentAccountSelection,
    updateParentAccountSelection,
    updateChildrenAccountSelectionOptions,
    filteredChildrenAccounts,
    childrenAccountSelection,
    setChildrenAccountSelection,
    updateExternalUsersByChildrenAccounts,
    loadingParentAccounts,
    loadingChildrenAccounts,
    loadingUsers
  } = useByDistributionChannel()

  const handleApplyCitySelectionFilter = useCallback(() => {
    updateExternalUsersByChildrenAccounts()
  }, [updateExternalUsersByChildrenAccounts])

  return (
    <Container>
      <SelectionSection>
        <h3>Canais de Distribuição</h3>
        <Multiselect
          dataKey="name"
          textField="name"
          data={parentAccountList}
          value={parentAccountSelection}
          onChange={value => updateParentAccountSelection(value)}
          busy={loadingParentAccounts}
        />
      </SelectionSection>
      <SelectionSection>
        <Button
          type="button"
          small
          onClick={updateChildrenAccountSelectionOptions}
          busy={loadingChildrenAccounts}>
          <FiRefreshCcw size={21} />
        </Button>
      </SelectionSection>
      <SelectionSection>
        <h3>Filiais</h3>
        <Multiselect
          dataKey="name"
          textField="name"
          data={filteredChildrenAccounts}
          value={childrenAccountSelection}
          onChange={value => setChildrenAccountSelection(value)}
          busy={loadingChildrenAccounts}
        />
      </SelectionSection>
      <SelectionSection>
        <Button type="button" onClick={handleApplyCitySelectionFilter} busy={loadingUsers}>
          Aplicar Filtros
        </Button>
      </SelectionSection>
    </Container>
  )
}

export default ByDistributionChannelSelection
