import { IUserFunctionality } from 'domain/interfaces/IInternalUser'

type ShowMenu = {
  userFunctionality: IUserFunctionality[]
  functionalityCode: string
}

type ShowSubMenu = {
  userFunctionality: IUserFunctionality[]
  functionalityCode: string
  screenName: string
}

type FilterFunctionality = {
  functionalityCode: string
  userPermission: IUserFunctionality
}

function filterFunctionality({ functionalityCode, userPermission }: FilterFunctionality) {
  return userPermission.code === functionalityCode
}

export function showSubMenu({ userFunctionality, functionalityCode, screenName }: ShowSubMenu) {
  const handlePermission = (currentFunctionality: IUserFunctionality) => {
    if (!filterFunctionality({ functionalityCode, userPermission: currentFunctionality })) {
      return false
    }

    return currentFunctionality.permissions.filter(p => p.screen === screenName).length > 0
  }

  return userFunctionality.filter(handlePermission).length > 0
}

export function showMenu({ userFunctionality, functionalityCode }: ShowMenu) {
  const functionality = userFunctionality.filter(userPermission =>
    filterFunctionality({ functionalityCode, userPermission })
  )

  return functionality.length > 0 && functionality[0].permissions?.length > 0
}
