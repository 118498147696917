import React from 'react'

import { IModalProps } from 'components/layout/ModalPortal'

import Button from 'components/ui/Button'
import { Container, Title, Text } from './styles'

const InformationModal: React.FC<IModalProps> = ({ title, message, onConfirm }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Text>{message}</Text>
      <Button width={300} type="button" onClick={onConfirm}>
        Fechar
      </Button>
    </Container>
  )
}

export default InformationModal
