import { IRadioButton } from 'components/ui/RadioGroup'

interface INotificationCategoriesMap {
  [key: string]: string
}

export const notificationCategoriesMap: INotificationCategoriesMap = {
  tips: 'Dicas',
  news: 'Notícias',
  events: 'Eventos',
  promotions: 'Promoções',
  communication: 'Comunicado'
}

export const notificationCategories: IRadioButton[] = [
  {
    id: 'tips',
    value: 'tips',
    name: 'notificationType',
    labelText: notificationCategoriesMap.tips
  },
  {
    id: 'news',
    value: 'news',
    name: 'notificationType',
    labelText: notificationCategoriesMap.news
  },
  {
    id: 'events',
    value: 'events',
    name: 'notificationType',
    labelText: notificationCategoriesMap.events
  },
  {
    id: 'promotions',
    value: 'promotions',
    name: 'notificationType',
    labelText: notificationCategoriesMap.promotions
  },
  {
    id: 'communication',
    value: 'communication',
    name: 'notificationType',
    labelText: notificationCategoriesMap.communication
  }
]
