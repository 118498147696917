import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
`

export const CampaignCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white()};
  border-radius: 12px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.12);

  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const CampaignTitle = styled.h3`
  margin-bottom: 24px;
  ${({ theme }) => theme.font.heading3}
  color: ${({ theme }) => theme.colors.brandPrimary.light()};

  a {
    color: ${({ theme }) => theme.colors.brandPrimary.light()};
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      color: ${({ theme }) => theme.colors.brandPrimary.base()};
    }
  }
`

export const SubTitle = styled.h4`
  margin-bottom: 12px;
  ${({ theme }) => theme.font.heading4}
  color: ${({ theme }) => theme.colors.foundationalBlack.light()};
`

export const DataBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
`

interface IStatusBar {
  done: number
  total: number
}

export const StatusBar = styled.div<IStatusBar>`
  border-radius: 12px;
  width: 55%;
  height: 12px;

  background-color: ${({ theme }) => theme.colors.foundationalGray.light()};

  position: relative;

  &:after {
    content: '';
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 12px;

    background-color: ${({ theme }) => theme.colors.foundationalGray.dark()};
    width: ${props =>
      (100 * props.done) / props.total <= 100 ? `${(100 * props.done) / props.total}%` : `100%`};
  }
`

export const StatusData = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    ${({ theme }) => theme.font.bodyTextBold};
    color: ${({ theme }) => theme.colors.foundationalGray.base()};
    & + span {
      color: ${({ theme }) => theme.colors.foundationalGray.dark()};
      margin-left: 18px;

      position: relative;
      &:before {
        content: '/';
        ${({ theme }) => theme.font.heading4};
        font-weight: normal;
        position: absolute;
        left: -12px;
        top: -2px;
        bottom: 0;
      }
    }
  }
`

export const DateInfo = styled.h4`
  ${({ theme }) => theme.font.heading4}
`
