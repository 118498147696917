import styled, { css, keyframes } from 'styled-components'
import * as Dialog from '@radix-ui/react-dialog'

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }
})

export const DialogOverlay = styled(Dialog.Overlay)`
  ${({ theme }) => css`
    background-color: ${theme.colors.foundationalBlack.darkest(0.15)};
    position: fixed;
    inset: 0;
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 9999;
  `}
`

export const DialogContent = styled(Dialog.Content)`
  background-color: white;
  border-radius: 16px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 620px;
  max-height: 85vh;
  padding: 25;
  animation: ${contentShow} 300 cubic - bezier(0.16, 1, 0.3, 1);
  z-index: 99999;

  padding: 24px;

  &:focus {
    outline: none;
  }
`

export const DialogTitle = styled(Dialog.Title)`
  ${({ theme }) => css`
    margin: 0;
    font-weight: 500;
    font-size: 17px;
    text-align: center;

    color: ${({ theme }) => theme.colors.brandPrimary.light()};
    ${theme.font.heading4}
  `}
`

export const DialogDescription = styled(Dialog.Description)`
  margin: 10px 0 20px;
  font-size: 15px;
  line-height: 150%;
`
