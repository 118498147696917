import React from 'react'
import ReactDOM from 'react-dom'

import { Container, Wrapper } from 'components/layout/ModalPortal/styles'
import ConfirmOrCancelModal from 'components/ui/ConfirmOrCancelModal'
import InformationModal from 'components/ui/InformationModal'

export const RESPONSE_MODAL_TITLE = {
  SUCCESS: 'Tudo Certo!',
  FAIL: 'Erro!',
  WARNING: 'Atenção'
}

export const MODAL_TYPES = {
  CONFIRM_OR_CANCEL: 'ConfirmOrCancel',
  INFORMATION: 'Information'
}

export interface IModalProps {
  title: string
  message: string
  onConfirm: () => void
  onCancel?: () => void
  options?: string[]
  selectedOption?: string
  onChangeOption?: (value: string) => void
}

export interface IModalPortal {
  id: string
  display: boolean
  type: string
  props: IModalProps
}

const ModalPortal: React.FC<IModalPortal> = ({ display, type, props }) => {
  if (!display) return null

  const modalPortal = document.getElementById('modalPortal') as HTMLElement
  return ReactDOM.createPortal(
    <Wrapper>
      <Container>
        {type === MODAL_TYPES.CONFIRM_OR_CANCEL && (
          <ConfirmOrCancelModal
            title={props.title}
            message={props.message}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            options={props.options}
            selectedOption={props.selectedOption}
            onChangeOption={props.onChangeOption}
          />
        )}
        {type === MODAL_TYPES.INFORMATION && (
          <InformationModal
            title={props.title}
            message={props.message}
            onConfirm={props.onConfirm}
          />
        )}
      </Container>
    </Wrapper>,
    modalPortal
  )
}

export default ModalPortal
