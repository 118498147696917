import React from 'react'

import { IModalProps } from 'components/layout/ModalPortal'

import Button from 'components/ui/Button'
import { DropdownList } from 'react-widgets'
import { Container, Text, Actions, Title } from './styles'

const ConfirmOrCancelModal: React.FC<IModalProps> = ({
  title,
  message,
  onConfirm,
  onCancel,
  options,
  selectedOption,
  onChangeOption
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Text>{message}</Text>
      {options && options.length > 0 && (
        <DropdownList defaultValue={selectedOption} data={options} onChange={onChangeOption} />
      )}
      <Actions>
        <Button type="button" onClick={onCancel} light>
          Cancelar
        </Button>
        <Button type="button" onClick={onConfirm}>
          Confirmar
        </Button>
      </Actions>
    </Container>
  )
}

export default ConfirmOrCancelModal
