import React from 'react'
import { IExternalUser } from 'domain/interfaces/IExternalUser'

import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableHeaderCell,
  DataTableLine
} from 'components/ui/DataTable'

import { Approve, Reject } from './styles'

interface IUserApprovalDataTable {
  userList: IExternalUser[]
  onStartApproval: (user: IExternalUser) => void
  onStartRejection: (user: IExternalUser) => void
}

const UserApprovalDataTable: React.FC<IUserApprovalDataTable> = ({
  userList = [],
  onStartApproval,
  onStartRejection
}) => {
  const tableColumns = ['Nome', 'Distribuidora', 'Cidade', 'Cargo', 'Ações']

  return (
    <DataTable>
      <DataTableHead>
        <DataTableLine>
          {tableColumns.map((column: string) => (
            <DataTableHeaderCell key={column}>{column}</DataTableHeaderCell>
          ))}
        </DataTableLine>
      </DataTableHead>
      <DataTableBody>
        {userList.length > 0 &&
          userList.map((user: IExternalUser, index) => {
            const { email, firstName, lastName, userAccount, city } = user
            const fullName = `${firstName} ${lastName}`
            const { account, userRole } = userAccount || { account: {}, userRole: {} }

            return (
              <DataTableLine key={`${email}-index=${index}`}>
                <DataTableCell>{fullName}</DataTableCell>
                <DataTableCell>{account?.name}</DataTableCell>
                <DataTableCell>{city?.cityName}</DataTableCell>
                <DataTableCell>{userRole?.portugueseName}</DataTableCell>
                <DataTableCell>
                  <Approve onClick={() => onStartApproval(user)} />
                  <Reject onClick={() => onStartRejection(user)} />
                </DataTableCell>
              </DataTableLine>
            )
          })}
      </DataTableBody>
    </DataTable>
  )
}

export default UserApprovalDataTable
