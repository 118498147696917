import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${({ theme }) => theme.colors.foundationalGray.lightest()};
  }
  body, input, button {
    font-family: ${({ theme }) => theme.font.family.default};
    font-size: ${({ theme }) => theme.font.bodyText.fontSize};
  }
  *, button, input {
    border: 0;
    outline: 0;
  }
  button {
    cursor: pointer;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  ul { list-style: none }


  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.colors.foundationalBlack.light(0.8)} transparent;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 7px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.foundationalBlack.light(0.8)};
    border-radius: 4px;
    border: none;
  }

`
