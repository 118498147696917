import { useUser } from 'context/user'
import { Redirect } from 'react-router-dom'

import { ListSalesCampaignProvider } from 'pages/SalesCampaign/ListCampaigns/context'

import SalesCampaignRoutes from 'routes/salesCampaign'
import { hasFunctionality } from 'utils/permissions'

const SalesCampaign = () => {
  const { userFunctionality = [] } = useUser()

  if (!hasFunctionality({ functionality: 'CAMPAIGN', userFunctionality })) {
    return <Redirect to="/dashboard" />
  }

  return (
    <ListSalesCampaignProvider>
      <SalesCampaignRoutes />
    </ListSalesCampaignProvider>
  )
}

export default SalesCampaign
