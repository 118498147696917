import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'

import { IProduct } from 'domain/interfaces/IProduct'
import { IHarvest } from 'domain/interfaces/IHarvest'
import { ITerritory } from 'domain/interfaces/ITerritory'
import getValidationErrors from 'utils/getValidationErrors'
import { IHTTPResponse } from 'utils/httpRequestHandler'
import { useAPI } from 'context/api'

export interface ICampaignProduct {
  id: string
  name: string
  productId: number
}

export interface ICampaignGroup {
  id: string
  prize: number
  products: ICampaignProduct[]
  setPrize: (value: number) => void
  setProducts: (products: ICampaignProduct[]) => void
  addProduct(product: ICampaignProduct): void
  removeProduct(id: string): void
}

class CampaignGroup implements ICampaignGroup {
  id: string

  prize: number

  products: ICampaignProduct[]

  setPrize(value: number): void {
    this.prize = value
  }

  setProducts(products: ICampaignProduct[]): void {
    this.products = products
  }

  addProduct(product: ICampaignProduct): void {
    this.setProducts([...this.products, product])
  }

  removeProduct(id: string): void {
    const newProductList = this.products.filter((product: ICampaignProduct) => product.id !== id)

    this.setProducts(newProductList)
  }

  constructor() {
    this.id = uuidv4()
    this.prize = 0
    this.products = [] as ICampaignProduct[]
  }
}

interface IMoveProduct {
  fromList: string
  toList: string
  productId: string
}

export interface ICampaignFormData {
  harvest: string
  territory: string
  startDate: Date
}

interface ICampaignGroupSubmitData {
  name: string
  prize: number
  products: ICampaignProduct[]
}

interface ICreateCampaign extends ICampaignFormData {
  groups: ICampaignGroupSubmitData[]
}

interface CreateCampaignContextWrapper {
  buildInitialDataLoad: () => Promise<void>
  forceRender: () => void
  selectedDate: Date | null
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>
  futureHarvestList: string[]
  selectedHarvest: string | number | readonly string[] | undefined
  setSelectedHarvest: React.Dispatch<
    React.SetStateAction<string | number | readonly string[] | undefined>
  >
  updateHarvest: (value: string) => void
  territoryList: string[]
  selectedTerritory: string | number | readonly string[] | undefined
  setSelectedTerritory: React.Dispatch<
    React.SetStateAction<string | number | readonly string[] | undefined>
  >
  campaignGroups: ICampaignGroup[]
  addCampaignGroup: () => void
  removeCampaignGroup: (id: string) => void
  moveProduct: (data: IMoveProduct) => void
  productColumnGroup: ICampaignGroup
  formRef: React.RefObject<FormHandles>
  handleSubmit: (data: ICampaignFormData) => Promise<boolean>
  hasSubmitted: boolean
  groupsWithRepeatedPrize: ICampaignGroup[]
  createSalesCampaing: () => Promise<IHTTPResponse>
  addProduct: (payload: { name: string; productTypeId?: number }) => Promise<IHTTPResponse>
  removeProduct: (productId) => Promise<IHTTPResponse>
  getBaseProductList: () => void
}

const CreateCampaignContext = createContext<CreateCampaignContextWrapper>(
  {} as CreateCampaignContextWrapper
)

const CreateCampaignProvider = ({ children }) => {
  const mounted = useRef(false)
  const { callApi } = useAPI()

  const [reRender, setReRender] = useState(false)

  const [campaignGroups, setCampaignGroups] = useState<ICampaignGroup[]>([])

  const [futureHarvestList, setFutureHarvestList] = useState<string[]>([])

  const [selectedHarvest, setSelectedHarvest] = useState<
    string | number | readonly string[] | undefined
  >(undefined)

  const updateHarvest = useCallback((value: string) => {
    setSelectedHarvest(value)
  }, [])

  const [territoryList, setTerritoryList] = useState<string[]>([])

  const [selectedTerritory, setSelectedTerritory] = useState<
    string | number | readonly string[] | undefined
  >(undefined)

  const [baseProductList, setBaseProductList] = useState<ICampaignProduct[]>([])

  const [productColumnGroup, setProductColumnGroup] = useState<ICampaignGroup>({} as ICampaignGroup)

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const [groupsWithRepeatedPrize, setGroupsWithRepeatedPrize] = useState<ICampaignGroup[]>([])

  const formRef = useRef<FormHandles>(null)

  const createSalesCampaing = useCallback(async () => {
    const salesCampaingData: ICreateCampaign = {
      harvest: selectedHarvest as string,
      territory: selectedTerritory as string,
      startDate: selectedDate as Date,
      groups: campaignGroups.map((group: ICampaignGroup, index) => {
        return {
          name: `Premiação ${index + 1}`,
          prize: group.prize,
          products: group.products
        }
      })
    }

    const response = await callApi({
      method: 'post',
      url: '/Campaign/Create',
      payload: salesCampaingData
    })

    return response
  }, [callApi, campaignGroups, selectedDate, selectedHarvest, selectedTerritory])

  const validateGroupsWithRepeatedPrizes = useCallback(async () => {
    const repeatedPrizeValues = campaignGroups.reduce(
      (acc, group: ICampaignGroup) => {
        if (acc.values.includes(group.prize)) {
          acc.repeatedValues = [...acc.repeatedValues, group.prize]
        }
        acc.values = [...acc.values, group.prize]
        return acc
      },
      {
        values: [] as number[],
        repeatedValues: [] as number[]
      }
    )

    const repeatedPrizeGroups = campaignGroups.filter((group: ICampaignGroup) =>
      repeatedPrizeValues.repeatedValues.includes(group.prize)
    )

    setGroupsWithRepeatedPrize(repeatedPrizeGroups)

    return repeatedPrizeGroups
  }, [campaignGroups])

  const hasGroupsWithPrizeZero = useCallback(() => {
    const prizeZeroGroups = campaignGroups.filter((group: ICampaignGroup) => group.prize === 0)

    return prizeZeroGroups.length > 0
  }, [campaignGroups])

  const hasEmptyGroups = useCallback(() => {
    const emptyGroups = campaignGroups.filter(
      (group: ICampaignGroup) => group.products && group.products.length === 0
    )

    return emptyGroups.length > 0
  }, [campaignGroups])

  const hasAtLeastOneGroup = useCallback(() => {
    return campaignGroups.length > 0
  }, [campaignGroups])

  const addCampaignGroup = useCallback(() => {
    const newGroup = new CampaignGroup()

    setCampaignGroups([...campaignGroups, newGroup])
  }, [campaignGroups])

  const removeCampaignGroup = useCallback(
    (id: string) => {
      const newGroupList = campaignGroups.filter((group: ICampaignGroup) => group.id !== id)

      setCampaignGroups(newGroupList)
    },
    [campaignGroups]
  )

  const forceRender = useCallback(() => {
    validateGroupsWithRepeatedPrizes()
    setReRender(!reRender)
  }, [reRender, validateGroupsWithRepeatedPrizes])

  const moveProduct = useCallback(
    (data: IMoveProduct) => {
      const allGroups = [...campaignGroups, productColumnGroup]
      const draggedProduct = baseProductList.find(
        (product: ICampaignProduct) => product.id === data.productId
      )

      const fromGroup = allGroups.find((group: ICampaignGroup) => group.id === data.fromList)
      const toGroup = allGroups.find((group: ICampaignGroup) => group.id === data.toList)

      if (fromGroup && toGroup && draggedProduct) {
        fromGroup.removeProduct(data.productId)
        toGroup.addProduct(draggedProduct)
      }

      forceRender()
    },
    [baseProductList, campaignGroups, forceRender, productColumnGroup]
  )

  const handleSubmit = useCallback(
    async (data: ICampaignFormData) => {
      setHasSubmitted(true)

      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          harvest: Yup.string().required('Selecione uma safra para a campanha'),
          territory: Yup.string().required('Selecione um território para a campanha'),
          startDate: Yup.date().required('Selecione uma data para a campanha')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        const repeatedPrizeGroups = await validateGroupsWithRepeatedPrizes()

        if (
          hasAtLeastOneGroup() &&
          !hasEmptyGroups() &&
          !hasGroupsWithPrizeZero() &&
          repeatedPrizeGroups.length === 0
        ) {
          return true
        }

        if (!hasAtLeastOneGroup()) {
          addCampaignGroup()
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }
      }
      return false
    },
    [
      addCampaignGroup,
      hasAtLeastOneGroup,
      hasEmptyGroups,
      hasGroupsWithPrizeZero,
      validateGroupsWithRepeatedPrizes
    ]
  )

  const getBaseProductList = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: '/Product/AllCornProducts'
    })

    const productList = response.data.map((product: IProduct) => {
      return {
        id: product.name,
        name: product.name,
        productId: product.id
      }
    })
    if (mounted) {
      setBaseProductList(productList)

      const productsGroup = new CampaignGroup()
      productsGroup.setProducts(productList)
      setProductColumnGroup(productsGroup)
    }
  }, [callApi])

  const getFutureHarvestList = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: '/Harvest/AllFutureHarvests'
    })

    if (mounted) {
      const harvests = response.data.map((harvest: IHarvest) => harvest.name)
      setFutureHarvestList(harvests)
    }
  }, [callApi])

  const getTerrritoryList = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: '/Territory/MainRegions'
    })

    if (mounted) {
      const territories = response.data.map((territory: ITerritory) => territory.name)
      setTerritoryList(territories)
    }
  }, [callApi])

  const addProduct = useCallback(async ({ name, productTypeId = 1 }) => {
    const response = await callApi({
      payload: { name, productTypeId },
      method: 'post',
      url: '/Product'
    })

    return response
  }, [])

  const removeProduct = useCallback(async productId => {
    const response = await callApi({ method: 'delete', url: `/Product/${productId}` })

    return response
  }, [])

  const buildInitialDataLoad = useCallback(async () => {
    await getBaseProductList()
    await getFutureHarvestList()
    await getTerrritoryList()
  }, [getBaseProductList, getFutureHarvestList, getTerrritoryList])

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [buildInitialDataLoad])

  return (
    <CreateCampaignContext.Provider
      value={{
        buildInitialDataLoad,
        forceRender,
        selectedDate,
        setSelectedDate,
        futureHarvestList,
        selectedHarvest,
        setSelectedHarvest,
        updateHarvest,
        territoryList,
        selectedTerritory,
        setSelectedTerritory,
        campaignGroups,
        addCampaignGroup,
        removeCampaignGroup,
        moveProduct,
        productColumnGroup,
        formRef,
        handleSubmit,
        hasSubmitted,
        createSalesCampaing,
        groupsWithRepeatedPrize,
        addProduct,
        removeProduct,
        getBaseProductList
      }}>
      {children}
    </CreateCampaignContext.Provider>
  )
}

function useCreateCampaign(): CreateCampaignContextWrapper {
  const context = useContext(CreateCampaignContext)

  if (!context) {
    throw new Error('useCreateCampaign must be used within an CreateCampaignProvider')
  }

  return context
}

export { CreateCampaignProvider, useCreateCampaign }
