import Button from 'components/ui/Button'
import { useByCommercialLocation } from 'domain/components/NotificationRecipientsSetup/context/selectionByCommercialLocation'

import React, { useCallback } from 'react'
import { FiRefreshCcw } from 'react-icons/fi'
import { Multiselect } from 'react-widgets'

import { Container, SelectionSection } from './styles'

const ByCommercialLocationSelection = () => {
  const {
    mainRegionList,
    mainRegionsSelection,
    updateMainRegionsSelection,
    subRegionList,
    subRegionSelection,
    updateSubRegionsSelectionOptions,
    updateSubRegionSelection,
    filteredChildrenTerritories,
    childrenTerritorySelection,
    setChildrenTerritorySelection,
    updateChildrenTerritorySelectionOptions,
    updateExternalUsersByTerritorySelection,
    loadingMainRegions,
    loadingSubRegions,
    loadingChildrenTerritories,
    loadingUsers
  } = useByCommercialLocation()

  const handleApplyTerritorySelectionFilter = useCallback(() => {
    updateExternalUsersByTerritorySelection()
  }, [updateExternalUsersByTerritorySelection])

  return (
    <Container>
      <SelectionSection>
        <h3>Regiões Macro</h3>
        <Multiselect
          dataKey="name"
          textField="name"
          data={mainRegionList}
          value={mainRegionsSelection}
          onChange={value => updateMainRegionsSelection(value)}
          busy={loadingMainRegions}
        />
      </SelectionSection>
      <SelectionSection>
        <Button
          type="button"
          small
          onClick={updateSubRegionsSelectionOptions}
          busy={loadingSubRegions}>
          <FiRefreshCcw size={21} />
        </Button>
      </SelectionSection>
      <SelectionSection>
        <h3>Sub Regiões</h3>
        <Multiselect
          dataKey="districtName"
          textField="districtName"
          data={subRegionList}
          value={subRegionSelection}
          onChange={value => updateSubRegionSelection(value)}
          busy={loadingSubRegions}
        />
      </SelectionSection>
      <SelectionSection>
        <Button
          type="button"
          small
          onClick={updateChildrenTerritorySelectionOptions}
          busy={loadingChildrenTerritories}>
          <FiRefreshCcw size={21} />
        </Button>
      </SelectionSection>
      <SelectionSection>
        <h3>Territórios</h3>
        <Multiselect
          dataKey="name"
          textField="name"
          data={filteredChildrenTerritories}
          value={childrenTerritorySelection}
          onChange={value => setChildrenTerritorySelection(value)}
          busy={loadingChildrenTerritories}
        />
      </SelectionSection>
      <SelectionSection>
        <Button type="button" onClick={handleApplyTerritorySelectionFilter} busy={loadingUsers}>
          Aplicar Filtros
        </Button>
      </SelectionSection>
    </Container>
  )
}

export default ByCommercialLocationSelection
