import React, { SelectHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'

import { FiAlertCircle } from 'react-icons/fi'
import colors from 'styles/theme/colors'
import { DropdownList } from 'react-widgets'
import { Container, Error } from './styles'
import { IoIosArrowDown } from 'react-icons/io'

interface ICustomDropdownList extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  data: string[]
  onChangeHandler: (value: string) => void
}

const CustomDropdownList: React.FC<ICustomDropdownList> = ({ name, data, onChangeHandler }) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const [hasContent, setHasContent] = useState(false)
  const { fieldName, defaultValue, error, registerField } = useField(name)

  const handleChange = useCallback(
    (value: string) => {
      setHasContent(!!value)
      if (selectRef.current) {
        onChangeHandler(value)
      }
    },
    [onChangeHandler]
  )

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])

  return (
    <Container hasError={!!error} hasContent={hasContent}>
      <DropdownList
        ref={selectRef}
        defaultValue={defaultValue}
        value={defaultValue}
        data={data}
        onChange={handleChange}
        name={name}
        placeholder="Selecione"
        selectIcon={<IoIosArrowDown size={20} color={colors.foundationalBlack.light()} />}
      />

      {error && !hasContent && (
        <Error title={error}>
          <FiAlertCircle color={colors.feedback.negative()} size={20} />
        </Error>
      )}
    </Container>
  )
}

export default React.memo(CustomDropdownList)
