import React, { useCallback, useEffect, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import CreateCampaignSetup from 'domain/components/CreateCampaignSetup'
import PageContainer from 'components/layout/PageContainer'
import { useHistory } from 'react-router-dom'
import { ICampaignFormData, useCreateCampaign } from 'pages/SalesCampaign/CreateCampaign/context'
import Button from 'components/ui/Button'
import { IModalPortal, MODAL_TYPES, RESPONSE_MODAL_TITLE } from 'components/layout/ModalPortal'
import { Form } from '@unform/web'

import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'
import { Container } from './styles'

const CreateCampaign = () => {
  const history = useHistory()

  const { buildInitialDataLoad, formRef, handleSubmit, createSalesCampaing } = useCreateCampaign()

  const [isLoading, setIsLoading] = useState(false)

  const [displayCrationModal, setDisplayCreationModal] = useState(false)

  const [displayResponseModal, setDisplayResponseModal] = useState(false)
  const [responseTitle, setResponseTitle] = useState<string | undefined>()
  const [responseMessage, setResponseMessage] = useState<string | undefined>()
  const [responseStatus, setResponseStatus] = useState<string | undefined>()

  const onStartCreation = useCallback(() => {
    setDisplayCreationModal(true)
  }, [])

  const onConfirmCreation = useCallback(async () => {
    setDisplayCreationModal(false)
    setIsLoading(true)
    const response = await createSalesCampaing()
    setResponseMessage(response.message)
    const newResponseTitle =
      response.status === HTTP_RESPONSE_STATUS.SUCCESS
        ? RESPONSE_MODAL_TITLE.SUCCESS
        : RESPONSE_MODAL_TITLE.FAIL
    setResponseTitle(newResponseTitle)
    setResponseStatus(response.status)
    setIsLoading(false)
    setDisplayResponseModal(true)
  }, [createSalesCampaing])

  const onCancelCreation = useCallback(() => {
    setDisplayCreationModal(false)
  }, [])

  const onFinishCreation = useCallback(() => {
    setDisplayResponseModal(false)
    if (responseStatus && responseStatus === HTTP_RESPONSE_STATUS.SUCCESS) {
      history.push('/dashboard/sales-campaign/list')
    }
  }, [history, responseStatus])

  const onSubmitForm = useCallback(
    async (data: ICampaignFormData) => {
      const passedValidation = await handleSubmit(data)
      if (passedValidation) {
        onStartCreation()
      }
    },
    [handleSubmit, onStartCreation]
  )

  const headerChildren = (
    <Button type="submit" width={300}>
      Salvar Campanha
    </Button>
  )

  const bodyChildren = <CreateCampaignSetup />

  const modalList: IModalPortal[] = [
    {
      id: uuidv4(),
      display: displayCrationModal,
      type: MODAL_TYPES.CONFIRM_OR_CANCEL,
      props: {
        title: 'Criar Campanha de Vendas',
        message: 'Confirma a criação da campanha?',
        onConfirm: onConfirmCreation,
        onCancel: onCancelCreation
      }
    },
    {
      id: uuidv4(),
      display: displayResponseModal,
      type: MODAL_TYPES.INFORMATION,
      props: {
        title: responseTitle as string,
        message: responseMessage as string,
        onConfirm: onFinishCreation
      }
    }
  ]

  const loadBaseData = useCallback(async () => {
    setIsLoading(true)
    await buildInitialDataLoad()
    setIsLoading(false)
  }, [buildInitialDataLoad])

  useEffect(() => {
    loadBaseData()
  }, [loadBaseData])

  return (
    <Container>
      <Form ref={formRef} onSubmit={onSubmitForm}>
        <PageContainer
          title="Criar Nova Campanha"
          headerChildren={headerChildren}
          bodyChildren={bodyChildren}
          modalList={modalList}
          isLoading={isLoading}
        />
      </Form>
    </Container>
  )
}

export default CreateCampaign
