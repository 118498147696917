import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { v4 as uuidv4 } from 'uuid'

import Button from 'components/ui/Button'

import { DropdownList } from 'react-widgets'
import PageContainer from 'components/layout/PageContainer'
import { IModalPortal, MODAL_TYPES, RESPONSE_MODAL_TITLE } from 'components/layout/ModalPortal'
import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'
import ActiveCampaignList from 'domain/components/CampaignLists/Active'
import FinishedCampaignList from 'domain/components/CampaignLists/Finished'
import NotStartedCampaignList from 'domain/components/CampaignLists/NotStarted'

import { ISalesCampaign, useListSalesCampaign } from './context'

import { Container, Actions } from './styles'
import { useUser } from 'context/user'
import { pagesNames } from 'domain/constants/functionality'
import { hasPermission } from 'utils/permissions'
import colors from 'styles/theme/colors'
import { IoIosArrowDown } from 'react-icons/io'

export const CAMPAIGN_STATUS = {
  CREATED: 'Programadas',
  ACTIVE: 'Ativas',
  FINISHED: 'Encerradas'
}

const campaignPermissions = pagesNames.CAMPAIGN.permissions
const listPermission = campaignPermissions.list
const createPermission = campaignPermissions.create

const ListCampaigns = () => {
  const history = useHistory()
  const { userFunctionality = [] } = useUser()

  const { getSalesCampaignData, finishCampaign, removeCampaign } = useListSalesCampaign()

  const [status, setStatus] = useState(CAMPAIGN_STATUS.ACTIVE)

  const [isLoading, setIsLoading] = useState(false)

  const [reviewCampaign, setReviewCampaign] = useState<ISalesCampaign | undefined>()

  const [displayFinishCampaignModal, setDisplayFinishCampaignModal] = useState(false)
  const [finishCampaignModalMessage, setFinishCampaignModalMessage] = useState<string | undefined>()

  const [displayRemoveCampaignModal, setDisplayRemoveCampaignModal] = useState(false)
  const [removeCampaignModalMessage, setRemoveCampaignModalMessage] = useState<string | undefined>()

  const [displayResponseModal, setDisplayResponseModal] = useState(false)
  const [responseTitle, setResponseTitle] = useState<string | undefined>()
  const [responseMessage, setResponseMessage] = useState<string | undefined>()

  const onStartFinishCampaign = useCallback((campaign: ISalesCampaign) => {
    setReviewCampaign(campaign)
    setFinishCampaignModalMessage(`Você está encerrando a campanha ${campaign.name}.
    Os vendedores não poderão mais registrar vendas nesta campanha.
    Deseja encerrar a campanha?`)
    setDisplayFinishCampaignModal(true)
  }, [])

  const onConfirmFinishCampaign = useCallback(async () => {
    if (reviewCampaign) {
      setDisplayFinishCampaignModal(false)
      setIsLoading(true)
      const response = await finishCampaign(reviewCampaign.code)
      setResponseMessage(response.message)
      const newResponseTitle =
        response.status === HTTP_RESPONSE_STATUS.SUCCESS
          ? RESPONSE_MODAL_TITLE.SUCCESS
          : RESPONSE_MODAL_TITLE.FAIL
      setResponseTitle(newResponseTitle)
      setIsLoading(false)
      setDisplayResponseModal(true)
    }
  }, [finishCampaign, reviewCampaign])

  const onCancelFinishCampaign = useCallback(() => {
    setDisplayFinishCampaignModal(false)
    setReviewCampaign(undefined)
  }, [])

  const onStartRemoveCampaign = useCallback((campaign: ISalesCampaign) => {
    setReviewCampaign(campaign)
    setRemoveCampaignModalMessage(`Confirma a exclusão da campanha ${campaign.name}?`)
    setDisplayRemoveCampaignModal(true)
  }, [])

  const onConfirmRemoveCampaign = useCallback(async () => {
    if (reviewCampaign) {
      setDisplayRemoveCampaignModal(false)
      setIsLoading(true)
      const response = await removeCampaign(reviewCampaign.code)
      setResponseMessage(response.message)
      const newResponseTitle =
        response.status === HTTP_RESPONSE_STATUS.SUCCESS
          ? RESPONSE_MODAL_TITLE.SUCCESS
          : RESPONSE_MODAL_TITLE.FAIL
      setResponseTitle(newResponseTitle)
      setIsLoading(false)
      setDisplayResponseModal(true)
    }
  }, [removeCampaign, reviewCampaign])

  const onCancelRemoveCampaign = useCallback(() => {
    setDisplayRemoveCampaignModal(false)
    setReviewCampaign(undefined)
  }, [])

  const onFinishAction = useCallback(() => {
    setDisplayResponseModal(false)
    setReviewCampaign(undefined)
  }, [])

  const handleFetchData = useCallback(async () => {
    setIsLoading(true)
    await getSalesCampaignData()
    setIsLoading(false)
  }, [getSalesCampaignData])

  useEffect(() => {
    handleFetchData()
  }, [])

  const canAccess = (screenName: string) =>
    hasPermission({ functionality: 'CAMPAIGN', userFunctionality, screenName })

  if (!canAccess(listPermission)) return <Redirect to="/dashboard" />

  const headerChildren = (
    <Actions>
      <DropdownList
        defaultValue={status}
        data={[CAMPAIGN_STATUS.ACTIVE, CAMPAIGN_STATUS.CREATED, CAMPAIGN_STATUS.FINISHED]}
        onChange={value => setStatus(value)}
        selectIcon={<IoIosArrowDown size={20} color={colors.foundationalBlack.light()} />}
      />
      {canAccess(createPermission) && (
        <Button width={300} onClick={() => history.push('/dashboard/sales-campaign/create')}>
          Nova Campanha
        </Button>
      )}
    </Actions>
  )

  const bodyChildren = (
    <Container>
      {status === CAMPAIGN_STATUS.ACTIVE && (
        <ActiveCampaignList onStartFinishCampaign={onStartFinishCampaign} />
      )}
      {status === CAMPAIGN_STATUS.FINISHED && <FinishedCampaignList />}
      {status === CAMPAIGN_STATUS.CREATED && (
        <NotStartedCampaignList onStartRemoveCampaign={onStartRemoveCampaign} />
      )}
    </Container>
  )

  const modalList: IModalPortal[] = [
    {
      id: uuidv4(),
      display: displayFinishCampaignModal,
      type: MODAL_TYPES.CONFIRM_OR_CANCEL,
      props: {
        title: 'Encerrar Campanha',
        message: finishCampaignModalMessage as string,
        onConfirm: onConfirmFinishCampaign,
        onCancel: onCancelFinishCampaign
      }
    },
    {
      id: uuidv4(),
      display: displayRemoveCampaignModal,
      type: MODAL_TYPES.CONFIRM_OR_CANCEL,
      props: {
        title: 'Exclusão de Campanha',
        message: removeCampaignModalMessage as string,
        onConfirm: onConfirmRemoveCampaign,
        onCancel: onCancelRemoveCampaign
      }
    },
    {
      id: uuidv4(),
      display: displayResponseModal,
      type: MODAL_TYPES.INFORMATION,
      props: {
        title: responseTitle as string,
        message: responseMessage as string,
        onConfirm: onFinishAction
      }
    }
  ]

  return (
    <PageContainer
      title={`Campanhas ${status}`}
      headerChildren={headerChildren}
      bodyChildren={bodyChildren}
      modalList={modalList}
      isLoading={isLoading}
    />
  )
}

export default ListCampaigns
