import React, { ButtonHTMLAttributes } from 'react'
import { Loading } from 'components/ui/Loading'

import { Container, BusyContainer } from './styles'
import { IconType } from 'react-icons/lib'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  light?: boolean
  width?: number
  busy?: boolean
  disabled?: boolean
  small?: boolean
  LeftIcon?: IconType
  RightIcon?: IconType
}

const Button: React.FC<IButton> = ({
  children,
  light,
  width,
  busy,
  disabled,
  small,
  LeftIcon,
  RightIcon,
  ...rest
}) => {
  return busy ? (
    <BusyContainer light={light} small={small} width={width || null} isDisabled={disabled}>
      <Loading />
    </BusyContainer>
  ) : (
    <Container
      isDisabled={disabled}
      width={width || null}
      disabled={disabled}
      light={light}
      small={small}
      {...rest}>
      {LeftIcon && <LeftIcon size={20} className="leftIcon" />}
      {children}
      {RightIcon && <RightIcon size={20} className="rightIcon" />}
    </Container>
  )
}

export default Button
