import { APIProvider } from 'context/api'
import React from 'react'

import { AuthProvider } from './auth'
import { PushNotificationsProvider } from './pushNotification'
import { UserProvider } from './user'

const AppProvider = ({ children }) => (
  <AuthProvider>
    <APIProvider>
      <UserProvider>
        <PushNotificationsProvider>{children}</PushNotificationsProvider>
      </UserProvider>
    </APIProvider>
  </AuthProvider>
)

export default AppProvider
