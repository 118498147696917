import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  h2 {
    margin-bottom: 24px;
    font-weight: bold;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 12px;
  }
`

export const ByLocation = styled.div``

export const ByTerritory = styled.div``

export const SelectionSection = styled.div`
  width: 100%;
  margin-bottom: 12px;

  .rw-multiselect {
    flex: 1;
  }

  .rw-popup {
    max-height: 150px;
  }

  .rw-widget-input {
    max-height: 126px;
    overflow: hidden scroll;
    padding: 0.4rem;
    border-radius: 8px;
    z-index: 1;
    border: 2px solid ${({ theme }) => theme.colors.foundationalGray.light()};

    ::-webkit-scrollbar {
      background: transparent;
    }
  }

  .rw-widget-picker.rw-widget-input {
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.foundationalGray.light()};
    box-shadow: none;
  }

  .rw-state-focus {
    border-radius: 8px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.foundationalGray.base()},
      inset 0 1px 1px ${({ theme }) => theme.colors.foundationalGray.base()};
  }
`
