import { Configuration } from '@azure/msal-browser'

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    authority: process.env.REACT_APP_AUTHORITY
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
}

export const loginRequest = {
  scopes: ['user.read']
}
