import React from 'react'
import format from 'date-fns/format'

import { ISalesCampaign, useListSalesCampaign } from 'pages/SalesCampaign/ListCampaigns/context'

import { formatNumber } from 'utils/formatNumbers'
import { Link } from 'react-router-dom'
import {
  Container,
  CampaignCard,
  CampaignTitle,
  SubTitle,
  DataBox,
  StatusBar,
  StatusData,
  DateInfo
} from './styles'

const FinishedCampaignList = () => {
  const { finishedCampaigns } = useListSalesCampaign()
  return (
    <Container>
      {finishedCampaigns.map((campaign: ISalesCampaign) => {
        return (
          <CampaignCard key={campaign.code}>
            <CampaignTitle>
              <Link to={`/dashboard/sales-campaign/details/${campaign.code}`}>{campaign.name}</Link>
            </CampaignTitle>
            <SubTitle>Data de Início</SubTitle>
            <DataBox>
              <DateInfo>{format(new Date(campaign.startDate), 'dd / MM / yyyy')}</DateInfo>
            </DataBox>
            <SubTitle>Data de Encerramento</SubTitle>
            <DataBox>
              <DateInfo>
                {campaign.endDate && format(new Date(campaign.endDate), 'dd / MM / yyyy')}
              </DateInfo>
            </DataBox>
            <SubTitle>Usuários Participantes</SubTitle>
            <DataBox>
              <StatusBar done={campaign.enrolledUsers} total={campaign.totalUsers} />
              <StatusData>
                <span>{formatNumber(campaign.enrolledUsers.toString())}</span>
                <span>{formatNumber(campaign.totalUsers.toString())}</span>
              </StatusData>
            </DataBox>
            <SubTitle>Metas Realizadas</SubTitle>
            <DataBox>
              <StatusBar done={campaign.achievedGoals} total={campaign.totalGoals} />
              <StatusData>
                <span>{formatNumber(campaign.achievedGoals.toString())}</span>
                <span>{formatNumber(campaign.totalGoals.toString())}</span>
              </StatusData>
            </DataBox>
          </CampaignCard>
        )
      })}
    </Container>
  )
}

export default FinishedCampaignList
