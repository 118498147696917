import { v4 as uuidv4 } from 'uuid'
import ModalPortal, { MODAL_TYPES } from 'components/layout/ModalPortal'
import { useUser } from 'context/user'

const DisabledUserModal = ({ isOpen, onConfirm, onCancel, userId }) => {
  const { disableUser } = useUser()
  const onConfirmDisableUser = async () => {
    await disableUser({ userId })
    onConfirm()
  }

  return (
    <ModalPortal
      id={uuidv4()}
      display={isOpen}
      type={MODAL_TYPES.CONFIRM_OR_CANCEL}
      props={{
        onConfirm: onConfirmDisableUser,
        onCancel: onCancel,
        title: 'Desativar Usuário',
        message: `Você confirma a desativação do usuário?`
      }}
    />
  )
}

export default DisabledUserModal
