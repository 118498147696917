import React, { createRef } from 'react'
import format from 'date-fns/format'
import { RiMore2Fill } from 'react-icons/ri'

import { IInternalUser } from 'domain/interfaces/IInternalUser'
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableHeaderCell,
  DataTableLine
} from 'components/ui/DataTable'
import colors from 'styles/theme/colors'

import { ButtonContainer } from './styles'
import EditUserModal, { EditUserModalRef, EditUserModalType } from '../EditUserModal'
import { useUser } from 'context/user'
import { pagesNames } from 'domain/constants/functionality'
import { hasPermission } from 'utils/permissions'
import { useAuth } from 'context/auth'
import DisableUserModal from '../DisableUserModal'

interface IInternalUsersDataTable {
  userList: IInternalUser[]
  onEdit: () => void
}

const userPermissions = pagesNames.USER.permissions
const editPermissionId = userPermissions.edit

const modalRef = createRef<EditUserModalRef>()

const adminRoleId = 42

const InternalUsersDataTable: React.FC<IInternalUsersDataTable> = ({ userList = [], onEdit }) => {
  const { userFunctionality = [] } = useUser()
  const { loggedInUser } = useAuth()
  const [selectedDisableUser, setSelectedDisableUser] = React.useState<
    EditUserModalType | undefined
  >(undefined)

  const canAccess = (screenName: string) =>
    hasPermission({ functionality: 'USER', userFunctionality, screenName })

  const tableColumns = [
    'Nome',
    'Email',
    'Perfil',
    'Quem convidou',
    'Data de convite',
    'Último acesso',
    canAccess(editPermissionId) ? '' : null
  ]

  const handleReadOnly = (userRoleId: number) => {
    if (loggedInUser.userRoleId === adminRoleId) return false
    if (userRoleId === loggedInUser.userRoleId) return false
    if (userRoleId !== adminRoleId) return false

    return true
  }

  const openConfirmationModal = user => {
    modalRef.current?.closeModal()
    setSelectedDisableUser(user)
  }

  const onConfirmDisableUser = async () => {
    setSelectedDisableUser(undefined)
    onEdit()
  }

  return (
    <>
      <DataTable>
        <DataTableHead>
          <DataTableLine>
            {tableColumns.map(
              column =>
                column !== null && <DataTableHeaderCell key={column}>{column}</DataTableHeaderCell>
            )}
          </DataTableLine>
        </DataTableHead>
        <DataTableBody>
          {userList.length > 0 &&
            userList.map((user: IInternalUser, index) => {
              const invitationDate = new Date(user?.invitationDate)
              const formatedInvitationDate = user?.invitationDate
                ? format(invitationDate, 'dd/MM/yyyy')
                : 'N/A'

              const lastAccessDate = new Date(user?.lastAccess)
              const formatedLastAccessDate =
                user?.lastAccess && lastAccessDate.getFullYear() > 2019
                  ? format(lastAccessDate, 'dd/MM/yyyy')
                  : 'N/A'

              return (
                <DataTableLine key={`${user.id}-${user?.email}-index=${index}`}>
                  <DataTableCell>{user?.name || 'N/A'}</DataTableCell>
                  <DataTableCell>{user?.email}</DataTableCell>
                  <DataTableCell>{user?.portugueseName}</DataTableCell>
                  <DataTableCell>{user?.invitedBy}</DataTableCell>
                  <DataTableCell>{formatedInvitationDate}</DataTableCell>
                  <DataTableCell>{formatedLastAccessDate}</DataTableCell>
                  {canAccess(editPermissionId) && (
                    <DataTableCell>
                      <ButtonContainer
                        onClick={() =>
                          modalRef.current?.openModal({
                            userId: user?.id,
                            readOnly: handleReadOnly(user.userRoleId)
                          })
                        }>
                        <RiMore2Fill size={24} color={colors.foundationalBlack.light()} />
                      </ButtonContainer>
                    </DataTableCell>
                  )}
                </DataTableLine>
              )
            })}
        </DataTableBody>
      </DataTable>
      <EditUserModal onSubmit={onEdit} ref={modalRef} onDisableUser={openConfirmationModal} />
      <DisableUserModal
        userId={selectedDisableUser?.userId}
        onConfirm={onConfirmDisableUser}
        onCancel={() => setSelectedDisableUser(undefined)}
        isOpen={selectedDisableUser}
      />
    </>
  )
}

export default React.memo(InternalUsersDataTable)
