import styled, { css } from 'styled-components'

interface IMenuState {
  menuOpen: boolean
}

interface IState {
  selected: boolean
  menuOpen: boolean
}

export const Container = styled.li<IState>`
  padding: 8px 0;
  ${props =>
    props.menuOpen &&
    css`
      padding: 8px 16px;
    `}
  transition: padding 0.2s;
  a {
    display: flex;
    align-items: center;
    overflow: hidden;

    transition: margin 0.2s, color 0.4s;
    background-color: ${({ theme }) => theme.colors.white()};
    color: ${({ theme }) => theme.colors.foundationalBlack.base()};
    text-decoration: none;

    ${props =>
      props.selected &&
      css`
        color: ${({ theme }) => theme.colors.brandPrimary.base()};
        font-weight: 500;
      `}

    svg {
      margin-right: 0;
      z-index: 999;
      background-color: ${({ theme }) => theme.colors.white()};
      ${props =>
        props.menuOpen &&
        css`
          margin-right: 16px;
        `}
    }
  }

  &:hover {
    a {
      color: ${({ theme }) => theme.colors.brandPrimary.light()};
    }
  }
`

export const Text = styled.span<IMenuState>`
  flex: 1;
  max-width: 0;
  transform: translateX(-100%);

  transition: width 0.2s, transform 0.2s;
  overflow: hidden;

  ${props =>
    props.menuOpen === true &&
    css`
      transform: translateX(0);
      max-width: 10rem;
    `}
`
