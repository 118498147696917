import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'

import { IProduct } from 'domain/interfaces/IProduct'
import getValidationErrors from 'utils/getValidationErrors'
import { HTTP_RESPONSE_STATUS, IHTTPResponse } from 'utils/httpRequestHandler'
import { ICampaign } from 'domain/interfaces/ICampaign'
import { ICampaignGroupDetails } from 'domain/interfaces/ICampaignGroupDetails'
import { useAPI } from 'context/api'

export interface ICampaignProduct {
  id: string
  name: string
}

export interface ICampaignGroup {
  id: string
  prize: number
  products: ICampaignProduct[]
  setPrize: (value: number) => void
  setProducts: (products: ICampaignProduct[]) => void
  addProduct(product: ICampaignProduct): void
  removeProduct(id: string): void
}

class CampaignGroup implements ICampaignGroup {
  id: string

  prize: number

  products: ICampaignProduct[]

  setPrize(value: number): void {
    this.prize = value
  }

  setProducts(products: ICampaignProduct[]): void {
    this.products = products
  }

  addProduct(product: ICampaignProduct): void {
    this.setProducts([...this.products, product])
  }

  removeProduct(id: string): void {
    const newProductList = this.products.filter((product: ICampaignProduct) => product.id !== id)

    this.setProducts(newProductList)
  }

  constructor() {
    this.id = uuidv4()
    this.prize = 0
    this.products = [] as ICampaignProduct[]
  }
}

interface IMoveProduct {
  fromList: string
  toList: string
  productId: string
}

export interface ICampaignFormData {
  startDate: Date
}

interface ICampaignGroupSubmitData {
  name: string
  prize: number
  products: ICampaignProduct[]
}

interface IViewCampaignDetails extends ICampaignFormData {
  groups: ICampaignGroupSubmitData[]
}

interface ViewCampaignDetailsContextWrapper {
  buildInitialDataLoad: () => Promise<void>
  forceRender: () => void
  selectedDate: Date | null
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>
  campaignGroups: ICampaignGroup[]
  addCampaignGroup: () => void
  removeCampaignGroup: (id: string) => void
  moveProduct: (data: IMoveProduct) => void
  productColumnGroup: ICampaignGroup
  formRef: React.RefObject<FormHandles>
  handleSubmit: (data: ICampaignFormData) => Promise<boolean>
  hasSubmitted: boolean
  editSalesCampaing: () => Promise<IHTTPResponse>
  campaignData: ICampaign | undefined
  getCampaignData: (campaignId: number) => Promise<void>
}

const ViewCampaignDetailsContext = createContext<ViewCampaignDetailsContextWrapper>(
  {} as ViewCampaignDetailsContextWrapper
)

const ViewCampaignDetailsProvider = ({ children }) => {
  const mounted = useRef(false)
  const { callApi } = useAPI()

  const [campaignData, setCampaignData] = useState<ICampaign | undefined>()

  const [campaignGroups, setCampaignGroups] = useState<ICampaignGroup[]>([])

  const [reRender, setReRender] = useState(false)

  const forceRender = useCallback(() => {
    setReRender(!reRender)
  }, [reRender])

  const [baseProductList, setBaseProductList] = useState<ICampaignProduct[]>([])

  const [productColumnGroup, setProductColumnGroup] = useState<ICampaignGroup>({} as ICampaignGroup)

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const formRef = useRef<FormHandles>(null)

  const [currentCampaignId, setCurrentCampaignId] = useState<number | undefined>()

  const getCampaignData = useCallback(
    async (campaignId: number) => {
      setCurrentCampaignId(campaignId)
      setCampaignData(undefined)
      const response = await callApi({
        method: 'get',
        url: `/Campaign/${campaignId}`
      })

      if (mounted && response.status === HTTP_RESPONSE_STATUS.SUCCESS && response.data) {
        const campaign: ICampaign = response.data
        setCampaignData(campaign)
        setCampaignGroups(() => {
          const groups = campaign.groups.map((group: ICampaignGroupDetails) => {
            const newGroup = new CampaignGroup()

            newGroup.prize = group.prize
            newGroup.products = group.products.map((product: { name: string }) => {
              return {
                id: product.name,
                name: product.name
              }
            })

            return newGroup
          })
          return groups
        })

        const remainingProducts = campaign.remainingProducts.map((product: IProduct) => {
          return {
            id: product.name,
            name: product.name
          }
        })

        const productsGroup = new CampaignGroup()
        productsGroup.setProducts(remainingProducts)
        setProductColumnGroup(productsGroup)
      }
    },
    [callApi]
  )

  const editSalesCampaing = useCallback(async () => {
    const salesCampaingData: IViewCampaignDetails = {
      startDate: selectedDate as Date,
      groups: campaignGroups.map((group: ICampaignGroup, index) => {
        return {
          name: `Premiação ${index + 1}`,
          prize: group.prize,
          products: group.products
        }
      })
    }

    const response = await callApi({
      method: 'put',
      url: `/Campaign/Edit/${currentCampaignId}`,
      payload: salesCampaingData
    })

    return response
  }, [callApi, campaignGroups, currentCampaignId, selectedDate])

  const hasEmptyGroups = useCallback(() => {
    const emptyGroups = campaignGroups.filter(
      (group: ICampaignGroup) => group.products && group.products.length === 0
    )

    return emptyGroups.length > 0
  }, [campaignGroups])

  const hasAtLeastOneGroup = useCallback(() => {
    return campaignGroups.length > 0
  }, [campaignGroups])

  const addCampaignGroup = useCallback(() => {
    const newGroup = new CampaignGroup()

    setCampaignGroups([...campaignGroups, newGroup])
  }, [campaignGroups])

  const removeCampaignGroup = useCallback(
    (id: string) => {
      const newGroupList = campaignGroups.filter((group: ICampaignGroup) => group.id !== id)

      setCampaignGroups(newGroupList)
    },
    [campaignGroups]
  )

  const moveProduct = useCallback(
    (data: IMoveProduct) => {
      const allGroups = [...campaignGroups, productColumnGroup]
      const draggedProduct = baseProductList.find(
        (product: ICampaignProduct) => product.id === data.productId
      )

      const fromGroup = allGroups.find((group: ICampaignGroup) => group.id === data.fromList)
      const toGroup = allGroups.find((group: ICampaignGroup) => group.id === data.toList)

      if (fromGroup && toGroup && draggedProduct) {
        fromGroup.removeProduct(data.productId)
        toGroup.addProduct(draggedProduct)
      }

      forceRender()
    },
    [baseProductList, campaignGroups, forceRender, productColumnGroup]
  )

  const handleSubmit = useCallback(
    async (data: ICampaignFormData) => {
      setHasSubmitted(true)

      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          startDate: Yup.date().required('Selecione uma data de início para a campanha')
        })

        await schema.validate(data, {
          abortEarly: false
        })

        if (hasAtLeastOneGroup() && !hasEmptyGroups()) {
          return true
        }

        if (!hasAtLeastOneGroup()) {
          addCampaignGroup()
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)
          formRef.current?.setErrors(errors)
        }
      }
      return false
    },
    [addCampaignGroup, hasAtLeastOneGroup, hasEmptyGroups]
  )

  const getBaseProductList = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: `/Product/AllCornProducts`
    })

    const productList = response.data.map((product: IProduct) => {
      return {
        id: product.name,
        name: product.name
      }
    })
    if (mounted) {
      setBaseProductList(productList)

      const productsGroup = new CampaignGroup()
      productsGroup.setProducts(productList)
      setProductColumnGroup(productsGroup)
    }
  }, [callApi])

  const buildInitialDataLoad = useCallback(async () => {
    await getBaseProductList()
  }, [getBaseProductList])

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return (
    <ViewCampaignDetailsContext.Provider
      value={{
        buildInitialDataLoad,
        forceRender,
        selectedDate,
        setSelectedDate,
        campaignGroups,
        addCampaignGroup,
        removeCampaignGroup,
        moveProduct,
        productColumnGroup,
        formRef,
        handleSubmit,
        hasSubmitted,
        editSalesCampaing,
        campaignData,
        getCampaignData
      }}>
      {children}
    </ViewCampaignDetailsContext.Provider>
  )
}

function useViewCampaignDetails(): ViewCampaignDetailsContextWrapper {
  const context = useContext(ViewCampaignDetailsContext)

  if (!context) {
    throw new Error('useViewCampaignDetails must be used within an ViewCampaignDetailsProvider')
  }

  return context
}

export { ViewCampaignDetailsProvider, useViewCampaignDetails }
