import styled from 'styled-components'

interface IContainer {
  isDragging: boolean
}

export const Container = styled.div<IContainer>`
  background-color: ${({ theme }) => theme.colors.foundationalGray.light()};
  border-radius: 12px;
  padding: 8px 16px;
  text-align: center;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.brandSecundary.light()};
    color: ${({ theme }) => theme.colors.white()};
  }
`
