import Tooltip from 'components/ui/Tooltip'
import styled, { css } from 'styled-components'

interface IContainer {
  isFocused: boolean
  hasError: boolean
  hasContent: boolean
}

export const Container = styled.div<IContainer>`
  background: ${({ theme }) => theme.colors.white()};
  border-radius: 10px;
  width: 100%;
  min-height: 56px;
  min-height: 56px;
  color: ${({ theme }) => theme.colors.foundationalGray.dark()};
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  & + div {
    margin-top: 12px;
  }
  ${props =>
    props.hasError &&
    !props.hasContent &&
    css`
      border-color: ${({ theme }) => theme.colors.feedback.negative()};
    `}
  ${props =>
    props.isFocused &&
    css`
      color: ${({ theme }) => theme.colors.foundationalGray.base()};
      border-color: ${({ theme }) => theme.colors.foundationalGray.base()};
    `}

  ${props =>
    props.hasContent &&
    css`
      height: auto;
      img {
        margin-right: 24px;
      }
    `}

  input {
    display: none;
  }

  position: relative;
  label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 48px;
    margin: 0;
    background: transparent;
    border: 3px solid transparent;
    border-radius: 7px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;

    font-weight: bold;
    color: ${({ theme }) => theme.colors.foundationalGray.dark()};
    font-size: ${({ theme }) => theme.font.largeTextBold};

    ${props =>
      props.hasContent &&
      css`
        color: transparent;
      `}
  }
`

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 14px;
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.feedback.negative()};
    color: ${({ theme }) => theme.colors.white()};
    &::before {
      border-color: ${({ theme }) => theme.colors.feedback.negative()} transparent;
    }
  }
`
