import React from 'react'
// import { getImageByBlobName } from 'utils/configuraAzureBlobStorage';

import {
  Container,
  CardHeader,
  Category,
  RecipientCount,
  CardBody,
  CardText,
  CardTitle,
  CardMessage,
  CardImage,
  CardFooter,
  CardFooterTitle,
  CardFooterDetail
} from './styles'

export interface INotificationCard {
  category: string
  imageSrc: string
  recipientCount: number
  title: string
  body: string
  link: string
  imageAltText: string
}

const NotificationCard: React.FC<INotificationCard> = ({
  category,
  title,
  body,
  link,
  recipientCount,
  imageSrc,
  imageAltText
}) => {
  // const image = getImageByBlobName(imageSrc);

  return (
    <Container>
      <CardHeader>
        <Category>{category}</Category>
        <RecipientCount>{`${recipientCount} destinatários`}</RecipientCount>
      </CardHeader>
      <CardBody>
        <CardText>
          <CardTitle>{title}</CardTitle>
          <CardMessage>{body}</CardMessage>
        </CardText>
        {imageSrc && (
          <CardImage>
            {/* {typeof image === 'string' && <img src={image} alt={imageAltText} />} */}
            <img src={imageSrc} alt={imageAltText} />
          </CardImage>
        )}
      </CardBody>
      {link && (
        <CardFooter>
          <CardFooterTitle>Link: </CardFooterTitle>
          <CardFooterDetail>{link}</CardFooterDetail>
        </CardFooter>
      )}
    </Container>
  )
}

export default NotificationCard
