import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { ICity } from 'domain/interfaces/ICity'
import { ICityGroupByState } from 'domain/interfaces/ICityGroupByState'
import { IExternalUser } from 'domain/interfaces/IExternalUser'
import { IStateProvince } from 'domain/interfaces/IStateProvince'
import { usePushNotifications } from 'context/pushNotification'
import { useAPI } from 'context/api'

interface ByGeographicLocationContextWrapper {
  stateProvinceList: IStateProvince[]
  stateProvinceSelection: IStateProvince[]
  updateStateProvinceSelection: (value: IStateProvince[]) => Promise<void>
  cityList: ICityGroupByState
  filteredCities: ICity[]
  updateCitySelectionOptions: () => void
  citySelection: ICity[]
  setCitySelection: React.Dispatch<React.SetStateAction<ICity[]>>
  filteredByCityExternalUsers: IExternalUser[]
  updateExternalUsersByCityList: () => Promise<void>
  loadingStates: boolean
  loadingCities: boolean
  loadingUsers: boolean
}

const ByGeographicLocationContext = createContext<ByGeographicLocationContextWrapper>(
  {} as ByGeographicLocationContextWrapper
)

const ByGeographicLocationProvider = ({ children }) => {
  const { setRecipientList } = usePushNotifications()
  const { callApi } = useAPI()
  const [loadingStates, setLoadingStates] = useState(false)

  const [loadingUsers, setLoadingUsers] = useState(false)
  const [loadingCities, setLoadingCities] = useState(false)
  const [stateProvinceList, setStateProvinceList] = useState<IStateProvince[]>([])

  const [cityList, setCityList] = useState<ICityGroupByState>({} as ICityGroupByState)

  const [stateProvinceSelection, setStateProvinceSelection] = useState<IStateProvince[]>([])

  const [filteredCities, setFilteredCities] = useState<ICity[]>([])

  const [citySelection, setCitySelection] = useState<ICity[]>([])

  const [filteredByCityExternalUsers, setFilteredByCityExternalUsers] = useState<IExternalUser[]>(
    []
  )

  const getStateProvinceList = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: '/StateProvince/All'
    })

    setStateProvinceList(response.data)
  }, [callApi, setStateProvinceList])

  const getCityList = useCallback(async () => {
    const response = await callApi({
      method: 'get',
      url: '/City/GroupByState'
    })

    setCityList(response.data)
  }, [callApi, setCityList])

  const updateExternalUsersByCityList = useCallback(async () => {
    setLoadingUsers(true)

    if (citySelection.length > 0) {
      const cityNames = citySelection.map((city: ICity) => {
        return { name: city.cityName }
      })
      const response = await callApi({
        method: 'post',
        url: '/User/ExternalUsersByCityList',
        payload: cityNames
      })

      setFilteredByCityExternalUsers(response.data)
      setRecipientList(response.data)
      setLoadingUsers(false)
    } else {
      const stateNames = stateProvinceSelection.map((state: IStateProvince) => {
        return { name: state.name }
      })
      const response = await callApi({
        method: 'post',
        url: '/User/ExternalUsersByStateList',
        payload: stateNames
      })

      setFilteredByCityExternalUsers(response.data)
      setRecipientList(response.data)
      setLoadingUsers(false)
    }
  }, [callApi, citySelection, setRecipientList, stateProvinceSelection])

  const updateCitySelectionOptions = useCallback(() => {
    setLoadingCities(true)
    const cities = stateProvinceSelection.reduce((acc: ICity[], item: IStateProvince) => {
      const stateProvinceCities = cityList[item.name]
      const newList = acc.concat(stateProvinceCities)
      return newList
    }, [] as ICity[])
    setFilteredCities(cities)
    const newCitySelection = citySelection.filter((city: ICity) =>
      stateProvinceSelection
        .map((state: IStateProvince) => state.name)
        .includes(city.stateProvinceName)
    )
    setCitySelection(newCitySelection)
    setTimeout(() => setLoadingCities(false), 1000)
  }, [cityList, citySelection, stateProvinceSelection])

  const updateStateProvinceSelection = useCallback(async (value: IStateProvince[]) => {
    await setStateProvinceSelection(value)
  }, [])

  const buildInitialDataLoad = useCallback(async () => {
    setLoadingStates(true)
    await getStateProvinceList()
    await getCityList()
    setLoadingStates(false)
  }, [getCityList, getStateProvinceList])

  useEffect(() => {
    buildInitialDataLoad()
  }, [buildInitialDataLoad])

  return (
    <ByGeographicLocationContext.Provider
      value={{
        stateProvinceList,
        cityList,
        stateProvinceSelection,
        updateStateProvinceSelection,
        filteredCities,
        updateCitySelectionOptions,
        citySelection,
        setCitySelection,
        filteredByCityExternalUsers,
        updateExternalUsersByCityList,
        loadingStates,
        loadingCities,
        loadingUsers
      }}>
      {children}
    </ByGeographicLocationContext.Provider>
  )
}

function useByGeographicLocation(): ByGeographicLocationContextWrapper {
  const context = useContext(ByGeographicLocationContext)

  if (!context) {
    throw new Error('useByGeographicLocation must be used within an ByGeographicLocationProvider')
  }

  return context
}

export { ByGeographicLocationProvider, useByGeographicLocation }
