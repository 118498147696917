import React from 'react'
import { HashRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import AppProvider from 'context'

import Routes from 'routes'
import GlobalStyles from 'styles/GlobalStyles'
import theme from 'styles/theme'

const App = () => (
  <ThemeProvider theme={theme}>
    <HashRouter>
      <AppProvider>
        <Routes />
      </AppProvider>
      <GlobalStyles />
    </HashRouter>
  </ThemeProvider>
)

export default App
