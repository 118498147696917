import styled, { css } from 'styled-components'

interface IButtonProps {
  light?: boolean
  width: number | null
  isDisabled?: boolean
  small?: boolean
}

export const BusyContainer = styled.button<IButtonProps>`
  border: none;
  border-radius: 8px;
  padding: 8px 16px;

  /* width: ${props =>
    props.width !== null ? `${props.width}px` : 'fit-content(min(max-content, 100%))'}; */
  width: ${props => (props.width !== null ? `${props.width}px` : 'max-content')};

  height: 48px;

  /* & + button {
    margin-left: 24px;
  } */

  ${({ theme }) => theme.font.breadCrumbActive};

  background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
  color: ${({ theme }) => theme.colors.white()};

  svg {
    height: 20px;
    width: 20px;
    color: ${({ theme }) => theme.colors.white()};

    padding: 0;
    margin: 0;
  }

  ${props =>
    props.small &&
    css`
      height: 36px;
    `}

  ${props =>
    props.light &&
    css`
      color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
      border: 2px solid ${({ theme }) => theme.colors.foundationalBlack.dark()};
      background-color: transparent;

      svg {
        color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
      }
    `}

  ${props =>
    props.isDisabled &&
    css`
      color: ${({ theme }) => theme.colors.foundationalGray.lightest()};
      border: 2px solid ${({ theme }) => theme.colors.foundationalGray.base()};
      background-color: ${({ theme }) => theme.colors.foundationalGray.base()};

      svg {
        color: ${({ theme }) => theme.colors.foundationalGray.lightest()};
      }
    `}

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Container = styled(BusyContainer)<IButtonProps>`
  padding: 8px 16px;

  svg {
    min-height: 20px;
    min-width: 20px;
  }

  .leftIcon {
    margin-right: 10px;
  }

  .rightIcon {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.brandPrimary.light(0.9)};
  }

  ${props =>
    props.light &&
    css`
      &:hover {
        background-color: transparent;
        opacity: 0.7;
      }
    `}

  ${props =>
    props.isDisabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.foundationalGray.base(0.9)};
        border-color: ${({ theme }) => theme.colors.foundationalGray.base(0.9)};
      }
    `}
`
