import { HtmlHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import colors from 'styles/theme/colors'

export const Container = styled.div`
  display: grid;
  gap: 16px;
`

export const UserContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(200px, 1.5fr)
    minmax(200px, 1.5fr)
    minmax(200px, 1.5fr)
    max-content;
  background-color: ${colors.white()};
  border-radius: 8px;
  padding: 32px 40px;
  gap: 16px;

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`

export const FieldContainer = styled.div`
  display: grid;
  gap: 16px;
`

export const FieldLabel = styled.label`
  font-weight: 700;
  font-size: 16px;
`

export const FieldValue = styled.label`
  font-weight: 500;
  font-size: 16px;
`
export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 16px;

  button {
    margin: 0;
  }

  button.added-user {
    width: 104px;
  }
`

export const SubmitButtonContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  display: flex;

  @media screen and (max-width: 1023px) {
    button {
      width: 100%;
    }
  }
`

type HintTextType = HtmlHTMLAttributes<'div'> & {
  hasError?: boolean
}

export const HintTextContainer = styled.div<HintTextType>`
  ${({ theme, hasError }) => css`
    background-color: ${hasError ? 'transparent' : theme.colors.foundationalBlack.lightest(0.8)};
    padding: 24px 32px;
    border-radius: 4px;

    ${hasError ? theme.font.bodyText : theme.font.bodyTextBold}

    b {
      color: ${!hasError
        ? theme.colors.brandPrimary.base()
        : theme.colors.foundationalBlack.darkest()};
    }
  `}
`
