/**
 * DOC: https://www.radix-ui.com/docs/primitives/components/dialog
 */
import React, { HTMLAttributes, useCallback, useImperativeHandle, useState } from 'react'
import { Root, Portal } from '@radix-ui/react-dialog'
import { DialogContent, DialogOverlay, DialogTitle } from './styles'

export interface IModalRef {
  openModal: () => void
  closeModal: () => void
}

export interface IModalProps extends HTMLAttributes<'div'> {
  onClose?: () => any
  title?: string
  // description?: string
}

const Modal = React.forwardRef<IModalRef, IModalProps>(({ title, children, onClose }, ref) => {
  const [open, setOpen] = useState(false)

  const openModal = useCallback(() => setOpen(true), [])
  const closeModal = useCallback(() => {
    setOpen(false)
    onClose && onClose()
  }, [])

  useImperativeHandle(ref, () => ({ openModal, closeModal }))

  return (
    <Root onOpenChange={setOpen} open={open}>
      <Portal>
        <DialogOverlay onClick={closeModal} />
        <DialogContent onCloseAutoFocus={onClose}>
          {title && <DialogTitle>{title}</DialogTitle>}
          {/* {description && <DialogDescription>{description}</DialogDescription>} */}
          {children}
        </DialogContent>
      </Portal>
    </Root>
  )
})

export default React.memo(Modal)
