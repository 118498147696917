import styled from 'styled-components'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white()};
  border-radius: 10px;
  width: 100%;
  min-height: 56px;
  min-height: 56px;
  color: ${({ theme }) => theme.colors.foundationalGray.dark()};
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  & + div {
    margin-top: 12px;
  }
`

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 16px;

  img {
    max-width: 400px;
  }
  svg {
    position: absolute;
    top: -20px;
    right: -20px;
    cursor: pointer;
  }
`

export const RemoveFile = styled.div`
  flex: 1;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;

  background-color: ${({ theme }) => theme.colors.foundationalGray.base()};
  color: ${({ theme }) => theme.colors.white()};

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.foundationalGray.dark()};
  }
`
