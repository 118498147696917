import React from 'react'
import { Redirect, Switch, Route as RouteDom } from 'react-router-dom'

import SignIn from 'pages/SignIn'
import Dashboard from 'pages/Dashboard'

import Route from 'routes/Route'

const Routes = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/dashboard" component={Dashboard} isPrivate />

    <RouteDom path="*" component={() => <Redirect to="/dashboard" />} />
  </Switch>
)

export default Routes
