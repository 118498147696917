import React, { createRef, HtmlHTMLAttributes, useCallback, useRef, useState } from 'react'
import { BiDownload } from 'react-icons/bi'
import { IoSearchOutline } from 'react-icons/io5'

import Button from 'components/ui/Button'
import Toast, { IToastRef } from 'components/ui/Toast'
import { useUser } from 'context/user'
import { AuthMechanismKeys, UserStatusIdKeys } from 'domain/interfaces/IUsers'
import { IUserTerritory } from 'domain/interfaces/IUserTerritory'
import { downloadBase64 } from 'utils/files'
import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'

import LoggedInUserTerritoryList from '../LoggedInUserTerritoryList'
import { Container, Input, InputContainer, InputWapper } from './styles'
import { useDebouncedCallback } from 'use-debounce'
import { Loading } from 'components/ui/Loading'

type UserHeaderType = HtmlHTMLAttributes<'div'> & {
  authMechanism?: AuthMechanismKeys
  userStatus: UserStatusIdKeys
  territories: IUserTerritory[]
  handleSearch?: (e: any) => void
}

const oneSecond = 1000

const handleFileName = (userStatus: UserStatusIdKeys | 'INTERNAL') => {
  const currentDate = new Date().toLocaleDateString('pt-BR')
  return `${userStatus}-${currentDate}.xls`
}

const toastRef = createRef<IToastRef>()

const UserHeader = ({ territories, userStatus, authMechanism, handleSearch }: UserHeaderType) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { exportExcel } = useUser()
  const [downloading, setDownloading] = useState(false)
  const [loading, setloading] = useState(false)

  const showToast = useCallback(() => toastRef.current?.showToast(), [])
  // const hideToast = useCallback(() => toastRef.current?.hideToast(), [])

  const handleSubmit = async () => {
    try {
      setDownloading(true)
      const search = inputRef.current?.value
      const response = await exportExcel({ userStatus, authMechanism, search })

      if (response.status === HTTP_RESPONSE_STATUS.FAIL) showToast()

      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        const downloadParams = {
          filename: handleFileName(authMechanism === 'AAD' ? 'INTERNAL' : userStatus),
          midiatype: 'application/vnd.ms-excel',
          codeBade64: response.data
        }

        downloadBase64(downloadParams)
      }

      setDownloading(false)
    } catch (e) {
      console.log(e)
      showToast()
      setDownloading(false)
    }
  }

  const handleSearchChange = async ({ target }) => {
    setloading(true)
    handleSearch && (await handleSearch(target.value))
    setloading(false)
  }

  const debounced = useDebouncedCallback(handleSearchChange, oneSecond)

  return (
    <>
      <Toast title="Erro" description="Erro ao exportar usuários para excel." ref={toastRef} />
      <Container>
        <InputWapper>
          <InputContainer loading={loading}>
            <IoSearchOutline size={24} color="black" />
            <Input ref={inputRef} placeholder="Buscar usuário" onChange={debounced} />
            {loading && <Loading id="loading" />}
          </InputContainer>
        </InputWapper>
        <LoggedInUserTerritoryList territories={territories} />
        <Button busy={downloading} small LeftIcon={BiDownload} onClick={handleSubmit}>
          Baixar Lista
        </Button>
      </Container>
    </>
  )
}

export default React.memo(UserHeader)
