import styled from 'styled-components'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export const Loading = styled(AiOutlineLoading3Quarters)`
  width: 54px;
  height: auto;
  color: ${({ theme }) => theme.colors.brandPrimary.base()};

  @keyframes spin {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 2s infinite;
`
