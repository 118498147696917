import React, { useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IExternalUser } from 'domain/interfaces/IExternalUser'

import { IModalPortal, MODAL_TYPES, RESPONSE_MODAL_TITLE } from 'components/layout/ModalPortal'
import PageContainer from 'components/layout/PageContainer'
import Pagination from 'components/ui/Pagination'
import { useAuth } from 'context/auth'
import { useUser } from 'context/user'
import UserApprovalDataTable from 'domain/components/UserApprovalDataTable'
import { pagesNames } from 'domain/constants/functionality'
import { Redirect } from 'react-router-dom'
import { HTTP_RESPONSE_STATUS } from 'utils/httpRequestHandler'
import { hasPermission } from 'utils/permissions'
import UserHeader from 'domain/components/UserHeader'
import { USER_STATUS } from 'domain/constants/user'

const screenName = pagesNames.USER.permissions.approve

const UserApproval = () => {
  const { loggedInUser } = useAuth()
  const {
    pendingApprovalUsers,
    fetchUserList,
    approveUserAccount,
    rejectUserAccount,
    removeUserAfterReview,
    userAccountRejectionReasons,
    getUserAccountRejectionReasons,
    pagesPaddingApprovalNumber,
    currentPaddingApprovalPage,
    userFunctionality = []
  } = useUser()

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [reviewUser, setReviewUser] = useState<IExternalUser | undefined>()

  const [displayApprovalModal, setDisplayApprovalModal] = useState(false)
  const [approvalModalMessage, setApprovalModalMessage] = useState<string | undefined>()

  const [displayRejectionModal, setDisplayRejectionModal] = useState(false)
  const [rejectionModalMessage, setRejectionModalMessage] = useState<string | undefined>()
  const [rejectionReason, setRejectionReason] = useState<string>(userAccountRejectionReasons[0])

  const [displayResponseModal, setDisplayResponseModal] = useState(false)
  const [responseTitle, setResponseTitle] = useState<string | undefined>()
  const [responseMessage, setResponseMessage] = useState<string | undefined>()

  const onStartApproval = useCallback((user: IExternalUser) => {
    setReviewUser(user)
    setApprovalModalMessage(`Confirmar aprovação do usuário ${user.email}`)
    setDisplayApprovalModal(true)
  }, [])

  const onConfirmApproval = useCallback(async () => {
    if (reviewUser) {
      setDisplayApprovalModal(false)
      setIsLoading(true)
      const response = await approveUserAccount(reviewUser.id)
      setResponseMessage(response.message)
      const newResponseTitle =
        response.status === HTTP_RESPONSE_STATUS.SUCCESS
          ? RESPONSE_MODAL_TITLE.SUCCESS
          : RESPONSE_MODAL_TITLE.FAIL
      setResponseTitle(newResponseTitle)
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        removeUserAfterReview(reviewUser.email)
      }
      setIsLoading(false)
      setDisplayResponseModal(true)
    }
  }, [approveUserAccount, removeUserAfterReview, reviewUser])

  const onCancelApproval = useCallback(() => {
    setDisplayApprovalModal(false)
    setReviewUser(undefined)
  }, [])

  const onFinishApproval = useCallback(() => {
    setDisplayResponseModal(false)
    setReviewUser(undefined)
  }, [])

  const onStartRejection = useCallback((user: IExternalUser) => {
    setReviewUser(user)
    setRejectionModalMessage(`Confirmar reprovação do usuário ${user.email}`)
    setDisplayRejectionModal(true)
  }, [])

  const onConfirmRejection = useCallback(async () => {
    if (reviewUser) {
      setDisplayRejectionModal(false)
      setIsLoading(true)
      const response = await rejectUserAccount(reviewUser.email, rejectionReason)
      setResponseMessage(response.message)
      const newResponseTitle =
        response.status === HTTP_RESPONSE_STATUS.SUCCESS
          ? RESPONSE_MODAL_TITLE.SUCCESS
          : RESPONSE_MODAL_TITLE.FAIL
      setResponseTitle(newResponseTitle)
      if (response.status === HTTP_RESPONSE_STATUS.SUCCESS) {
        removeUserAfterReview(reviewUser.email)
      }
      setIsLoading(false)
      setDisplayResponseModal(true)
    }
  }, [rejectUserAccount, rejectionReason, removeUserAfterReview, reviewUser])

  const onCancelRejection = useCallback(() => {
    setDisplayRejectionModal(false)
    setReviewUser(undefined)
  }, [])

  const handleFetchData = useCallback(async () => {
    setIsLoading(true)
    await getUserAccountRejectionReasons()
    const response = await fetchUserList(USER_STATUS.PENDING_APPROVAL, currentPaddingApprovalPage)

    if (response && response.status === HTTP_RESPONSE_STATUS.FAIL) {
      setResponseMessage(response.message)
      setResponseTitle(RESPONSE_MODAL_TITLE.FAIL)
      setIsLoading(false)
      setDisplayResponseModal(true)
    }
    setIsLoading(false)
  }, [fetchUserList, getUserAccountRejectionReasons])

  const handlePageChange = useCallback(
    async (page: number) => {
      setIsLoading(true)
      await fetchUserList(USER_STATUS.PENDING_APPROVAL, page, search)
      setIsLoading(false)
    },
    [fetchUserList, search]
  )

  useEffect(() => {
    handleFetchData()
  }, [])

  const handleSearch = useCallback(
    async value => {
      const searchPage = 1
      await fetchUserList(USER_STATUS.PENDING_APPROVAL, searchPage, value)
      setSearch(value)
    },
    [setSearch]
  )

  if (!hasPermission({ functionality: 'USER', userFunctionality, screenName })) {
    return <Redirect to="/dashboard" />
  }

  const modalList: IModalPortal[] = [
    {
      id: uuidv4(),
      display: displayApprovalModal,
      type: MODAL_TYPES.CONFIRM_OR_CANCEL,
      props: {
        title: 'Aprovação de Usuário',
        message: approvalModalMessage as string,
        onConfirm: onConfirmApproval,
        onCancel: onCancelApproval
      }
    },
    {
      id: uuidv4(),
      display: displayRejectionModal,
      type: MODAL_TYPES.CONFIRM_OR_CANCEL,
      props: {
        title: 'Reprovação de Usuário',
        message: rejectionModalMessage as string,
        onConfirm: onConfirmRejection,
        onCancel: onCancelRejection,
        options: userAccountRejectionReasons,
        selectedOption: rejectionReason,
        onChangeOption: setRejectionReason
      }
    },
    {
      id: uuidv4(),
      display: displayResponseModal,
      type: MODAL_TYPES.INFORMATION,
      props: {
        title: responseTitle as string,
        message: responseMessage as string,
        onConfirm: onFinishApproval
      }
    }
  ]

  const headerChildren = (
    <UserHeader
      userStatus="PENDING_APPROVAL"
      territories={loggedInUser.userTerritories}
      handleSearch={handleSearch}
    />
  )

  const bodyChildren = (
    <Pagination
      currentPage={currentPaddingApprovalPage}
      pagesNumber={pagesPaddingApprovalNumber}
      onPageChange={handlePageChange}>
      <UserApprovalDataTable
        userList={pendingApprovalUsers}
        onStartApproval={onStartApproval}
        onStartRejection={onStartRejection}
      />
    </Pagination>
  )

  return (
    <PageContainer
      title="Usuários Pendentes"
      headerChildren={headerChildren}
      bodyChildren={bodyChildren}
      modalList={modalList}
      isLoading={isLoading}
    />
  )
}

export default UserApproval
