import styled from 'styled-components'
import Tooltip from '../Tooltip'

// export const Container = styled.select`
//   border-radius: 8px;
//   border: 3px solid ${({ theme }) => theme.colors.foundationalBlack.light()};
//   padding: 16px;

//   width: 300px;
//   height: 48px;

//   & + button {
//     margin-left: 24px;
//   }

//   font-size: ${({ theme }) => theme.font.breadCrumbActive.fontSize};
//   font-weight: ${({ theme }) => theme.font.breadCrumbActive.fontWeight};
//   line-height: ${({ theme }) => theme.font.breadCrumbActive.lineHeight};

//   color: ${({ theme }) => theme.colors.foundationalBlack.base()};
// `

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.foundationalGray.base()};
  border-radius: 10px;
  padding: 0 8px;
  padding-left: 16px;

  font-weight: normal;

  position: relative;

  .rw-state-focus,
  .rw-widget-input,
  .rw-widget-picker,
  .rw-dropdown-list-inp,
  .rw-input-add,
  .rw-picker-b,
  .rw-state-selected {
    border: none !important;
    box-shadow: none !important;

    padding: 0;
    margin: 0;
  }
  .rw-widget-input.rw-state-disabled,
  .rw-state-disabled .rw-widget-input,
  fieldset[disabled] .rw-widget-input {
    background-color: transparent !important;
  }

  .rw-widget-picker {
    flex: 1;
    height: 48px;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
  }

  /* .rw-dropdown-list-input {
    width: 100%;
  } */

  .rw-input-addon.rw-picker-btn {
    border: none;
    &:hover {
      background-color: ${({ theme }) => theme.colors.foundationalGray.lightest(0.3)};
    }
  }

  .rw-state-selected {
    background-color: ${({ theme }) => theme.colors.brandPrimary.light()};
    border-color: ${({ theme }) => theme.colors.brandPrimary.light()};

    &:hover {
      background-color: ${({ theme }) => theme.colors.brandPrimary.light(0.9)} !important;
    }
  }

  .rw-widget-input {
    color: ${({ theme }) => theme.colors.foundationalBlack.darkest()};
    box-shadow: none;
    padding: 0px;
  }

  .rw-list-option {
    padding: 8px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.foundationalGray.lightest(0.3)};
      border: none;
    }
  }
`

export const Error = styled(Tooltip)`
  position: absolute;
  top: calc(50% - 10px);
  right: 38px;

  height: 20px;
  margin-left: 14px;
  svg {
    margin: 0;
  }
  span {
    background: ${({ theme }) => theme.colors.feedback.negative()};
    color: ${({ theme }) => theme.colors.white()};
    &::before {
      border-color: ${({ theme }) => theme.colors.feedback.negative()} transparent;
    }
  }
`

export const SelectIconContainer = styled.div`
  display: flex;
  flex-direction: row;
`
