import React, { useCallback, useImperativeHandle, useState } from 'react'

import Modal, { IModalRef } from 'components/ui/Modal'
import Button from 'components/ui/Button'

import { ButtonsContainer, ContentContainer } from './styles'

const modalRef = React.createRef<IModalRef>()

interface RemoveProductModalProps extends React.HTMLAttributes<'div'> {
  onSubmit?: (productId) => void
}

export interface RemoveProductModalRef {
  openModal: (productId) => void
  closeModal: () => void
}

const EditUserModal = React.forwardRef<RemoveProductModalRef, RemoveProductModalProps>(
  ({ onSubmit }, ref) => {
    const [productId, setProductId] = useState()

    const cleanData = useCallback(() => {
      setProductId(undefined)
    }, [])

    const closeModal = useCallback(() => {
      modalRef.current?.closeModal()
      cleanData()
    }, [])

    const openModal = useCallback(data => {
      modalRef.current?.openModal()
      setProductId(data)
    }, [])

    useImperativeHandle(ref, () => ({ openModal, closeModal }))

    const onSave = async () => {
      onSubmit && (await onSubmit(productId))
      cleanData()
      closeModal()
    }

    return (
      <Modal title="Remover Produto" ref={modalRef} onClose={cleanData}>
        <ContentContainer>
          <p>Você tem certeza que deseja excluir este produto?</p>
        </ContentContainer>
        <ButtonsContainer>
          <Button autoFocus width={120} small light onClick={closeModal}>
            Cancelar
          </Button>
          <Button width={120} onClick={onSave} small>
            Confirmar
          </Button>
        </ButtonsContainer>
      </Modal>
    )
  }
)

export default React.memo(EditUserModal)
