import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.h3`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.brandPrimary.light()};
  font-weight: bold;
`

export const Text = styled.p`
  width: 100%;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.brandPrimary.light()};
    font-weight: bold;
  }
`
