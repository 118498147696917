import React from 'react'
import DashboardRoutes from 'routes/dashboard'
import SideBar from 'components/layout/SideBar'

import 'react-widgets/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import { LayoutContainer, Main } from './styles'

const Layout = () => {
  return (
    <LayoutContainer>
      <SideBar />
      <Main>
        <DashboardRoutes />
      </Main>
    </LayoutContainer>
  )
}

export default Layout
