import React, { HtmlHTMLAttributes, useCallback } from 'react'
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiOutlineChevronLeft,
  HiOutlineChevronRight
} from 'react-icons/hi'

import colors from 'styles/theme/colors'

import { Page, Wapper } from './styles'

interface IPagination extends HtmlHTMLAttributes<'ul'> {
  pagesNumber: number
  currentPage: number
  onPageChange: (page: number) => void
}

const maxVisubleButtons = 5

const Pagination = ({
  pagesNumber = 1,
  currentPage = 1,
  onPageChange = () => null,
  children
}: IPagination) => {
  const calculateMaxVisible = useCallback(() => {
    let maxLeft = currentPage - Math.floor(maxVisubleButtons / 2)
    let maxRight = currentPage + Math.floor(maxVisubleButtons / 2)

    if (maxLeft < 1) {
      maxLeft = 1
      maxRight = maxVisubleButtons
    }

    if (maxRight > pagesNumber) {
      maxLeft = pagesNumber - (maxVisubleButtons - 1)
      maxRight = pagesNumber

      if (maxLeft < 1) maxLeft = 1
    }

    return { maxLeft, maxRight }
  }, [pagesNumber, currentPage])

  const handlePageChange = useCallback(
    page => {
      if (page < 1) page = 1
      if (page > pagesNumber) page = pagesNumber

      onPageChange(page)
    },
    [onPageChange, pagesNumber]
  )

  const renderPageButton = useCallback(
    page => (
      <Page
        key={page}
        selected={page === currentPage}
        onClick={() => page !== currentPage && handlePageChange(page)}>
        {page}
      </Page>
    ),
    [currentPage, handlePageChange]
  )

  const handlePageButtons = useCallback(() => {
    const { maxLeft, maxRight } = calculateMaxVisible()

    return Array.from({ length: maxRight - maxLeft + 1 }, (_, i) => i + maxLeft).map(i => {
      return renderPageButton(i)
    })
  }, [renderPageButton, calculateMaxVisible])

  const renderPagination = useCallback(() => {
    return (
      <Wapper>
        {currentPage > 1 && (
          <>
            <Page selected={false} key="fist" onClick={() => handlePageChange(1)}>
              <HiChevronDoubleLeft size={24} color={colors.brandPrimary.light()} />
            </Page>
            <Page selected={false} key="one-less" onClick={() => handlePageChange(currentPage - 1)}>
              <HiOutlineChevronLeft size={24} color={colors.brandPrimary.light()} />
            </Page>
          </>
        )}

        {handlePageButtons()}

        {currentPage < pagesNumber && (
          <>
            <Page selected={false} key="one-more" onClick={() => handlePageChange(currentPage + 1)}>
              <HiOutlineChevronRight size={24} color={colors.brandPrimary.light()} />
            </Page>
            <Page selected={false} key="last" onClick={() => handlePageChange(pagesNumber)}>
              <HiChevronDoubleRight size={24} color={colors.brandPrimary.light()} />
            </Page>
          </>
        )}
      </Wapper>
    )
  }, [handlePageButtons, currentPage, handlePageChange])

  if (pagesNumber < 2 && !children) return null
  if (pagesNumber < 2) return <>{children}</>

  return (
    <>
      {renderPagination()}
      {children}
      {renderPagination()}
    </>
  )
}

export default Pagination
