import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  white-space: pre-line;

  display: flex;
  /* flex-direction: column; */
  align-items: stretch;
  justify-content: center;

  button {
    width: 320px;
    & + button {
      margin-top: 24px;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

export const ColumnContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-bottom: 64px;
    svg {
      width: 280px;
      height: auto;
    }
  }
`

export const BrandLogoWrapper = styled.div`
  width: 40vw;
  background-color: ${({ theme }) => theme.colors.brandPrimary.base()};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 300px;
    height: auto;
    margin: 16px;
  }

  @media (max-width: 1023px) {
    flex: 0.6;
    width: 100vw;
    padding: 0 48px;

    svg {
      max-width: 400px;
      height: auto;
      margin: 48px;
    }
  }
`

export const ContentWrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 0 160px;

  button {
    width: 100%;
    max-width: 400px;
  }

  @media (max-width: 1023px) {
    width: 100vw;
    padding: 0 48px;
    align-items: center;

    p,
    h2 {
      align-self: left;
      width: 100%;
      max-width: 400px;
    }
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.brandPrimary.light()};
  ${({ theme }) => theme.font.heading2}
  font-weight: bold;
  margin-bottom: 24px;
`

export const Text = styled.p`
  ${({ theme }) => theme.font.largeTextRegular}

  margin-bottom: 48px;
`
