import { Client } from '@microsoft/microsoft-graph-client'

function getAuthenticatedClient(accessToken: string) {
  const client = Client.init({
    authProvider: done => {
      done(null, accessToken)
    }
  })

  return client
}

export async function getUserDetails(accessToken: string): Promise<any> {
  const client = getAuthenticatedClient(accessToken)

  const user = await client.api('/me').select('displayName,mail,userPrincipalName').get()

  return user
}

export async function getUserAvatar(accessToken: string): Promise<any> {
  const client = getAuthenticatedClient(accessToken)

  const avatar = await client.api('/me/photo/$value').get()

  return avatar
}
