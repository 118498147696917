import React, { useState } from 'react'

import ByCommercialLocationSelection from 'domain/components/NotificationRecipientsSetup/Selection/byCommercialLocation'
import ByDistributionChannelSelection from 'domain/components/NotificationRecipientsSetup/Selection/byDistributionChannel'
import ByGeographicLocationSelection from 'domain/components/NotificationRecipientsSetup/Selection/byGeographicLocation'

import { usePushNotifications } from 'context/pushNotification'
import NotificationRecipientCount from 'domain/components/NotificationRecipientCount'
import { FiAlertCircle } from 'react-icons/fi'
import colors from 'styles/theme/colors'
import { ByGeographicLocationProvider } from 'domain/components/NotificationRecipientsSetup/context/selectionByGeoLocation'
import { ByCommercialLocationProvider } from 'domain/components/NotificationRecipientsSetup/context/selectionByCommercialLocation'
import { ByDistributionChannelProvider } from 'domain/components/NotificationRecipientsSetup/context/selectionByDistributionChannel'
import {
  Container,
  FilterTypeSelection,
  FilterTypeSelectionTab,
  ByGeoLocation,
  ByCommercialLocation,
  ByDistributionChannel,
  Error
} from './styles'

const SELECTION = {
  GEO_LOCATION: 'Localização',
  COMMERCIAL_LOCATION: 'Territórios',
  DISTRIBUTION_CHANNEL: 'Distribuidora'
}

const NotificationRecipientsSetup = () => {
  const [selectionBy, setSelectionBy] = useState(SELECTION.COMMERCIAL_LOCATION)
  const { recipientList, hasSubmitted } = usePushNotifications()

  return (
    <Container hasError={hasSubmitted && (recipientList?.length === 0 || !recipientList?.length)}>
      <NotificationRecipientCount count={recipientList?.length || 0} />
      <FilterTypeSelection>
        <FilterTypeSelectionTab
          tooltip={SELECTION.GEO_LOCATION}
          selected={selectionBy === SELECTION.GEO_LOCATION}
          onClick={() => setSelectionBy(SELECTION.GEO_LOCATION)}>
          <ByGeoLocation selected={selectionBy === SELECTION.GEO_LOCATION} size={32} />
        </FilterTypeSelectionTab>
        <FilterTypeSelectionTab
          tooltip={SELECTION.COMMERCIAL_LOCATION}
          selected={selectionBy === SELECTION.COMMERCIAL_LOCATION}
          onClick={() => setSelectionBy(SELECTION.COMMERCIAL_LOCATION)}>
          <ByCommercialLocation
            selected={selectionBy === SELECTION.COMMERCIAL_LOCATION}
            size={32}
          />
        </FilterTypeSelectionTab>
        <FilterTypeSelectionTab
          tooltip={SELECTION.DISTRIBUTION_CHANNEL}
          selected={selectionBy === SELECTION.DISTRIBUTION_CHANNEL}
          onClick={() => setSelectionBy(SELECTION.DISTRIBUTION_CHANNEL)}>
          <ByDistributionChannel
            selected={selectionBy === SELECTION.DISTRIBUTION_CHANNEL}
            size={32}
          />
        </FilterTypeSelectionTab>
      </FilterTypeSelection>
      {selectionBy === SELECTION.GEO_LOCATION && (
        <ByGeographicLocationProvider>
          <ByGeographicLocationSelection />
        </ByGeographicLocationProvider>
      )}
      {selectionBy === SELECTION.COMMERCIAL_LOCATION && (
        <ByCommercialLocationProvider>
          <ByCommercialLocationSelection />
        </ByCommercialLocationProvider>
      )}
      {selectionBy === SELECTION.DISTRIBUTION_CHANNEL && (
        <ByDistributionChannelProvider>
          <ByDistributionChannelSelection />
        </ByDistributionChannelProvider>
      )}
      {hasSubmitted && (recipientList?.length === 0 || !recipientList?.length) && (
        <Error title="Nenhum destinatário encontrado! Selecione os filtros abaixo e tente novamente.">
          <FiAlertCircle color={colors.feedback.negative()} size={20} />
        </Error>
      )}
    </Container>
  )
}

export default NotificationRecipientsSetup
