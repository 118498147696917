import React, { useEffect, useState } from 'react'

import { Recipients, RecipientCount } from './styles'

export interface INotificationRecipientCount {
  count: number
}

const NotificationRecipientCount: React.FC<INotificationRecipientCount> = ({ count }) => {
  const [change, setChange] = useState(false)

  useEffect(() => {
    setChange(true)

    setTimeout(() => {
      setChange(false)
    }, 200)
  }, [count])

  return (
    <Recipients change={change}>
      <RecipientCount change={change}>{count}</RecipientCount>
      <span>Destinatários</span>
    </Recipients>
  )
}

export default NotificationRecipientCount
