import styled, { css } from 'styled-components'

interface IState {
  open: boolean
}

export const Container = styled.div<IState>`
  background-color: ${({ theme }) => theme.colors.white()};
  box-shadow: 8px 12px 30px rgba(0, 0, 0, 0.24);

  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;

  width: 220px;
  max-height: 100vh;
  transition: width 0.4s, box-shadow 0.6s;

  ${props =>
    props.open === false &&
    css`
      width: 96px;
      box-shadow: 4px 12px 25px rgba(0, 0, 0, 0.12);
    `}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;
`

export const BrandSection = styled.div<IState>`
  width: 100%;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${props =>
    props.open === false &&
    css`
      justify-content: center;
    `}

  svg {
    cursor: pointer;
  }

  padding: 0 16px;
  margin-top: 32px;

  position: relative;
`

export const LogoBig = styled.div<IState>`
  transform: translateX(-150%);
  transition: transform 0.3s;

  ${props =>
    props.open &&
    css`
      transform: translateX(0);
    `}

  svg {
    width: 85%;
    height: auto;
  }
`

export const LogoSmall = styled.div<IState>`
  transform: translateX(0);
  transition: transform 0.4s;
  width: 100%;

  ${props =>
    props.open &&
    css`
      transform: translateX(-150%);
    `}

  position: absolute;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 56px;
    height: auto;
  }
`

export const MenuControl = styled.div<IState>`
  position: absolute;
  right: 12px;
  top: 12px;

  background-color: ${({ theme }) => theme.colors.foundationalGray.lightest(0.9)};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 4px;

  svg {
    cursor: pointer;
    transition: transform 0.7s;
    color: ${({ theme }) => theme.colors.foundationalBlack.base()};

    ${props =>
      props.open === false &&
      css`
        transform: rotate(-180deg);
      `}
  }
`

export const MenuItems = styled.ul`
  width: 100%;
  margin-top: 24px;
  flex: 1;
  max-height: calc(100vh - 124px);
`

export const MenuPages = styled.ul<{ open?: boolean }>`
  width: 100%;
  max-height: calc(100vh - 244px);

  overflow: scroll;
  overflow-x: hidden;

  background-color: white;
  flex: 1;

  ${props =>
    props.open === false &&
    css`
      max-height: calc(100vh - 280px);
    `}
`

export const UserSection = styled.div<IState>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 12px;
  padding: 0 16px;

  ${props =>
    props.open === false &&
    css`
      flex-direction: column;
      justify-content: flex-end;
      height: 150px;
    `}
  transition: all 0.7s;
`

export const UserBar = styled.div<IState>`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 12px;
  width: 100%;
  padding-top: 18px;

  ${props =>
    props.open &&
    css`
      align-items: flex-start;
    `}
  transition: all 0.7s;
`

export const AvatarWrapper = styled.div``

export const Username = styled.div`
  margin-top: 6px;
  text-align: center;
  ${({ theme }) => theme.font.smallText};
  color: ${({ theme }) => theme.colors.brandPrimary.light()};
`

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border: 2px solid ${({ theme }) => theme.colors.brandPrimary.light()};
`

export const EmptyAvatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.brandPrimary.dark()};
  color: ${({ theme }) => theme.colors.white()};

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`

export const Logout = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.foundationalBlack.light()};
  ${({ theme }) => theme.font.smallText};
  margin-bottom: 18px;
`
