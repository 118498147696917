import styled, { css } from 'styled-components'

export const Wapper = styled.ul`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  /* margin-bottom: 12px; */
  margin: 16px;
`

interface IPage {
  selected: boolean
}

export const Page = styled.li<IPage>`
  color: ${({ theme }) => theme.colors.foundationalBlack.base()};
  margin-right: 16px;
  display: flex;

  cursor: pointer;

  font-weight: 400;

  ${props =>
    props.selected &&
    css`
      color: ${({ theme }) => theme.colors.brandPrimary.light()};
      background-color: ${({ theme }) => theme.colors.white()};
      font-weight: 900;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-right: 12px;
      align-items: center;
      justify-content: center;

      text-align: center;
      vertical-align: middle;
    `}
`
